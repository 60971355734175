import { axiosInstance as axios } from "../context/AuthContext";
import { appendParamsToUrl } from '../utils/helper';

const baseUrl = process.env.REACT_APP_BASE_URL;
const attendanceBaseUrl = `${baseUrl}/Attendances`;
const shiftsBaseUrl = `${baseUrl}/Shifts`;

/**
 * Retrieves attendances data based on the specified start and end dates and employee ID.
 *
 * @param {Date} startDate - the start date for the attendance data
 * @param {Date} endDate - the end date for the attendance data
 * @param {number} employeeId - the ID of the employee for whom the attendance data is retrieved
 * @return {Promise} the attendance data retrieved from the server
 */
export const getAttendances = async (startDate, endDate, employeeId) => {
  const response = await axios.get(
    `${attendanceBaseUrl}?startDate=${startDate}&endDate=${endDate}&employeeId=${employeeId}`
  );
  return response?.data;
};

/**
 * Retrieves attendance settings.
 * @return {Promise} the attendance settings data retrieved from the server
 */
export const getAttendanceSettings = async () => {
  const response = await axios.get(`${attendanceBaseUrl}/settings`);
  return response?.data;
};

/**
 * Update attendance settings.
 * @param {object} bodyData - the data to be sent in the request body
 * @return {Promise} the updated response returned from the server
 */
export const updateAttendanceSettings = async bodyData => {
  const response = await axios.put(`${attendanceBaseUrl}/settings`, bodyData);
  return response?.data;
};

/**
 * Retrieves the active time logs from the attendance base URL.
 *
 * @return {Object} The data returned from the active time logs API endpoint.
 */
export const timeLogsActive = async () => {
  const response = await axios.get(`${attendanceBaseUrl}/timelogs/active`);
  return response?.data;
};

/**
 * Starts the timer by sending a POST request to the attendance API endpoint.
 *
 * @return {Promise<any>} The response data from the API.
 */
export const startTimer = async formData => {
  const response = await axios.post(`${attendanceBaseUrl}/startTimer`, formData);
  return response?.data;
};

/**
 * Asynchronously stops the timer by sending a POST request to the attendance base URL.
 *
 * @return {Promise} The data returned from the POST request.
 */
export const stopTimer = async () => {
  const response = await axios.post(`${attendanceBaseUrl}/stopTimer`);
  return response?.data;
};

export const deleteTimeLog = async timeLogId => {
  const response = await axios.delete(`${attendanceBaseUrl}/timelogs/${timeLogId}`);
  return response?.data;
};

export const attendanceStatusUpdate = async (attendanceIdCsv = " ", status = " ") => {
  const response = await axios.post(
    `${attendanceBaseUrl}/status?attendanceIdCsv=${attendanceIdCsv}&status=${status}`
  );
  return response;
};

export const attendancesTimelogs = async timelogs => {
  const response = await axios.post(`${attendanceBaseUrl}/timelogs`, timelogs);
  return response;
};

// shifts services
/**
 * Creates a new shift.
 * @param {Object} shiftData
 * @returns {Promise<Object>}
 */
export const createShift = async shiftData => {
  try {
    const response = await axios.post(`${shiftsBaseUrl}`, shiftData);
    return response?.data;
  } catch (error) {
    // Handle specific backend errors
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("An unexpected error occurred while creating the shift.");
    }
  }
};

/**
 * Updates an existing shift.
 * @param {Object} shiftData
 * @returns {Promise<Object>}
 */
export const updateShift = async shiftData => {
  const response = await axios.put(`${shiftsBaseUrl}`, shiftData);
  return response?.data;
};

/**
 * Retrieves a shift by its ID.
 * @param {number} shiftId
 * @returns {Promise<Object>}
 */
export const getShiftById = async shiftId => {
  const response = await axios.get(`${shiftsBaseUrl}/${shiftId}`);
  return response?.data;
};

/**
 * Retrieves shifts for a specific tenant.
 * @returns {Promise<Array>}
 */
export const getShifts = async (page, pageSize) => {
  const response = await axios.get(`${shiftsBaseUrl}?page=${page}&pageSize=${pageSize}`);
  return response?.data;
};

/**
 * Deletes a shift by its ID.
 * @param {number} shiftId
 * @returns {Promise<boolean>}
 */
export const deleteShift = async shiftId => {
  const response = await axios.delete(`${shiftsBaseUrl}/${shiftId}`);
  return response?.data;
};

/**
 * Assigns a shift to multiple departments.
 * @param {Object} assignShiftData
 * @returns {Promise<Object>}
 */
export const assignShiftToDepartments = async assignShiftData => {
  const response = await axios.post(`${shiftsBaseUrl}/assign/departments`, assignShiftData);
  return response?.data;
};

/**
 * Assigns a shift to employees.
 * @param {Object} assignShiftData - Data for assigning shifts to employees.
 * @returns {Promise<Object>} - API response.
 */
export const assignShiftToEmployees = async assignShiftData => {
  try {
    const response = await axios.post(`${shiftsBaseUrl}/assign/employees`, assignShiftData);
    return response?.data;
  } catch (error) {
    console.error("Error assigning shift to employees:", error);
    throw error;
  }
};

export const getShiftsAvailableForDepartment = async departmentId => {
  const response = await axios.get(`${shiftsBaseUrl}`, {
    params: {
      departmentId,
    },
  });
  return response?.data;
};

// Function to fetch assigned shifts with pagination and date filters
export const getAssignedShifts = async ({ page = 1, pageSize = 10, startDate, endDate, searchQuery }) => {
  try {
    if (!startDate || !endDate) {
      throw new Error("Start date and end date are required.");
    }

    // Validate date range
    if (new Date(startDate) > new Date(endDate)) {
      throw new Error("Start date cannot be later than end date.");
    }

    // Append query parameters to the URL
    const url = appendParamsToUrl(`${shiftsBaseUrl}/assigned`, {
      page,
      pageSize,
      startDate,
      endDate,
      searchQuery
    });

    // Make the API request
    const response = await axios.get(url);

    // Return the response data
    return response?.data;
  } catch (error) {
    console.error("Error fetching assigned shifts:", error);
    throw error;
  }
};
