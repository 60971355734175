import { createSlice } from "@reduxjs/toolkit";
import { attendanceManagementTabs } from "../../pages/Attendance Management/helper";


interface AttendanceManagementState {
  id: string,
  selectedAttendanceTab: number,
}

const initialState: AttendanceManagementState = {
  id: "attendanceManagement",
  selectedAttendanceTab: attendanceManagementTabs.myAttendance,
};


const attendanceManagementSlice = createSlice({
  name: 'attendanceManagement',
    initialState,
    reducers: {
      setSelectedAttendanceTab: (state, action) => {
        state.selectedAttendanceTab = action.payload
      },
    }
});

export const {
  setSelectedAttendanceTab,
} = attendanceManagementSlice.actions;

export{ initialState as initialAttendanceManagementState }

export default attendanceManagementSlice.reducer;
