import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Filter from '../../common/Filter/Filter';
import SelectWithDropdown from '../../common/SelectWithDropdown/SelectWithDropdown';
import InputFields from '../../common/Input/InputFields';
import { Checkbox } from '../../../hooks/useCheckbox';
import { GetDepartments } from '../../../services/DepartmentService';
import { Role } from '../../../enum/Role';

const EmployeesFilter = ({ filters, onFilterChange, activeFiltersCount }) => {
  const [showDepartment, setShowDepartment] = useState(false);
  const [showDesignation, setShowDesignation] = useState(false);
  const [departments, setDepartments] = useState([{ value: '', label: 'All Departments' }]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await GetDepartments(true, 1, 100, true);  // Passing isActive, page, pageSize, isFilter. Setting isFilter to true gets only departments with assigned users, not all departments that has been created by the admin. It's an optimization.
        const departmentOptions = response.data.map(department => ({
          value: department.id,
          label: department.name
        }));
        setDepartments([{ value: '', label: 'All Departments' }, ...departmentOptions]);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    fetchDepartments();
  }, []);

  const handleCheckboxChange = useCallback((key) => {
    if (key === 'department') {
      setShowDepartment((prev) => !prev);
      if (showDepartment) {
        onFilterChange("department")({ target: { value: '' } });
      }
    } else if (key === 'designation') {
      setShowDesignation((prev) => !prev);
      if (showDesignation) {
        onFilterChange("designation")({ target: { value: '' } });
      }
    }
  }, [showDepartment, showDesignation, onFilterChange]);

  const handleDepartmentChange = useCallback((options, selectedOption) => {
    onFilterChange("department")({ target: { value: selectedOption } });
  }, [onFilterChange]);

  const handleDesignationChange = useCallback((e) => {
    onFilterChange("designation")(e);
  }, [onFilterChange]);

  const handleRoleChange = useCallback((options, selectedOption) => {
    onFilterChange("role")({ target: { value: selectedOption } });
  }, [onFilterChange]);

  const {Administrator, Moderator, Member} = Role;

  const roleOptions = useMemo(() => {
    return [
    { value: '', label: 'All Roles' },
    { value: Administrator, label: 'Administrator' },
    { value: Moderator, label: 'Moderator' },
    { value: Member, label: 'Member' },
  ]}, []);

  const handleDepartmentCheckboxChange = useCallback(() => {
    handleCheckboxChange('department');
  }, [handleCheckboxChange]);

  const handleDesignationCheckboxChange = useCallback(() => {
    handleCheckboxChange('designation');
  }, [handleCheckboxChange]);

  return (
    <Filter filters={filters} filterIconColor='#000000' activeFiltersCount={activeFiltersCount}>
      <div className="space-y-2 w-64 rounded-md transition-colors duration-200">
        {/* Department filter */}
        <div className="hover:bg-[#bcd6d7] p-2 rounded-md transition-colors duration-200">
          <div className="flex items-center">
            <Checkbox
              id="departmentCheckbox"
              checked={showDepartment}
              onChange={handleDepartmentCheckboxChange}
              inputClass="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              label="Department"
              labelClass="block text-sm font-medium text-gray-700"
              otherProps={{ tabIndex: '-1' }}
            />
          </div>
          {showDepartment && (
            <>
              <SelectWithDropdown
                options={departments}
                isDefaultValue={true}
                defaultValue={filters.department?.departmentName || "All Departments"}
                handleChange={handleDepartmentChange}
                backgroundColor="tc-select-dropdown common-input !mt-2 !bg-[#EEEEEE] !text-gray-900 !border-gray-300 !dark:border-gray-600"
                size="w-full"
                className="flex justify-between"
              />
              <p className="text-sm text-gray-500 mt-2">Only departments with assigned users are displayed</p>
            </>
          )}
        </div>

        {/* Designation filter */}
        <div className="hover:bg-[#bcd6d7] p-2 rounded-md transition-colors duration-200">
          <div className="flex items-center">
            <Checkbox
              id="designationCheckbox"
              checked={showDesignation}
              onChange={handleDesignationCheckboxChange}
              inputClass="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              label="Designation"
              labelClass="block text-sm font-medium text-gray-700"
              otherProps={{ tabIndex: '-1' }}
            />
          </div>
          {showDesignation && (
            <InputFields
              InputField={{
                id: 'designation',
                label: '',
                type: 'text',
                tabIndex: '-1',
                name: 'designation',
                placeholder: 'Enter designation...',
                required: false,
              }}
              value={filters.designation}
              onChange={handleDesignationChange}
              inputClassName="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              parentClassName="mt-1"
              otherProps={{tabIndex: '-1'}}
            />
          )}
        </div>

        {/* Role filter */}
        <div className="hover:bg-[#bcd6d7] p-2 rounded-md transition-colors duration-200">
          <label className="block text-sm font-medium text-gray-700">Role</label>
          <SelectWithDropdown
            options={roleOptions}
            isDefaultValue={true}
            defaultValue={filters.role?.roleName || "All Roles"}
            handleChange={handleRoleChange}
            backgroundColor="tc-select-dropdown common-input !bg-[#EEEEEE] !text-gray-900 !border-gray-300 !dark:border-gray-600"
            size="w-full"
            className="flex justify-between"
          />
        </div>
      </div>
    </Filter>
  );
};

export default React.memo(EmployeesFilter);
