import { v4 as uuidv4 } from "uuid";
import { FileTypes } from "../enum/FileTypes";
import { GeneralSvg } from "../media/ProfileTabSvgs";
import { BsPersonFillX } from "react-icons/bs";
import SkillsSvg from "../media/SkillsSvg";
import PayrollHistory from "../components/Payroll/PayrollHistory";
import PayrollRun from "../components/Payroll/PayrollRun";
import { BsFillMoonStarsFill, BsFlagFill } from "react-icons/bs";
import { ImFire } from "react-icons/im";
import { GiCelebrationFire, GiFoodTruck, GiGlassCelebration } from "react-icons/gi";
import { MdCardTravel, MdCelebration, MdCoPresent, MdEmojiFoodBeverage, MdOutlineHealthAndSafety } from "react-icons/md";
import { IoMdPaper } from "react-icons/io";
import { RiCalendar2Line } from "react-icons/ri";
import { ImTree } from "react-icons/im";
import { WiNightAltCloudyWindy } from "react-icons/wi";
import { TiCalendarOutline } from "react-icons/ti";
import { MdOutlineEmojiFoodBeverage } from "react-icons/md";
import { SiFoodpanda } from "react-icons/si";
import { TfiShoppingCartFull, TfiVideoCamera } from "react-icons/tfi";
import { TbChristmasTree } from "react-icons/tb";
import { BsMoonStarsFill } from "react-icons/bs";
import { FaFlag } from "react-icons/fa";
import { GrAnnounce } from "react-icons/gr";

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_TOTAL_PAGES = 1;
export const INITIAL_NOTIFICATION_COUNT = 0;
// export const DEFAULT_IMAGE_WIDTH = 1080;
// export const DEFAULT_IMAGE_HEIGHT = 1080;
export const DEFAULT_FILE_SIZE_LIMIT_IN_BYTES = 5 * 1024 * 1024;

export const STATUS_CODES = {
  SUCCESS: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT_ERROR: 409,
  SERVER_ERROR: 500,
  UNPROCESSABLE_ENTITY: 422,
  TOO_MANY_REQUESTS: 429,
  UNSUPPORTED_MEDIA_TYPE: 415,
  INTERNAL_SERVER_ERROR: 500,
};

export const DEFAULT_MONTH = [
  {
    value: "01",
    label: "January",
  },
  {
    value: "02",
    label: "February",
  },
  {
    value: "03",
    label: "March",
  },
  {
    value: "04",
    label: "April",
  },
  {
    value: "05",
    label: "May",
  },
  {
    value: "06",
    label: "June",
  },
  {
    value: "07",
    label: "July",
  },
  {
    value: "08",
    label: "August",
  },
  {
    value: "09",
    label: "September",
  },
  {
    value: "10",
    label: "October",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "December",
  },
];

export const MONTHS = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const WEEKDAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const WEEKDAYSArr = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const REGISTER_INPUT_FIELDS = [
  {
    label: "First Name",
    type: "text",
    name: "firstName",
    id: "first-name",
    placeholder: "First Name",
    required: true,
  },
  {
    label: "Last Name",
    type: "text",
    name: "lastName",
    id: "last-name",
    placeholder: "Last Name",
    required: true,
  },
  {
    label: "Email",
    type: "email",
    name: "email",
    id: "user-email",
    placeholder: "name@company.com",
    required: true,
  },
  {
    label: "Mobile Number",
    type: "number",
    name: "phoneNumber",
    id: "mobile-number",
    placeholder: "Mobile Number",
    required: true,
  },
  {
    label: "Company Name",
    type: "text",
    name: "companyName",
    id: "company-name",
    placeholder: "Company name",
    required: true,
  },
  {
    label: "Password",
    type: "password",
    name: "password",
    id: "password",
    placeholder: "Enter Password",
    required: true,
    otherProps: {
      onPaste: e => {
        e.preventDefault();
      },
      onCopy: e => {
        e.preventDefault();
      },
    },
  },
  {
    label: "Confirm Password",
    type: "password",
    name: "confirmPassword",
    id: "confirm-password",
    placeholder: "Enter Password",
    required: true,
    otherProps: {
      onPaste: e => {
        e.preventDefault();
      },
      onCopy: e => {
        e.preventDefault();
      },
    },
  },
];

export const INPUT_FIELDS_CLASS = (className = "") =>
  `${className} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500`;

export const HOLIDAYS_DOTS_OPTIONS = [
  {
    id: uuidv4(),
    text: "Flexi Holiday",
    dotClassName: "shadow-[inset_0_0_0_1rem_#faf5cbc4] border-[#faf5cbc4] border",
    textClassName: "text-[.9rem] text-gray-500 font-semibold",
  },
];

export const DOCS_LIST = [
  {
    label: "Adhaar Card",
    value: FileTypes.AdhaarCard,
    itemClassName: "w-[19rem] h-[2.7rem]",
  },
  {
    label: "Pan Card",
    value: FileTypes.PanCard,
    itemClassName: "w-[19rem] h-[2.7rem]",
  },
  {
    label: "Passport",
    value: FileTypes.Passport,
    itemClassName: "w-[19rem] h-[2.7rem]",
  },
  {
    label: "Voter ID Card",
    value: FileTypes.VoterIdCard,
    itemClassName: "w-[19rem] h-[2.7rem]",
  },
  {
    label: "Driving License",
    value: FileTypes.DrivingLicense,
    itemClassName: "w-[19rem] h-[2.7rem]",
  },
  {
    label: "Matric Marksheet",
    value: FileTypes.MatricMarkSheet,
    itemClassName: "w-[19rem] h-[2.7rem]",
  },
  {
    label: "Inter Marksheet",
    value: FileTypes.InterMarksheet,
    itemClassName: "w-[19rem] h-[2.7rem]",
  },
  {
    label: "Graduation Certificate",
    value: FileTypes.GraduationCertificate,
    itemClassName: "w-[19rem] h-[2.7rem]",
  },
  {
    label: "Other",
    value: FileTypes.Other,
    itemClassName: "w-[19rem] h-[2.7rem]",
  },
];

export const PROFILE_TABS_ADMIN = [
  {
    id: "general",
    name: "general",
    icon: <GeneralSvg size={20} />,
  },
  {
    id: "skills",
    name: "skills",
    icon: <SkillsSvg className="w-[22px] h-[22px]" />,
  },

  {
    id: "attendance",
    name: "attendance",
    icon: <MdCoPresent className="w-[22px] h-[22px]" />,
  },

  {
    id: "leaves",
    name: "leaves",
    icon: <MdCardTravel className="w-[22px] h-[22px]" />,
  },

  // {
  //   id: "resignation",
  //   name: "resignation",
  //   icon: <BsPersonFillX className="w-[22px] h-[22px]" />,
  // },
];

export const PROFILE_TABS_USER = [
  {
    id: "general",
    name: "general",
    icon: <GeneralSvg size={20} />,
  },
  {
    id: "skills",
    name: "skills",
    icon: <SkillsSvg className="w-[22px] h-[22px]" />,
  },

  {
    id: "attendance",
    name: "attendance",
    icon: <MdCoPresent className="w-[22px] h-[22px]" />,
  },

  {
    id: "leaves",
    name: "leaves",
    icon: <MdCardTravel className="w-[22px] h-[22px]" />,
  },

  // {
  //   id: "resignation",
  //   name: "resignation",
  //   icon: <BsPersonFillX className="w-[22px] h-[22px]" />,
  // },
];

export const VIEW_DETAILS_TABS_ENUM = {
  skill: "skills",
  uploadedDocs: "uploadedDocuments",
};


export const CLIENTS_HEADING = [
  "Client Name",
  "Contact Person",
  "Contact Info",
  "Industry",
  "Total Invoices",
  "Actions",
];

export const BUSINESS_HEADING = [
  "Business Name",
  "Industry",
  "Total Invoices",
  "Actions",
];

export const PAYROLL_HISTORY_TABLE_HEADER = ["payment date", "payroll type", "details", "status"];

export const ICON_MAPPING = [
  {
    id: uuidv4(),
    iconName: "MdCelebration",
    iconComponent: <MdCelebration size={23} />,
  },
   {
    id: uuidv4(),
    iconName: "IoMdPaper",
    iconComponent: <IoMdPaper size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "GiGlassCelebration",
    iconComponent: <GiGlassCelebration size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "TbChristmasTree",
    iconComponent: <TbChristmasTree size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "ImTree",
    iconComponent: <BsMoonStarsFill size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "WiNightAltCloudyWindy",
    iconComponent: <WiNightAltCloudyWindy size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "FaFlag",
    iconComponent: <FaFlag size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "MdOutlineEmojiFoodBeverage",
    iconComponent: <MdOutlineEmojiFoodBeverage size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "GiFoodTruck",
    iconComponent: <GiFoodTruck size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "MdEmojiFoodBeverage",
    iconComponent: <MdEmojiFoodBeverage size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "SiFoodpanda",
    iconComponent: <SiFoodpanda size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "TfiShoppingCartFull",
    iconComponent: <TfiShoppingCartFull size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "TfiVideoCamera",
    iconComponent: <TfiVideoCamera size={23} />,
  },
  {
    id: uuidv4(),
    iconName: "GrAnnounce",
    iconComponent: <GrAnnounce size={23} />,
  },
];

//Icon colors
export const ICON_COLOR_MAPPING = [
  {
    id: uuidv4(),
    color: "#bad989",
  },
  {
    id: uuidv4(),
    color: "#aae09f",
  },
  {
    id: uuidv4(),
    color: "#b8fcc7",
  },
  {
    id: uuidv4(),
    color: "#b8fce2",
  },
  {
    id: uuidv4(),
    color: "#9dc4f5",
  },
  {
    id: uuidv4(),
    color: "#9e9df5",
  },
  {
    id: uuidv4(),
    color: "#bd9df5",
  },
  {
    id: uuidv4(),
    color: "#d7abf7",
  },
  {
    id: uuidv4(),
    color: "#f2abf7",
  },
  {
    id: uuidv4(),
    color: "#f7b5ee",
  },
  {
    id: uuidv4(),
    color: "#f7b5d5",
  },
  {
    id: uuidv4(),
    color: "#f7b5bf",
  },
  {
    id: uuidv4(),
    color: "#f7c5b5",
  },
  {
    id: uuidv4(),
    color: "#f7f3b5",
  },
  {
    id: uuidv4(),
    color: "#cabffd",
  },
];

//JOBS input fields
export const JOBS_INPUT_FIELDS = [
  {
    id: uuidv4(),
    label: "Job Name",
    type: "text",
    name: "name",
    placeholder: "Enter job name",
    required: true,
  },
  {
    id: uuidv4(),
    label: "Project",
    type: "AsyncSelect",
    name: "projectName",
    placeholder: "Search project",
    required: true,
  },
  {
    id: uuidv4(),
    label: "Start Date",
    type: "Date",
    name: "startDate",
    placeholder: "Start date",
    required: false,
  },
  {
    id: uuidv4(),
    label: "End Date",
    type: "Date",
    name: "endDate",
    placeholder: "End date",
    required: false,
  },
  {
    label: "Rate Per Hour ($)",
    type: "number",
    name: "rph",
    placeholder: 0.0,
    required: false,
  },
  {
    id: uuidv4(),
    label: "Billable Status",
    type: "Select",
    name: "isBillable",
    placeholder: "Select",
    required: false,
  },
  {
    id: uuidv4(),
    label: "Assignees",
    type: "AsyncSelect",
    name: "assignees",
    placeholder: "Search user",
    required: true,
  },
  {
    id: uuidv4(),
    label: "Description",
    type: "textarea",
    name: "description",
    placeholder: "Description",
    required: false,
  },
];

export const DATEFORMATS = {
  DAY_MONTH_YEAR_FORMAT: "dd MMM yyyy",
};

// Projects input fields
export const PROJECTS_INPUT_FIELDS = [
  {
    id: uuidv4(),
    label: "Project Name",
    type: "text",
    name: "name",
    placeholder: "Enter project name",
    required: true,
  },
  {
    id: uuidv4(),
    label: "Client Name",
    type: "AsyncSelect",
    name: "clientName",
    placeholder: "Enter client name",
    required: true,
  },
  {
    id: uuidv4(),
    label: "Project Cost",
    type: "number",
    name: "projectCost",
    placeholder: "Enter project cost",
    required: false,
  },

  {
    id: uuidv4(),
    label: "Project Head",
    type: "AsyncSelect",
    name: "projectHead",
    placeholder: "Enter project head",
    required: true,
  },
  {
    id: uuidv4(),
    label: "Project Manager",
    type: "AsyncSelect",
    name: "projectManager",
    placeholder: "Enter project manager",
    required: true,
  },
  {
    id: uuidv4(),
    label: "Assignees",
    type: "AsyncSelect",
    name: "assignees",
    placeholder: "Search user",
    required: true,
  },
  {
    id: uuidv4(),
    label: "Description",
    type: "textarea",
    name: "description",
    placeholder: "Description",
    required: false,
  },
];

export const DateDropdownOptions = {
  today: 'Today',
  yesterday: 'Yesterday',
  thisWeek: 'This Week',
  lastWeek: 'Last Week',
  thisMonth: 'This Month',
  lastMonth: 'Last Month',
  custom: 'Custom'
}

export const ImageBaseUrl = process.env.REACT_APP_BASE_URL+'/uploadedFiles/ProfileImages/';
