export const getPlaceholderInitials = (userName?: string) => {
  if (!userName) return "";
  const names = userName.split(" ");
  const firstNameInitial = names[0]?.[0].toUpperCase() || "";
  const lastNameInitial = names[names.length - 1]?.[0].toUpperCase() || "";
  return `${firstNameInitial}${lastNameInitial}`;
};

export const getStatusClass = (status?: string) => {
  if (!status) return "";
  const statusColors = {
    online: "bg-green-400",
    offline: "bg-gray-400",
  };

  return `absolute h-3.5 w-3.5 rounded-full border-2 border-white dark:border-gray-800 ${statusColors[status] || ""}`;
};

export const getIconBorderClass = (iconBorder: boolean, iconBorderColor?: string) =>
  iconBorder
    ? `ring-2 ${iconBorderColor ? `ring-${iconBorderColor}-300 dark:ring-${iconBorderColor}-500` : "ring-gray-300 dark:ring-gray-500"} rounded-full w-20 h-20`
    : "";

export const getStatusPositionClass = (iconStatusPosition: string) => {
  const positions: Record<string, string> = {
    "top-right": "top-0 right-0",
    "top-left": "top-0 left-0",
    "bottom-right": "bottom-0 right-0",
    "bottom-left": "bottom-0 left-0",
    "top-center": "-top-1 center",
    "bottom-center": "-bottom-1 center",
    center: "center center",
  };
  return positions[iconStatusPosition] || "";
};
