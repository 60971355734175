import React, { useState, useEffect, useContext, useCallback } from "react";
import { InputText } from "primereact/inputtext";
import { useAppSelector } from "../../../store/store";
import { AuthContext } from "../../../context/AuthContext";
import ModalComponent from "../../common/Modal/Modal";
import ModalButtons from "../../common/Modal/ModalButtons";
import { btnPrimary } from "../../../utils/helper";
import DepartmentDropdown from "../../common/DropDown/DepartmentDropdown";
import { GetUsers } from "../../../services/UserService";
import { Dropdown } from "primereact/dropdown";
import { showToast } from "../../common/Toast";
import AsyncSelect from "react-select/async";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../../constants/Constant";

const AddEditDepartment = ({ department, onSave, onCancel, showModal, onCloseModal }) => {
  const user = useAppSelector(state => state.userManagement.user);
  const { user: authUser } = useContext(AuthContext);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedDepartmentHead, setSelectedDepartmentHead] = useState("");
  const [users, setUsers] = useState([]); // State to store users
  const [searchQuery, setSearchQuery] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  // Validate form fields
  const validateForm = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = "Please enter a department name";
    }

    return errors;
  };

  const [formData, setFormData] = useState({
    name: "",
    code: "",
    description: "",
    status: "",
    createdBy: "",
    updatedBy: "",
    departmentHeadId: null,
    parentDepartmentId: null,
  });

  // Reusable fetchUsers function
  const fetchUsers = async (query = "", pageSize = DEFAULT_PAGE_SIZE, pageNo = DEFAULT_PAGE_NO) => {
    try {
      const response = await GetUsers({
        search: query,
        pageSize,
        pageNo,
      });
      setUsers(response.data || []);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Call fetchUsers when searchQuery changes
  useEffect(() => {
    if (searchQuery.trim() !== "") {
      fetchUsers(searchQuery);
    } else {
      fetchUsers(); // Default fetch for all users
    }
  }, [searchQuery]);

  useEffect(() => {
    if (department) {
      setFormData({
        ...department,
        status: department.status,
        createdBy: department.createdBy,
        updatedBy: authUser.id,
        departmentHeadId: department.departmentHeadId,
        parentDepartmentId: department.parentDepartmentId,
      });
      setSelectedDepartmentHead(
        department.departmentHeadId
          ? { value: department.departmentHeadId, label: department.departmentHeadName }
          : null
      );
      if (department.parentDepartmentId) {
        setSelectedDepartments([
          { id: department.parentDepartmentId, name: department.parentDepartmentName },
        ]);
      } else {
        setSelectedDepartments([]);
      }
    } else {
      setFormData({
        name: "",
        code: "",
        description: "",
        status: 1,
        createdBy: authUser.id,
        updatedBy: authUser.id,
        departmentHeadId: null, // Reset department head
        parentDepartmentId: null,
      });
      setSelectedDepartmentHead(null);
      setSelectedDepartments([]); // Clear selected departments
    }
  }, [department]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    // Validate inputs
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      if (errors.name) showToast("error", errors.name); // Show toast for department name
      return;
    }

    try {
      await onSave(formData);

      onCloseModal();
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  // Handle department head selection
  const handleDepartmentHeadChange = selectedOption => {
    setSelectedDepartmentHead(selectedOption);
    setFormData(prev => ({
      ...prev,
      departmentHeadId: selectedOption ? selectedOption.value : null,
    }));
  };

  // Clean up timeout on component unmount
  useEffect(() => {
    return () => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
    };
  }, []);

  const handleDepartmentsChange = departmentIds => {
    // Extract the id value (assuming departmentIds is an array of objects)
    const parentDepartmentId = departmentIds.length > 0 ? departmentIds[0].id : null;

    setSelectedDepartments(departmentIds); // Keep full array if needed for other purposes

    // Update formData with only the id value
    setFormData(prev => ({
      ...prev,
      parentDepartmentId: parentDepartmentId,
    }));
  };

  const loadOptions = async inputValue => {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    return new Promise(resolve => {
      const newTimeout = setTimeout(async () => {
        try {
          const response = await GetUsers({ search: inputValue, pageSize: DEFAULT_PAGE_SIZE, pageNo: DEFAULT_PAGE_NO });
          resolve(
            response.data.map(user => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }))
          );
        } catch (error) {
          console.error("Error loading users:", error);
          resolve([]);
        }
      }, 300); // Debounce time

      setDebounceTimeout(newTimeout);
    });
  };

  return (
    <ModalComponent
      show={showModal}
      onClose={onCloseModal}
      heading={department ? "Edit Department" : "Add Department"}
      size="lg"
      showFooter={false}
      className="custom-modal-class"
      bodyClassName="px-2 py-4 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div className="max-w-md mx-auto bg-white dark:bg-gray-900 rounded-lg ">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Name
            </label>
            <InputText
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-1 border rounded-md dark:bg-gray-800 dark:text-white dark:border-gray-700 focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="code"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Code
            </label>
            <InputText
              id="code"
              name="code"
              value={formData.code}
              onChange={handleChange}
              className="w-full px-3 py-1 border rounded-md dark:bg-gray-800 dark:text-white dark:border-gray-700 focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="departmentHead"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Department Head
            </label>
            <AsyncSelect
              isClearable
              cacheOptions
              defaultOptions
              loadOptions={loadOptions}
              value={selectedDepartmentHead}
              onChange={handleDepartmentHeadChange}
              placeholder="Search user"
              className="text-sm"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="parentDepartment"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Parent Department
            </label>
            <div className="w-full">
              <DepartmentDropdown
                selectedDepartments={selectedDepartments}
                onDepartmentsChange={handleDepartmentsChange}
                multiSelect={false}
                excludeDepartmentId={formData.id} //exclude the selected department from the parent department list
                className="w-full"
              />
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full h-40 px-3 py-1 rounded-md border border-gray-300 dark:bg-gray-800 dark:text-white dark:border-gray-700 focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        {/* Submit Buttons */}
        <div className="flex justify-end gap-3 mt-6">
          <ModalButtons
            btnCont="flex justify-end gap-2"
            cancelBtnText="Cancel"
            actionBtnText={department ? "Update" : "Submit"}
            onClickCancel={onCancel}
            onClickAction={handleSubmit}
            actionBtnType="button"
            actionBtnClass={`${btnPrimary()} rounded-md text-white font-semibold  px-4 py-[10px] tracking-wide`}
            cancelBtnClass="gray"
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export default AddEditDepartment;
