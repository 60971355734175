import React, { useState, useEffect } from "react";
import { Pagination } from "flowbite-react";
import Table from "../../common/Table/Table";
import ModalComponent from "../../common/Modal/Modal";
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NO } from "../../../constants/Constant";
const EmployeeListModal = ({
  visible,
  onClose,
  departmentId,
  departmentName,
  pageSize = DEFAULT_PAGE_SIZE,
  fetchDepartmentDetails
}) => {
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);
  const [employees, setEmployees] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState(0);

  useEffect(() => {
    const loadEmployees = async () => {
      if (!visible || !departmentId) return;
      
      try {
        const response = await fetchDepartmentDetails(departmentId, pageNo, pageSize);
        setEmployees(response.employees || []);
        setTotalEmployees(response.totalEmployees || 0);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    loadEmployees();
  }, [departmentId, pageNo, pageSize, visible]);

  const handlePageChange = newPage => {
    setPageNo(newPage);
  };

  const totalPages = Math.ceil(totalEmployees / pageSize);

  return (
    <ModalComponent
      show={visible}
      onClose={onClose}
      size="lg"
      heading={`Employees in ${departmentName}`}
      body={
        <>
          {totalEmployees > 0 ? (
            <div className="p-1 mt-4 rounded-2xl bg-gray-50">
              <Table
                col={["Name"]}
                rows={employees.map((emp, index) => [
                  (pageNo - 1) * pageSize + index + 1,
                  [`${emp.firstName} ${emp.lastName}`],
                ])}
              />
            </div>
          ) : (
            <p className="text-gray-500 dark:text-gray-300">No employees found</p>
          )}
          {totalPages > 1 && (
            <div className="flex justify-end mt-4">
              <Pagination
                currentPage={pageNo}
                onPageChange={handlePageChange}
                totalPages={totalPages}
                showIcons={true}
              />
            </div>
          )}
        </>
      }
    />
  );
};

export default EmployeeListModal;