import { memo } from "react";
import { capitalizeFirstLetter } from "../helper";

/**
 * Generates a table header component with the specified columns.
 *
 * @param {{cols: Array, styles?: {tableHead?: string, tableRow?: string, tableCol?: string}, otherRowProps?: Object, otherTableColProps?: Object}} props - Props for the table header.
 * @param {Array} cols - An array of column names.
 * @param {Object} styles - An object containing CSS styles for the table header.
 * @param {Object} otherRowProps - Additional props to be applied to the <tr> element ex- onClick etc.
 * @param {Object} otherTableColProps - Additional props to be applied to the <th> elements.
 * @return {JSX.Element} - The table header component.
 */
function TableHeaderCB({ cols, styles, otherRowProps, otherTableColProps, needCheckBox, checkBoxContent}) {
  return (
    <thead className={`${styles?.tableHead} text-xs uppercase`}>
      <tr {...otherRowProps} className={styles?.tableRow}>
        {cols.map((col, i) => (
          <th
            {...otherTableColProps}
            key={i}
            scope="col"
            className={`${styles?.tableCol} px-4 py-3 !text-left`}>
            <span className={`${styles?.headerText} whitespace-nowrap`}>
              {needCheckBox && i === 0 && checkBoxContent}
              {capitalizeFirstLetter(col)}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
}

/**
 * Generates a table header component with the specified columns.
 *
 * @param {Array} cols - An array of column names.
 * @param {Object} styles - An object containing CSS styles for the table header.
 * @param {Object} otherRowProps - Additional props to be applied to the <tr> element ex- onClick etc.
 * @param {Object} otherTableColProps - Additional props to be applied to the <th> elements.
 * @return {JSX.Element} - The table header component.
 */
const TableHeader = memo(TableHeaderCB);

export default TableHeader;
