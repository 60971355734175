import { lazy, Suspense, useContext, memo } from "react";
import { AuthContext } from "../context/AuthContext";
import { Role } from "../enum/Role";
import { Navigate, useSearchParams } from "react-router-dom";
import { Route, Routes, useLocation } from "react-router";
import { useStore } from "../context/createFastContext";
import RestrictedPage from "../components/common/RestrictedPage";
import ManageSettings from "../components/Attendance/MyAttendanceSetting/ManageSettings";
import Subscription from "../pages/Subscription/Subscription";
import Department from "../components/Department/Department";
import ManageEmployees from "../components/EmployeeManagement/ViewDetails/ManageEmployees";

// Lazy loaded pages
const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register/Register"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const VerifyUser = lazy(() => import("../pages/VerifyUser"));
const MultiStepForm = lazy(() => import("../pages/MultiStepForm/MultiStepForm"));
const RootLayout = lazy(() => import("../pages/Root/RootLayout"));
const RequiredDetailsRoot = lazy(() => import("../pages/Root/RequiredDetailsRoot"));

// Home and Profile
const Home = lazy(() => import("../pages/Home/Home"));
const Profile = lazy(() => import("../pages/Profile"));

// Team and Attendance
const MyTeams = lazy(() => import("../pages/MyTeams/MyTeams"));
const AttendanceManagement = lazy(() => import("../pages/Attendance Management/AttendanceManagement"));
const PaySlips = lazy(() => import("../components/payslips/PaySlips"));
const Holidays = lazy(() => import("../pages/Holidays/Holidays"));

// Admin Routes
const ControlPanel = lazy(() => import("../pages/ControlPanel"));
const EmployeeManagement = lazy(() => import("../pages/EmployeeManagement"));
const Roles = lazy(() => import("../pages/Roles/Roles"));
const LeaveConfigurations = lazy(() => import("../pages/LeaveConfigurations/LeaveConfigurations"));
const SalaryComponents = lazy(() => import("../pages/SalaryComponents/SalaryComponents"));
const OrganizationDetails = lazy(() => import("../pages/OrganizationDetails/OrganizationDetails"));
const ExceptionLogs = lazy(() => import("../pages/ExceptionLog"));
const SkillManagement = lazy(() => import("../pages/SkillManagement"));
const Resignations = lazy(() => import("../pages/Resignations"));
const LogoBranding = lazy(() => import("../pages/Logo-Branding/LogoBranding"));

// Invoice Routes
const InvoiceManagement = lazy(() => import("../pages/Invoice/InvoiceManagement"));
const Invoice = lazy(() => import("../pages/Invoice/Invoice"));
const Businesses = lazy(() => import("../pages/Invoice/Businesses"));
const Clients = lazy(() => import("../components/Attendance/Clients/Clients"));
const CreateInvoice = lazy(() => import("../pages/Invoice/CreateInvoice"));
const UpdateInvoice = lazy(() => import("../pages/Invoice/UpdateInvoice"));

// Payroll Routes
const Payroll = lazy(() => import("../pages/Payroll/Payroll"));
const PayrollRun = lazy(() => import("../components/Payroll/PayrollRun"));
const PayrollHistory = lazy(() => import("../components/Payroll/PayrollHistory"));
const ViewDetails = lazy(() => import("../components/Payroll/ViewDetails"));

// Leave Management Routes
const LeaveManagement = lazy(() => import("../pages/LeaveManagement/LeaveManagement"));
const MyLeaves = lazy(() => import("../components/Leaves/MyLeaves"));
const MyApprovals = lazy(() => import("../components/Leaves/MyApprovals"));
const LeaveTypes = lazy(() => import("../components/Leaves/LeaveTypes"));
const CustomizeBalance = lazy(() => import("../components/Leaves/CustomizeBalance"));

// Other Routes
const Pricing = lazy(() => import("../pages/Pricing"));
const AboutUs = lazy(() => import("../pages/AboutUs"));
const Checkout = lazy(() => import("../pages/payments/Checkout"));
const ContactUs = lazy(() => import("../pages/ContactUs"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("../pages/TermsAndConditions"));
const CancellationAndRefundPolicy = lazy(() => import("../pages/CancellationAndRefundPolicy"));

// Attendance Routes
const MyAttendance = lazy(() => import("../components/Attendance/MyAttendance/MyAttendance"));
const Approvals = lazy(() => import("../components/Attendance/Approvals/Approvals"));
const Job = lazy(() => import("../pages/Jobs/Job"));
const Project = lazy(() => import("../pages/Project/Project"));

// Error Pages
const AccessDeniedPage = lazy(() => import("../components/common/AccessDeniedPage"));
const PageNotFound = lazy(() => import("../components/common/PageNotFound"));

// Salary Routes
const SalaryComponentManagement = lazy(() => import("../pages/SalaryComponents/SalaryComponentManagement"));
const StatutoryComponent = lazy(() => import("../pages/SalaryComponents/StatutoryComponent"));

const AllRoutes = () => {
  const { user, userAuth } = useContext(AuthContext);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path="/login"
          element={
            <LoginRoute user={user} userAuth={userAuth}>
              <Login />
            </LoginRoute>
          }
        />

        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
        <Route path="/verify-user/:token/:email" element={<VerifyUser />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-user" element={<VerifyUser />} />

        <Route path="/required-details" element={<RequiredDetailsRoot />}>
          <Route
            index
            element={
              <RequiredDetailsRoute user={user}>
                <MultiStepForm />
              </RequiredDetailsRoute>
            }
          />
        </Route>

        {/* Protected routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute user={user} userAuth={userAuth}>
              <RootLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Home />} />
          <Route path="profile" element={<Profile />} />
          <Route path="my-team" element={<MyTeams />} />
          <Route path="my-attendance" element={<AttendanceManagement />} />
          <Route path="payslips" element={<PaySlips />} />
          <Route path="holidays" element={<Holidays />} />

          {(user?.roleId === Role.Administrator || user?.roleId === Role.Moderator) && (
            <>
              <Route path="control-panel" element={<ControlPanel />}>
                <Route index path="users-management" element={<EmployeeManagement />} />
                <Route index path="Roles" element={<Roles />} />
                <Route path="leave-configurations" element={<LeaveConfigurations />} />
                <Route path="salary-components" element={<SalaryComponents />} />
                <Route path="organisation-details" element={<OrganizationDetails />} />
                <Route path="exception-logs" element={<ExceptionLogs />} />
                <Route path="skill-management" element={<SkillManagement />} />
                <Route path="resignations" element={<Resignations />} />
                <Route path="logo-branding" element={<LogoBranding />} />
              </Route>

              <Route path="invoices" element={<InvoiceManagement />}>
                <Route index path="allInvoices" element={<Invoice />} />
                <Route index path="businesses" element={<Businesses />} />
                <Route index path="clients" element={<Clients />} />
              </Route>

              <Route path="Invoice/create" element={<CreateInvoice />} />
              <Route path="Invoices/:id" element={<UpdateInvoice />} />

              <Route path="payroll" element={<Payroll />}>
                <Route index path="payroll-run" element={<PayrollRun />} />
                <Route path="payroll-history" element={<PayrollHistory />} />
              </Route>

              <Route path="payroll/:id" element={<ViewDetails />} />

              <Route path="users" element={<EmployeeManagement />}>
                <Route index path="my-people" element={<ManageEmployees />} />
                <Route path="roles" element={<Roles />} />
                <Route path="departments" element={<Department />} />
                <Route path="skills-management" element={<SkillManagement />} />
              </Route>

              <Route path="settings">
                <Route path="payroll-components" element={<SalaryComponentManagement />}>
                  <Route path="salary" element={<SalaryComponents />} />
                  <Route path="statutory" element={<StatutoryComponent />} />
                </Route>
                <Route path="organisation-details" element={<OrganizationDetails />} />
                <Route path="exception-logs" element={<ExceptionLogs />} />
                <Route path="logo-branding" element={<LogoBranding />} />
              </Route>
            </>
          )}

          <Route path="leaves" element={<LeaveManagement />}>
            <Route index path="my-leaves" element={<MyLeaves />} />
            <Route path="my-approvals" element={<MyApprovals />} />
            <Route path="leave-types" element={<LeaveTypes />} />
            <Route path="customize-balance" element={<CustomizeBalance />} />
          </Route>

          <Route path="attendance" element={<AttendanceManagement />}>
            <Route index path="my-attendance" element={<MyAttendance />} />
            <Route index path="settings" element={<ManageSettings />} />
            {/* <Route path="approvals" element={<Approvals />} /> */}

            {(user?.roleId === Role.Administrator || user?.roleId === Role.Moderator) && (
              <>
                {/* <Route path="jobs" element={<Job />} /> */}
                {/* <Route path="projects" element={<Project />} /> */}
                <Route path="clients" element={<Clients />} />
              </>
            )}
          </Route>

          <Route path="pricing" element={<Pricing />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="cancellation-and-refund-policy" element={<CancellationAndRefundPolicy />} />
          <Route path="my-subscription" element={<Subscription />} />
        </Route>

        <Route path="/403-Forbidden" element={<AccessDeniedPage />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/403-Restricted" element={<RestrictedPage />} />
      </Routes>
    </Suspense>
  );
};

export default memo(AllRoutes);

const LoginRoute = ({ children }) => {

  return <>{children}</>;
};

const ProtectedRoute = ({ children }) => {
  return children
};

const RequiredDetailsRoute = ({ children, user }) => {
  const [initialContextState, _] = useStore(s => s);
  if (initialContextState.orgCompletionStatus.isCompleted) {
    return <Navigate to="/" replace />;
  }

  if (user && user?.roleId && user.roleId === Role.Administrator) {
    return <>{children}</>;
  }

  return <Navigate to="/" replace />;
};
