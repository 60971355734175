import { Button } from "flowbite-react";
import ModalComponent from "../../common/Modal/Modal";
import { DeleteDepartment } from "../../../services/DepartmentService";
import InfoIconSvg from "../../../media/InfoIconSvg";

const DeleteDepartmentModal = ({ modals, departmentToDelete, setDepartmentToDelete, cancelDelete, handleDelete }) => {
  const onClickDeleteHandler = async () => {
    try {
      await DeleteDepartment(departmentToDelete.id); // Call the service directly
      handleDelete(departmentToDelete.id); // Call the parent handler after successful deletion
    } catch (error) {
      console.error("Error deleting department:", error.response?.data || error.message);
    }
  };

  const onClickCancelHandler = () => {
    cancelDelete(); // Call the parent cancel handler
  };

  return (
    <ModalComponent
      show={modals.deleteModal} // Controls visibility based on the modal state
      heading="Delete Department"
      showFooter={false}
      bodyClassName="px-6"
      size="2xl"
      onClose={onClickCancelHandler}>
      <div>
        <h5 className="font-semibold text-gray-700 my-5 text-center">
          Are you sure you want to delete the department "{departmentToDelete.name}"?
        </h5>
      </div>

      <div className="flex items-center justify-center gap-2 my-4">
        <InfoIconSvg className="w-3 h-3 fill-gray-500" />
        <span className="text-gray-700 text-sm">
          Warning: This action is irreversible and may affect department assignments and employee data.
        </span>
      </div>

      <div className="flex justify-end items-center gap-4">
        <Button onClick={onClickDeleteHandler} color="failure">
          Yes, delete it
        </Button>
        <Button onClick={onClickCancelHandler} color="gray">
          Cancel
        </Button>
      </div>
    </ModalComponent>
  );
};

export default DeleteDepartmentModal;
