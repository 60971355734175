import { memo, useCallback, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Role } from "../../enum/Role";
import { UserStatus } from "../../enum/UserStatus";
import { formatDate, formatTimeByRegion, formatTimeUTC } from "../../utils/helper";
import EmailSvg from "../../media/EmailSvg";
import { cn, getUser } from "../../utils/common";
import Table from "../common/Table/Table";
import Menu from "../common/Menu/Menu";
import { getActionMenuItems, HEADINGS } from "./helper";
import { useAppDispatch } from "../../store/store";
import { setIsEditingUser, setRefetchUsers, setToggleDeactivateUserModal, setToggleDeleteUserModal, setToggleReactivateUserModal, setToggleUserModal, setUser } from "../../store/slices/userManagementSlice";
import { GetUserById } from "../../services/UserService";
import { AddEditEmployeeModal } from "./EditEmployee";
import { RoleEmployee } from "./RoleEmployee";
import DeleteEmployee from "./DeleteEmployee";
import DeActivateEmployee from "./DeActivateEmployee";
import ReActivateEmployee from "./ReActivateEmployee";
import Avatar from "../common/Avatar/Avatar";
import { resendVerificationEmail } from "../../services/AccountService";
import { STATUS_CODES } from "../../constants/Constant";
import { showToast } from "../common/Toast";
import { EMPLOYEE_LIST_MESSAGES } from "../../constants/Messages";
import TWTooltip from "../common/TWTooltip";
import InfoIconSvg from "../../media/InfoIconSvg";
import ListItem from "../common/Widgets/listingWidget/listItem/ListItem";

const EmployeesList = ({ users, onSort, sortConfig }) => {
  const navigate = useNavigate();
  const currentUser = getUser();
  const [_, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  //State for updating Role
  const [toggleChangeRoleModal, setToggleChangeRoleModal] = useState(false);

  const goToUserProfile = useCallback(
    (userId) => {
      if (
        currentUser?.roleId === Role.Administrator ||
        currentUser?.roleId === Role.Moderator
      ) {
        navigate(`/profile?userId=${userId}`);
      } else {
        navigate("/403-Forbidden");
      }
    },
    [currentUser, navigate]
  );

  const onClickNavigateHandler = useCallback(
    (user) => setSearchParams({ userId: user?.id }),
    [setSearchParams]
  );

  const handleNavigateClick = (user) => () => {
    onClickNavigateHandler(user);
  };

  //Function to submit resend verification email for the users whose email is not verified
  const onSubmitResendEmailVerification = async (userId, message) => {
    if (userId && message === "resendVerificationEmail") {
      const response = await resendVerificationEmail(userId);
      if (response?.status === STATUS_CODES.SUCCESS) {
        return showToast("success", EMPLOYEE_LIST_MESSAGES.RESEND_VERIFICATION_EMAIL_SUCCESS);
      } else {
        return showToast("error", EMPLOYEE_LIST_MESSAGES.RESEND_VERIFICATION_EMAIL_FAILED);
      }
    }

    if (userId && message === "sendVerificationEmail") {
      const response = await resendVerificationEmail(userId);
      if (response?.status === STATUS_CODES.SUCCESS) {
        return showToast("success", EMPLOYEE_LIST_MESSAGES.SEND_VERIFICATION_EMAIL_SUCCESS);
      } else {
        return showToast("error", EMPLOYEE_LIST_MESSAGES.SEND_VERIFICATION_EMAIL_FAILED);
      }
    }
  };

  // Function for toggling delete and and setting the user id
  const onHandleToggleDeleteUser = user => {
    dispatch(setUser(user));
    dispatch(setToggleDeleteUserModal(true));
  };

  //Function for toggling Deactivate, Reactivate user getting the user id
  const onHandleToggleDeactivateAndReactivateUser = (userAction, user) => {
    dispatch(setUser(user));
    //when user clicks on deactivate button
    if (userAction === "deactivate") {
      dispatch(setToggleDeactivateUserModal(true));
    }

    //when user clicks on reactivate button
    if (userAction === "reactivate") {
      dispatch(setToggleReactivateUserModal(true));
    }
  };

  const getIndividualUserDetails = async userId => {
    const res = await GetUserById(userId);
    const user = res?.data;
    dispatch(setUser(user));
  };

  const menuItems = (user) => getActionMenuItems(user, currentUser, dispatch, getIndividualUserDetails, setUser, setIsEditingUser, setToggleUserModal, goToUserProfile, setToggleChangeRoleModal, onSubmitResendEmailVerification, onHandleToggleDeactivateAndReactivateUser, onHandleToggleDeleteUser);

  const formatLogInDateTime = useCallback((dateTime) => {
    if (!dateTime) {
      return "No login record";
    }
    if (dateTime === "0001-01-01T00:00:00") {
      return (
        <div className="flex items-center">
          No login
          <TWTooltip text="User has not logged in since the system update." iconClassName="ml-1 mt-1 w-3.5 h-3.5" defaultIconColor="#77a9b0" defaultIconSize={12}>
          </TWTooltip>
        </div>
      );
    }
    const date = new Date(dateTime);
    const formattedDate = formatDate(date);
    const formattedTime = formatTimeByRegion(dateTime)
    return (
      <div>
        {formattedDate}
        <span className="text-thynkwebPrimary-900">{`\n${formattedTime}`}</span>
      </div>
    );
  }, []);

  const rows = useMemo(() => {
    return users.map(user => [
      { id: user.id }, // data-id for the row
      [  
        <div
          className="flex items-center cursor-pointer"
        >
          <ListItem 
            key={user.id}
            title={`${user?.firstName} ${user?.lastName}`}
            onItemClick={handleNavigateClick(user)}
            avatarProps={{
              className: 'tc-small-avatar !-mb-4 !mt-1 drop-shadow-md',
              userName: `${user?.firstName} ${user?.lastName}`,
              rounded: true,
              img: user?.imageBytes && `data:image/jpeg;base64,${user?.imageBytes}`,
            }}
            parentClass={cn('my-[0px]', 'text-sm font-semibold text-gray-900 drop-shadow-md')}
            titleParentClass={'min-w-0 mr-4'}
            showBar={false}
            showBarClass={'bg-transparent'}
            subtitle={user?.employeeNumber || ""}
            subtitleClass="!text-[0.7rem] !text-gray-500 !truncate !dark:text-gray-400"
          />
        </div>,
        
        <div className="inline-flex gap-1 items-center bg-thynkwebPrimary-50 text-thynkwebPrimary-900 text-xs font-medium px-2 py-0.5 rounded dark:bg-thynkwebPrimary-900 dark:text-thynkwebPrimary-300">
          <EmailSvg />
          {user?.email}
        </div>,
        
        <div className="inline-flex items-center text-xs font-semibold text-gray-700 py-0.5 rounded">
          {user?.roleId === Role.Administrator ? "Administrator" :
           user?.roleId === Role.Moderator ? "Moderator" : "Member"}
        </div>,
        
        <div className="text-xs font-semibold text-gray-700">{user?.phoneNumber}</div>,
        <div className="text-xs font-semibold text-gray-700">{user?.designation}</div>,
        
        <div className="flex items-center text-xs font-semibold text-gray-700">
          {user?.status === UserStatus.Active ? (
            <><div className="w-3 h-3 mr-2 bg-green-700 border rounded-full"></div> Active</>
          ) : user?.status === UserStatus.Deactivated ? (
            <><div className="w-3 h-3 mr-2 bg-red-700 border rounded-full"></div> Inactive</>
          ) : user?.status === UserStatus.RestrictedLogin ? (
            <>
              <div className="w-3 h-3 mr-2 bg-yellow-400 border rounded-full"></div>
              Restricted Login
            </>
          ) : (
            <>
              <div className="w-3 h-3 mr-2 bg-yellow-500 border rounded-full"></div>
              Pending Verification
            </>
          )}
        </div>,
        
        <div className="text-xs font-semibold text-gray-700">{formatDate(user.dateOfJoining)}</div>,
        <div className="whitespace-pre text-xs font-semibold text-gray-700">{formatLogInDateTime(user?.lastLogin)}</div>,
        <div className="font-semibold text-gray-700"><Menu menuItems={menuItems(user)} /></div>
      ]
    ]);
  }, [users, onClickNavigateHandler, menuItems]);

  return (
    <div className="overflow-x-auto rounded-lg shadow-md">
      <div className="min-w-full inline-block align-middle">
        <div className="overflow-hidden rounded-t-lg">
          <Table
            col={HEADINGS}
            rows={rows}
            trClassName="!cursor-default"
            sorting={true}
            onSort={onSort}
            sortConfig={sortConfig}
          />
        </div>
      </div>
      <AddEditEmployeeModal />
      <RoleEmployee
        toggleRole={toggleChangeRoleModal}
        setToggleRole={setToggleChangeRoleModal} 
      />
      <DeleteEmployee />
      <DeActivateEmployee />
      <ReActivateEmployee />
    </div>
  );
};

export default memo(EmployeesList);
