import { axiosInstance as axios } from "../context/AuthContext";

const FILE_UPLOAD_BASE_URL = `${process.env.REACT_APP_BASE_URL}/Files`;
const RESUMES_BASE_URL = `${FILE_UPLOAD_BASE_URL}/resumes`;
const user = JSON.parse(localStorage.getItem("user"));

/**
 * Retrieves all user files for a given user ID.
 *
 * @param {string} userId - The ID of the user.
 * @return {Promise<any>} - A promise that resolves to the data of the user files.
 */
export const getAllUserFiles = async function (userId) {
  const res = await axios.get(`${FILE_UPLOAD_BASE_URL}?userId=${userId}`);
  return res.data?.data;
};

/**
 * Uploads a file using the provided data.
 *
 * @param {{userId: number, fileType: string, file: File}} data - The data used to upload the file.
 * @return {object} The response data from the file upload.
 */
export const addFile = async function (data) {
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("userId", data.userId);
  formData.append("fileType", data.fileType);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const customAxios = axios.create(config);

  const res = await customAxios.post(FILE_UPLOAD_BASE_URL, formData);
  return res.data;
};

/**
 * Uploads a user file to the server.
 *
 * @param {{userId: number, fileType: string, file: File}} data - The file data to be uploaded.
 * @return {Promise} A promise that resolves to the response data.
 */
// export const uploadUserFile = async function (data) {
//   const res = await axios.put(FILE_UPLOAD_BASE_URL, data);
//   return res.data;
// };

/**
 * Deletes a user file.
 *
 * @param {{userId: number, docId: number}} data - The data representing the file to be deleted.
 * @return {Promise<any>} The response data from the delete request.
 */
export const deleteUserFile = async function (data) {
  const res = await axios.delete(
    `${FILE_UPLOAD_BASE_URL}?userId=${data.userId}&fileType=${data.fileType}`
  );
  return res.data;
};

//Resume Services
export const getResume = async function (userId) {
  const res = await axios.get(`${RESUMES_BASE_URL}?userId=${userId}`);
  return res.data;
};

/**
 * Uploads a user file to the server.
 *
 * @param {{userId: number, fileType: string, file: File}} data - The file data to be uploaded.
 * @return {Promise} A promise that resolves to the response data.
 */
export const uploadResume = async function (file, data) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("userId", data.userId);
  formData.append("fileType", data.fileType);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const customAxios = axios.create(config);

  const res = await customAxios.put(`${RESUMES_BASE_URL}`, formData);
  return res.data;
};

export const deleteResume = async function (userId) {
  const res = await axios.delete(`${RESUMES_BASE_URL}?userId=${userId}`, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const getImportCsvSample = async function () {
  const res = await axios.get(`${FILE_UPLOAD_BASE_URL}/importUserCsv`, {responseType: 'blob'});
  return res;
}
