import React, { useEffect, useState } from "react";
import ModalComponent from "../../../../common/Modal/Modal";
import ModalButtons from "../../../../common/Modal/ModalButtons";
import {
  getShiftsAvailableForDepartment,
  assignShiftToEmployees,
} from "../../../../../services/AttendanceService";

const AvailableAndAssignShift = ({
  isShiftModalOpen,
  handleCloseModal,
  handleSubmit,
  handleInputChange,
  shiftData,
}) => {
  const [availableShifts, setAvailableShifts] = useState([]);
  useEffect(() => {
    const fetchAvailableShifts = async () => {
      if (shiftData && shiftData.departmentId) {
        try {
          const shifts = await getShiftsAvailableForDepartment(shiftData.departmentId);
          setAvailableShifts(Array.isArray(shifts.data) ? shifts.data : []);
        } catch (error) {
          console.error("Error fetching available shifts:", error);
        }
      } else {
        setAvailableShifts([]);
      }
    };

    fetchAvailableShifts();
  }, [shiftData]);

  // Filter out the shift that is already assigned
  const filteredShifts = availableShifts.filter(
    shift => shift.id !== shiftData.shiftId // Exclude the current shift
  );

  const handleAssignShift = shiftId => {
    if (!shiftId) {
      console.error("Invalid shiftId:", shiftId);
      return;
    }

    const formattedDate = new Date(
      shiftData.selectedDate.getTime() - shiftData.selectedDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10);

    const assignShiftData = {
      employeeIds: [shiftData.employeeId], // Ensure this is an array of employee IDs
      startDate: formattedDate,
      endDate: formattedDate,
      shiftId: shiftId,
      notes: "",
    };

    assignShiftToEmployees(assignShiftData)
      .then(response => {
        handleSubmit(true); // Indicate success
        handleCloseModal(); // Close modal after assignment
      })
      .catch(error => {
        console.error("Error assigning shift to employees:", error);
      });
  };

  return (
    <ModalComponent
      show={isShiftModalOpen}
      size="md"
      bodyClassName="gap-4 mt-2"
      heading="Employee Shifts"
      showFooter={false}
      subModal={true}
      onClose={handleCloseModal}>
      <div className="flex flex-col gap-4">
        {/* Display assigned shift details */}
        {shiftData ? (
          <div className="p-4 bg-gray-100 rounded-md">
            <div className="text-sm text-gray-600">
              Current Shift: <span className="font-semibold">{shiftData.shiftName}</span>
            </div>
          </div>
        ) : (
          <div>No shift assigned</div>
        )}

        {/* Render available shifts */}
        {filteredShifts.length > 0 ? (
          <div className="max-h-[60vh] overflow-y-auto space-y-4">
            {filteredShifts.map(({ id, shiftName, shiftStart, shiftEnd }) => (
              <div
                key={id}
                className="py-2 bg-white rounded-sm shadow-sm flex justify-between items-center flex-wrap sm:flex-nowrap gap-3">
                <div>
                  <div className="text-xs sm:text-lg">{shiftName}</div>
                  <div className="text-sm text-gray-500">
                    {shiftStart.slice(0, -3)} - {shiftEnd.slice(0, -3)}
                  </div>
                </div>
                <ModalButtons
                  btnCont="flex sm:justify-start justify-center w-full sm:w-auto"
                  onClickAction={() => handleAssignShift(id)}
                  actionBtnType="button"
                  actionBtnClass="px-4 py-2 bg-blue-500 text-white rounded-md"
                  actionBtnText="Assign"
                  hideCancelBtn
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500 mt-4">No other shifts left for this user.</div>
        )}
      </div>
    </ModalComponent>
  );
};

export default AvailableAndAssignShift;
