import React, { useState, useMemo, useCallback } from "react";
import ConfirmationModal, { ConfirmationModalProps } from "./ConfirmationModal";
import { HiOutlineExclamationCircle, HiOutlineTrash } from "react-icons/hi";
import Avatar, { ReusableAvatarProps } from "../Avatar/Avatar";
import ModalButtons from "./ModalButtons";
import InfoIconSvg from "../../../media/InfoIconSvg";

// Types for avatarConfigs
interface AvatarConfigs extends ReusableAvatarProps {
  userName?: string;
  imageBytes?: string;
  profileImage?: string;
  showIcon?: boolean;
  avatarIcon?: React.ReactNode;
}

// Updated DeleteModalProps
type DeleteModalProps = Omit<ConfirmationModalProps, "children" | "onConfirm" | "onClose"> & {
  avatarConfigs: AvatarConfigs;
  title: string;
  message?: string | React.ReactElement;
  warningMessage?: string | React.ReactElement;
  onDelete: () => void;
  onCancel: () => void;
};

const DeleteModal: React.FC<DeleteModalProps> = ({
  avatarConfigs = {},
  title,
  message,
  warningMessage,
  onDelete,
  onCancel,
  ...props
}) => {
  const {
    userName,
    imageBytes,
    profileImage,
    showIcon = false,
    avatarIcon,
    ...avatarProps
  } = avatarConfigs;

  const [isConfirmed, setIsConfirmed] = useState(false);

  const userImage = useMemo(() => {
    return imageBytes ? `data:image/jpeg;base64,${imageBytes}` : profileImage;
  }, [imageBytes, profileImage]);

  const handleCheckboxChange = () => {
    setIsConfirmed(!isConfirmed);
  };

  const handleDelete = useCallback(() => {
    if (isConfirmed) {
      onDelete();
      setIsConfirmed(false); // Reset checkbox upon deletion
    }
  }, [onDelete, isConfirmed]);

  const handleCancel = useCallback(() => {
    onCancel();
    setIsConfirmed(false); // Reset checkbox on cancel
  }, [onCancel]);

  const defaultWarningMessage = `This action is irreversible and may permanently delete critical data. 
  Please proceed with caution.`;

  // Utility function to check if avatar should be rendered. This needs to be moved to utils folder once those are converted to typescript.
  const shouldRenderAvatar = (userImage?: string, userName?: string) => {
    return Boolean(userImage || userName);
  };

  const renderUserAvatar = () => {
    if (!shouldRenderAvatar(userImage, userName)) return null;

    return (
      <Avatar
        userName={userName}
        img={userImage}
        rounded
        alt="User Image"
        size="lg"
        statusPosition="top-right"
        {...avatarProps}
      />
    );
  };

  const renderIconAvatar = () =>
    showIcon && (
      <Avatar
        icon={
          avatarIcon || (
            <HiOutlineExclamationCircle
              size="4rem"
              className="mx-auto text-red-500 dark:text-red-300"
            />
          )
        }
        alt="Icon"
        iconBorder={true}
        iconBorderColor="yellow"
        {...avatarProps}
      />
    );

  return (
    <ConfirmationModal
      modalSize="md"
      modalPosition="center"
      onClose={handleCancel}
      modalHeader={
        <div className="flex items-center space-x-3 ml-2">
          <HiOutlineTrash className="text-red-500 text-2xl" />
          <h2 className="text-xl font-semibold text-gray-900">{title || "Confirm Deletion"}</h2>
        </div>
      }
      {...props}>
      <div className="text-center mt-6" onClick={(e) => e.stopPropagation()}>
        {/* Image or Icon that need to be shown */}
        {renderUserAvatar()}
        {renderIconAvatar()}

        {/* Message to be shown */}
        <p className="text-lg text-gray-700 leading-relaxed mt-5">
          {message || (
            <>
              You are about to delete <strong>{userName || "this item"}</strong>. This action{" "}
              <span className="text-red-500">cannot</span> be undone.
            </>
          )}
        </p>

        {/* Warning message */}
        <div className="flex items-center justify-center gap-4 mt-6 p-4 bg-yellow-50 border-l-4 border-yellow-500 rounded-lg">
          <InfoIconSvg className="w-6 h-6 flex-shrink-0 fill-yellow-500" />
          <div className="text-left">
            <p className="text-sm text-yellow-900 leading-relaxed">
              <strong className="block mb-1">Warning:</strong>
              {warningMessage || defaultWarningMessage}
            </p>
          </div>
        </div>

        <p className="text-xs text-gray-500 italic mt-6">Please double-check before proceeding.</p>

        {/* Confirmation Checkbox */}
        <div className="flex items-center justify-center mt-8">
          <input
            type="checkbox"
            id="confirmCheckbox"
            className="h-5 w-5 text-red-500 focus:ring-2 focus:ring-red-400 rounded cursor-pointer"
            checked={isConfirmed}
            onChange={handleCheckboxChange}
          />
          <label
            htmlFor="confirmCheckbox"
            className="ml-3 text-sm text-gray-700 cursor-pointer select-none">
            I understand the consequences of this action
          </label>
        </div>

        <ModalButtons
          btnCont="justify-center mt-8 mb-4"
          actionBtnText={props.confirmText || "Yes, Delete"}
          cancelBtnText="No, Cancel"
          onClickAction={handleDelete}
          onClickCancel={handleCancel}
          isActionDeleteBtn={true}
          isCustomDeleteButton={false}
          isProcessing={props.isProcessing}
          isDisabled={!isConfirmed} // Disable until checkbox is checked
        />
      </div>
    </ConfirmationModal>
  );
};

export default React.memo(DeleteModal);
