import { useEffect, useState, memo } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import usePagination, { PaginationComponent } from "../../hooks/usePagination";

import PageSection from "../Layout/PageSection/PageSection";
import { getPayrollHistory } from "../../services/PayrollService";
import TableHeader from "../common/Table/TableHeader";
import { PAYROLL_HISTORY_TABLE_HEADER } from "../../constants/Constant";
import PayrollHistoryList from "./PayrollHistoryList";
import { TbReportMoney } from "react-icons/tb";

/**
 * The PayrollHistory component displays the payroll history data in a table format.
 *
 * @return {JSX.Element} The JSX representation of the PayrollHistory component.
 */
const PayrollHistory = () => {
  const navigate = useNavigate();
  const [payrollHistoryData, setPayrollHistoryData] = useState({
    payrollHistory: {},
  });
  const { setPageSizeHandler, onPageChange } = usePagination(
    false,
    payrollHistoryData?.payrollHistory?.totalRecords || 0
  );
  const { pageNo, pageSize } = useSelector(state => state.pagination);

  useEffect(() => {
    Promise.allSettled([getPayrollHistory(pageSize, pageNo)]).then(res => {
      if (res[0].status === "fulfilled") setPayrollHistoryData({ payrollHistory: res[0].value });
    });
  }, [pageNo, pageSize]);

  const onClickHandler = e => {
    const elem = e.target.closest("[data-id]");
    if (!elem) return;
    const dataset = elem.dataset.id;
    navigate("/payroll/" + dataset);
  };

  return (
    <PageSection
      heading="Payroll History"
      showIcon={true}
      icon={<TbReportMoney size={"2.2rem"} />}>
      <table onClick={onClickHandler} className="w-full bg-white">
        <TableHeader cols={PAYROLL_HISTORY_TABLE_HEADER} />

        <tbody>
          <PayrollHistoryList payrollHistory={payrollHistoryData.payrollHistory.data} />
        </tbody>
      </table>
      <PaginationComponent
        showIcons={true}
        totalRecords={payrollHistoryData.payrollHistory?.totalRecords}
        onChange={setPageSizeHandler}
        onPageChange={onPageChange}
      />
      <h5 className="mb-1 text-right font-semibold text-gray-800 p-4">
        Total Records: {payrollHistoryData?.payrollHistory?.records}
      </h5>
    </PageSection>
  );
};

export default memo(PayrollHistory);
