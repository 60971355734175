import React, { ReactNode, memo } from 'react';

const dotDefaultClasses = "w-4 h-4 rounded-full";

// Define types for the props
interface DotOption {
  id: string | number;
  text?: string;
  dotClassName?: string;
  textClassName?: string;
}

interface PageSectionProps {
  icon?: React.ReactNode; // Optional icon, could be svg, image, or any React node
  showIcon?: boolean; // Optional flag to display the icon
  heading: ReactNode; // The heading for the section
  children: ReactNode; // Content inside the section
  dotsOptions?: DotOption[]; // Optional array of dot options
  dotsPosition?: 'top' | 'bottom'; // Position for the dots (top or bottom)
  button?: React.ReactNode; // Optional button
  containerClass?: string; // Class for the container of the section
  headingClassName?: string; // Class for the heading container
  headingContainerClass?: string; // Class for the heading container wrapper
  headingTextClass?: string; // Class for the heading text
  sectionClass?: string; // Optional class for the section itself
  divWrapperClass?: string; // Optional class for the div wrapper
}

const PageSection: React.FC<PageSectionProps> = ({
  icon,
  showIcon = true,
  heading,
  children,
  dotsOptions = [],
  dotsPosition,
  button,
  containerClass,
  headingClassName,
  headingContainerClass,
  headingTextClass,
  sectionClass = '',
  divWrapperClass = '',
}) => {
  return (
    <section className={`${sectionClass} tc-page h-[calc(100vh-61px)] py-3 sm:py-5 overflow-scroll`}>
      <div className={`${divWrapperClass} px-4 mx-auto lg:px-12 mb-10`}>
        <div className={`${headingContainerClass} mb-5 flex flex-wrap justify-between items-center gap-1`}>
          {showIcon && (
            <div className={`${headingClassName} flex items-center gap-3 pt-1 focus:none text-gray-800 dark:text-white`}>
              <div className="tc-page-icon">{icon}</div>
              <h1 className={`${headingTextClass} text-3xl text-gray-900 dark:text-white py-4 cursor-default`}>
                {heading}
              </h1>
            </div>
          )}
          {!showIcon && (
            <div className={`${headingClassName} pt-1 focus:none text-gray-800 dark:text-white`}>
              <h1 className={`${headingTextClass} text-4xl text-gray-900 dark:text-white py-4 cursor-default`}>
                {heading}
              </h1>
            </div>
          )}
          {dotsPosition === "top" && dotsOptions?.length > 0 && (
            <div className="flex gap-4 items-center self-end pb-3 pr-2">
              {dotsOptions.map(dot => (
                <div key={dot.id} className="flex gap-2 items-center">
                  <div className={`${dot?.dotClassName} ${dotDefaultClasses}`}></div>
                  <span className={`${dot?.textClassName}`}>{dot?.text}</span>
                </div>
              ))}
            </div>
          )}
          {button && button}
        </div>
        <div className={`${containerClass} relative bg-white rounded-lg`}>
          {children}
        </div>
        {dotsPosition === "bottom" && dotsOptions?.length > 0 && (
          <div className="flex gap-4 items-center justify-end mt-2 pb-3 pr-2">
            {dotsOptions.map(dot => (
              <div key={dot.id} className="flex gap-1 items-center">
                <div className={`${dot?.dotClassName} ${dotDefaultClasses}`}></div>
                <span className={`${dot?.textClassName}`}>{dot?.text}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default memo(PageSection);
