import { memo, useState } from "react";
import { PricingProps } from "./subscriptionInterfaces";

const Pricing = memo(({ setState, selectedPlan, setSelectedPlan, plans, preSelectedPlan }: PricingProps) => {
  const [billingCycle, setBillingCycle] = useState("Monthly");

  const selectSubscription = (plan) => {
    setSelectedPlan(plan);
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className='text-[28px] font-bold'>Find the Perfect Plan</h1>
      <span className='mb-[1rem]'>We are here to coach you</span>
      <div className="mb-8 flex items-center bg-white shadow-lg rounded-lg">
        <button
          className={`px-4 py-2 font-medium ${billingCycle === "Monthly" ? "bg-blue-500 text-white" : "text-gray-600"} rounded-l-lg`}
          onClick={() => setBillingCycle("Monthly")}
        >
          Monthly
        </button>
        <button
          className={`px-4 py-2 font-medium ${billingCycle === "Yearly" ? "bg-blue-500 text-white" : "text-gray-600"} rounded-r-lg`}
          onClick={() => setBillingCycle("Yearly")}
        >
          Yearly
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {plans.map((item, index) => (
          <div
            key={item.id}
            onClick={() => selectSubscription(item)}
            className={`bg-[#eeeeee] shadow-lg rounded-lg p-6 max-w-80 relative transition-transform duration-300 ease-in-out transform hover:scale-105 ${selectedPlan?.id === item.id ? 'border border-2 border-solid border-blue-500' : ''}`}
          >
            {item.id === preSelectedPlan && (
              <span className="absolute top-0 left-0 bg-green-500 text-white text-xs uppercase px-3 py-1 rounded-br-lg">
                Active
              </span>
            )}
            {index === 2 && (
              <div className="wrap">
                <span className="ribbon6">Most Popular</span>
              </div>
            )}
            <h2 className="text-4xl font-bold text-center">{item.monthly_price_per_user}$</h2>
            <p className="text-gray-500 text-center mb-4">/{billingCycle === 'Yearly' ? 'year' : 'month'}</p>
            <h3 className="text-xl font-medium text-center mb-4">{item.description}</h3>
            <ul className="space-y-2">
              {item.features.map((feature, i) => (
                <li key={i} className="flex items-center text-gray-600">
                  <span className="text-blue-500 mr-2">✔</span> {feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Pricing
