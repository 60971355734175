import React, {memo, useState} from 'react'
import { HiDotsHorizontal, HiOutlineTrash, HiPencil } from 'react-icons/hi';
import { useFloating, offset, flip, shift, useTransitionStyles, useInteractions } from "@floating-ui/react";
import {FloatingPortal, useDismiss} from '@floating-ui/react';

type ListItemEditProps = {
  editAction?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, item?: any) => void,
  deleteAction?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, item?: any) => void,
  item?: any
}

const ListItemEdit = (props: ListItemEditProps) => {
  const {editAction, deleteAction, item} = props;
  const [state, setState] = useState({
    expanded: false
  })

  const { x, y, strategy, refs, context } = useFloating({
        open: state.expanded,
        onOpenChange: (open) => setState({ expanded: open }), // Automatically close on outside clicks
        middleware: [offset(-40), flip(), shift()],
        strategy: "absolute",
  });
    
  const { isMounted, styles } = useTransitionStyles(context, {
        initial: {
          transform: "scale(0)",
        },
        common: ({ side }) => ({
          transformOrigin: {
          top: "center bottom",
          bottom: "center top",
          left: "left center",
          right: "right center",
          }[side],
        }),
    });
  
  const dismiss = useDismiss(context);

   const {getReferenceProps, getFloatingProps} = useInteractions([
    dismiss,
  ]);

  const handleMenu = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setState(s => ({...s, expanded: !s.expanded}))
  }

  const handleEditClick = () => {
    if(editAction) editAction(null,item)
  }

  const handleDelete = () => {
    if(deleteAction) deleteAction(null,item)
  }

  return (
    <div className='relative'
     ref={refs.setReference}
     {...getReferenceProps()}
    >
      <button
          onClick={(e) => handleMenu(e)}
          className="absolute text-sm font-medium right-1 top-[12px] transform -translate-y-1/2">
          <HiDotsHorizontal size="1.2rem" />
      </button>

      {state.expanded && isMounted && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 2,
              ...styles,
            }}
            {...getFloatingProps()}
          >
 
        <div className='absolute top-[15px] right-[-14px] z-10' data-edit-dropdown>
            <ul className="bg-white border border-gray-200 rounded-lg shadow-md py-1">
          <li>
            <button className="flex items-center w-full !py-2 !px-3 h-full text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              onClick={handleEditClick}
              >
              <HiPencil size="1rem" className="mr-2" />
                Edit
              </button>
            </li>
            <li>
              <button className="flex items-center w-full !py-2 !px-3 h-full text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={handleDelete}>
                <HiOutlineTrash size="1rem" className={`mr-2 hover:text-red-500`} />  
                Delete
            </button>
            </li>
            </ul>          
          </div>
          </div>
        </FloatingPortal>
        )}
    </div>
  )
}

export default memo(ListItemEdit)
