import { BloodGroupEnum } from "../../../enum/BloodGroupEnum";
import { GenderEnum } from "../../../enum/GenderEnum";
import { MaritalStatusEnum } from "../../../enum/MaritalStatusEnum";
import { RegimeType, RegimeTypeLabels } from "../../../enum/RegimeType";

/**
 * Returns an array of objects, each containing a gender type and its corresponding value.
 * The returned objects are in the format required by the SelectWithDropdown component.
 * @returns {Array<{value: number, label: string, className: string, itemClassName: string}>}
 */
export const genderOptions = () => {
  const options = Object.keys(GenderEnum).map(genderType => ({
    value: GenderEnum[genderType],
    label: genderType,
    className: "bg-gray-50 rounded-lg w-[320px]",
    itemClassName: "bg-gray-50 align-center w-[320px]",
  }));
  return options;
}

/**
 * Returns an array of objects, each containing a marital status type and its corresponding value.
 * The returned objects are in the format required by the SelectWithDropdown component.
 * @returns {Array<{value: number, label: string, className: string, itemClassName: string}>}
 */
export const maritalOptions = () => {
  const options = Object.keys(MaritalStatusEnum).map(maritaltType => ({
    value: MaritalStatusEnum[maritaltType],
    label: maritaltType,
    className: "bg-gray-50 rounded-lg w-[320px]",
    itemClassName: "bg-gray-50 align-center w-[320px]",
  }));
  return options;
}

/**
 * Returns an array of objects, each containing a blood group type and its corresponding value.
 * The returned objects are in the format required by the SelectWithDropdown component.
 * @returns {Array<{value: number, label: string, itemClassName: string}>}
 */
export const bloodGroupOptions = () => {
  const options = Object.keys(BloodGroupEnum).map(bgtype => ({
    value: BloodGroupEnum[bgtype],
    label: bgtype,
    itemClassName: "bg-gray-50 align-center w-[320px]",
  }));
  return options;
}

/**
 * Returns the gender of a given user as a string.
 * If the user object is null or the gender property does not exist, or if the gender property is 0, then the function returns "Not Available".
 * Otherwise it returns the string representation of the gender as defined in the GenderEnum object.
 * @param {Object} user - The user object
 * @returns {string}
 */
export const getGender = (user) => {
  const gender = Object.keys(GenderEnum).find(g => GenderEnum[g] === user?.gender) || "Not Available";
  return gender;
}

/**
 * Returns the marital status of a given user as a string.
 * If the user object is null or the maritalStatus property does not exist, or if the maritalStatus property is 0, then the function returns "Not Available".
 * Otherwise it returns the string representation of the marital status as defined in the MaritalStatusEnum object.
 * @param {Object} user - The user object
 * @returns {string}
 */
export const getMaritalStatus = (user) => {
  const maritalStatus = Object.keys(MaritalStatusEnum).find(status => MaritalStatusEnum[status] === user?.maritalStatus) || "Not Available";
  return maritalStatus;
}

/**
 * Returns the blood group of a given user as a string.
 * If the user object is null or the bloodGroup property does not exist, or if the bloodGroup property is 0, then the function returns "Not Available".
 * Otherwise it returns the string representation of the blood group as defined in the BloodGroupEnum object.
 * @param {Object} user - The user object
 * @returns {string}
 */
export const getBloodGroup = (user) => {
  const blood_group = Object.keys(BloodGroupEnum).find(bg => BloodGroupEnum[bg] === user?.bloodGroup) || "Not Available";
  return blood_group;
}

/**
 * Returns an array of objects, each containing a regime type and its corresponding value.
 * The returned objects are in the format required by the SelectWithDropdown component.
 * @returns {Array<{value: number, label: string, itemClassName: string}>}
 */
export const regimeTypeOptions = () => {
  const options = Object.keys(RegimeType).map(type => ({
    value: RegimeType[type],
    label: RegimeTypeLabels[RegimeType[type]],
    itemClassName: "bg-gray-50 align-center w-[320px]",
  }));
  return options;
};