import React, { useState, useEffect, useRef } from "react";
import { GetDepartments } from "../../../services/DepartmentService";
import AddSvg from "../../../media/AddSvg";
import CrossIconSvg from "../../../media/CrossIconSvg";

export default function DepartmentDropdown({
  selectedDepartments,
  onDepartmentsChange,
  multiSelect = true,
  excludeDepartmentId = null,

  containerClassName = "flex items-center gap-2 relative",
  dropdownButtonClassName = "block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm cursor-pointer p-[6px] flex items-center",
  dropdownTextClassName = "text-gray-600",
  clearButtonClassName = "text-gray-500 hover:text-blue-600 ml-auto",
  menuClassName = "dropdown-menu absolute z-10 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm",
  menuItemClassName = "cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-indigo-100",
  selectedMenuItemClassName = "bg-indigo-200",
  checkmarkClassName = "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4",
  loadingClassName = "text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"

}) {
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setCurrentPage(1); // Reset page when opening
      setDepartments([]); // Clear previous data
      setHasMore(true); // Reset hasMore
    }
  };

  useEffect(() => {
    const handleOutsideClick = e => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchDepartments(1); // Initial fetch on open
    }
  }, [isOpen]);

  const fetchDepartments = async page => {
    if (isLoading || !hasMore) return;

    setIsLoading(true);
    try {
      const response = await GetDepartments(true, page, 10);

      // Conditionally filter departments if `excludeDepartmentId` is provided
      const filteredDepartments = excludeDepartmentId
        ? response.data.filter(
            dept =>
              dept.id !== excludeDepartmentId && dept.parentDepartmentId !== excludeDepartmentId
          )
        : response.data;

      // Map to add `isSelected` flag for UI handling
      const updatedDepartments = filteredDepartments.map(dept => ({
        ...dept,
        isSelected: selectedDepartments.some(sd => sd.id === dept.id),
      }));

      setDepartments(prev => [...prev, ...updatedDepartments]);

      // Stop further fetching if the response data is smaller than the page size
      if (response.data.length < 10) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
      if (error.response && error.response.status === 404) {
        setHasMore(false);
      }
    } finally {
      setIsLoading(false);
      setCurrentPage(page);
    }
  };

  // const ensureScrollableContent = async nextPage => {
  //   const dropdownElement = dropdownRef.current.querySelector(".dropdown-menu");
  //   if (
  //     dropdownElement &&
  //     dropdownElement.scrollHeight <= dropdownElement.clientHeight &&
  //     hasMore
  //   ) {
  //     await fetchDepartments(nextPage); // Fetch next page
  //   }
  // };

  const handleScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      fetchDepartments(currentPage + 1);
    }
  };

  const handleDepartmentToggle = department => {
    if (multiSelect) {
      const isAlreadySelected = selectedDepartments.some(sd => sd.id === department.id);
      const updatedSelection = isAlreadySelected
        ? selectedDepartments.filter(sd => sd.id !== department.id)
        : [...selectedDepartments, department];
      onDepartmentsChange(updatedSelection);
    } else {
      onDepartmentsChange([department]);
      setIsOpen(false);
    }
  };

  const handleClearSelection = () => {
    onDepartmentsChange([]);
  };

  const getSelectedDepartmentNames = () => {
    return selectedDepartments.map(dept => dept.name).join(", ");
  };

  return (
    <div className={containerClassName} ref={dropdownRef}>
      <div className="w-full">
        <div
          className={dropdownButtonClassName}
          onClick={toggleDropdown}>
          <span className={dropdownTextClassName}>
            {selectedDepartments.length === 0 ? "Select Department" : getSelectedDepartmentNames()}
          </span>
          <button
            type="button"
            className={clearButtonClassName}
            onClick={e => {
              e.stopPropagation();
              toggleDropdown();
              if (selectedDepartments.length > 0) {
                handleClearSelection();
              }
            }}>
            {selectedDepartments.length > 0 ? <CrossIconSvg /> : <AddSvg size={24} />}
          </button>
        </div>

        {isOpen && (
          <ul
            className={menuClassName}
            onScroll={handleScroll}>
            {departments.map(department => (
              <li
                key={department.id}
                className={`${menuItemClassName} ${
                  selectedDepartments.some(sd => sd.id === department.id) ? selectedMenuItemClassName : ""
                }`}
                onClick={() => handleDepartmentToggle(department)}>
                <span className="block truncate">{department.name}</span>
                {multiSelect && selectedDepartments.some(sd => sd.id === department.id) && (
                  <span className={checkmarkClassName}>
                    ✔
                  </span>
                )}
              </li>
            ))}
            {isLoading && (
              <li className={loadingClassName}>
                Loading...
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
