import { axiosInstance as axios } from "../context/AuthContext";

const baseUrl = process.env.REACT_APP_BASE_URL;
const commonBaseUrl = `${baseUrl}/Common`;

/**
 * Adds holidays to the system.
 *
 * @param {object} holidayDetails - The details of the holidays to be added.
 * @return {Promise<any>} A Promise that resolves to the response data.
 */
export const addHolidays = async holidayDetails => {
  const response = await axios.post(`${commonBaseUrl}/holidays`, holidayDetails);
  return response.data;
};

/**
 * Retrieves holidays for a given year.
 *
 * @param {number} holidayYear - The year for which to retrieve holidays. Defaults to 0.
 * @return {Promise} A Promise that resolves to the holidays data.
 */
export const getHolidays = async (holidayYear = 0) => {
  const response = await axios
    .get(`${commonBaseUrl}/holidays?holidayYear=${holidayYear}`)
    .then(response => {
      return response.data;
    });
  return response;
};

/**
 * Updates the holidays with the given holiday details.
 *
 * @param {Object} holidayDetails - The details of the holidays to be updated.
 * @return {Promise} A promise that resolves to the response from the server.
 */
export const updateHolidays = async holidayDetails => {
  try {
    const response = await axios.put(`${commonBaseUrl}/holidays`, holidayDetails);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Change the status of holidays identified by the given IDs.
 *
 * @param {Array} ids - An array of holiday IDs.
 * @param {string} status - The new status to set for the holidays.
 * @return {Promise} A promise that resolves to the response from the API call.
 */
export const changeHolidaysStatus = async (ids, status) => {
  try {
    const res = await axios.put(
      `${commonBaseUrl}/holidays/statuses?holidayIdsCSV=${ids.toString()}&status=${status}`
    );
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Retrieves a holiday by its ID.
 *
 * @param {number} holidayId - The ID of the holiday to retrieve.
 * @return {Promise} - A promise that resolves to the response data from the API.
 */
export const getHolidayById = async holidayId => {
  const response = await axios.get(`${commonBaseUrl}/holidays/${holidayId}`);
  return response;
};

//Deleting Holiday

export const deleteHolidayById = async holidayId => {
  const response = await axios.delete(`${commonBaseUrl}/holidays/${holidayId}`);
  return response;
};

/**
 * Updates the settings with the provided data.
 *
 * @param {Object} data - The data to update the settings with.
 * @return {Promise<Object>} A promise that resolves to the updated settings.
 */
export const updateSettings = async data => {
  const response = await axios.put(`${commonBaseUrl}/settings`, data);
  return response;
};

/**
 * Retrieves the settings from the server.
 *
 * @return {Promise<object>} The response containing the settings.
 */
export const getSettings = async function () {
  const res = await axios.get(`${commonBaseUrl}/settings`);
  return res;
};

/**
 * Retrieves the completion status of the organization profile.
 *
 * @return {Promise} A promise that resolves with the completion status response.
 */
export const getOrgCompletionStatus = async function () {
  const res = await axios.get(`${commonBaseUrl}/orgprofilecompletionstatus`);
  return res;
};

/**
 * Retrieves analytics data from the server.
 *
 * @return {Promise} A promise that resolves to the analytics data.
 */
export const getAnalytics = async function (body) {
  const res = await axios.post(`${commonBaseUrl}/analytics`, body);
  return res;
};

/**
 * Retrieves the regional settings data from the server.
 *
 * @return {Promise} A promise that resolves with the regional settings data.
 */
export const getRegionalSettings = async function () {
  const res = await axios.get(`${commonBaseUrl}/regionalSettings`);
  return res;
};

/**
 * Adds quick link to the database.
 *
 * @param {object} quickLinkDetails - The details of the quick links to be added.
 * @return {Promise<any>} A Promise that resolves to the response data.
 */
export const addQuickLinks = async quickLinkDetails => {
  const res = await axios.post(`${commonBaseUrl}/quickLinks`, quickLinkDetails);
  return res;
};

/**
 * Deletes quick link from the database.
 *
 * @param {number} quickLinkId - The id of quick link to be deleted.
 * @return {Promise<any>} A Promise that resolves to the response data.
 */
export const deleteQuickLinkById = async quickLinkId => {
  const res = await axios.delete(`${commonBaseUrl}/quickLinks/${quickLinkId}`);
  return res;
};

/**
 * Updates quick link in the database.
 *
 * @param {object} updatedQuickLinkDetails - The details of the quick link to be updated.
 * @return {Promise<any>} A Promise that resolves to the response data.
 */
export const updateQuickLinks = async updatedQuickLinkDetails => {
  const response = await axios.put(`${commonBaseUrl}/quickLinks`, updatedQuickLinkDetails);
  return response;
};

/**
 * Adds Announcement to the database.
 *
 * @param {object} announcementDetails - The details of the announcements to be added.
 * @return {Promise<any>} A Promise that resolves to the response data.
 */
export const createAnnouncements = async announcementDetails => {
  const res = await axios.post(`${commonBaseUrl}/announcements`, announcementDetails);
  return res;
};

/**
 * Deletes announcements from the database.
 *
 * @param {number} announcementId  - The id of announcement to be deleted.
 * @return {Promise<any>} A Promise that resolves to the response data.
 */
export const deleteAnnouncementById  = async announcementId => {
  const res = await axios.delete(`${commonBaseUrl}/announcements/${announcementId}`);
  return res;
};

/**
 * Updates announcements in the database.
 *
 * @param {object} updatedAnnouncementDetails - The details of the announcements to be updated.
 * @return {Promise<any>} A Promise that resolves to the response data.
 */
export const updateAnnouncements = async updatedAnnouncementDetails => {
  const response = await axios.put(`${commonBaseUrl}/announcements`, updatedAnnouncementDetails);
  return response;
};
