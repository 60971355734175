/**
 * @namespace formatDate format date
 * @param {string} date date string in any format
 * @returns formatted date `Aug 07, 2023`
 */
export const formatDate = date => {
  if (!date) return;
  return new Date(date)
    .toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    .split("-")
    .join(" ");
};


export const radioPrimaryClass = () =>
  "cursor-pointer text-thynkwebPrimary-900 bg-gray-100 border-gray-300 focus:ring-thynkwebPrimary-500 dark:focus:ring-thynkwebPrimary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";

export const btnPrimary = () =>
  "tc-btn-dark";

// Latest primaryBtn class to be used in the project from now onwards
export const primaryBtn = "bg-[#5e9393] text-white shadow-md hover:shadow-lg hover:bg-opacity-90 focus:ring-2 focus:ring-[#5e9393] focus:outline-none transition-all duration-300"

/**
 * @namespace trimValues trim values from object only from a string value
 * @param {Object} values Object with key value pair
 * @returns trimmed values from object values
 */
export const trimValues = values => {
  const trimmedValues = {};
  for (const key in values) {
    if (typeof values[key] === "string") trimmedValues[key] = values[key].trim();
    else trimmedValues[key] = values[key];
  }
  return trimmedValues;
};

// Converts a date to a string by using the current or specified locale.
export const convert_Date = (split, join, date, lang, day, month, year, splitChar, joinChar) => {
  if (split) {
    return new Date(date)
      .toLocaleDateString(lang, {
        day: day,
        month: month,
        year: year,
      })
      .split(splitChar);
  } else if (split && join) {
    return new Date(date)
      .toLocaleDateString(lang, {
        day: day,
        month: month,
        year: year,
      })
      .split(splitChar)
      .join(joinChar);
  } else {
    return new Date(date).toLocaleDateString(lang, {
      day: day,
      month: month,
      year: year,
    });
  }
};

/**
 * Checks if a value includes a specific substring.
 *
 * @param {any} value - The value to check.
 * @param {string} included - The substring to search for.
 * @return {boolean} Returns true if the value includes the substring, otherwise false.
 */
export const isIncludes = (value, included) => {
  return value.toString().includes(included);
};

/**
 * Replaces occurrences of a specified value in a string with another value.
 *
 * @param {any} value - The string or number to be replaced.
 * @param {any} from - The value to be replaced.
 * @param {any} to - The value to replace all occurrences of `from`.
 * @return {string} - The resulting string after replacement.
 */
export const replaceWith = (value, from, to) => {
  return value.toString().replace(from, to);
};

/**
 * Generates an array of options for a React Select component based on the given data.
 *
 * @param {[{id: string|number, value : string| number, label: string | number}]} data - The data used to generate the options.
 * @param {string} customId - The name of the property that contains the unique identifier for each option. (optional)
 * @return {Array} An array of options for the React Select component.
 */
export const createReactSelectOptions = function (data, customId) {
  if (data.length === 0) return data;
  else {
    const options = data.map(item => {
      return {
        id: item.id || item[customId],
        value: item.name,
        label: item.name,
      };
    });
    return options;
  }
};

/**
 * Converts a base64 string to a File object with the specified file name.
 *
 * @param {string} stringFile - The base64 string to convert.
 * @param {string} fileName - The name of the file.
 * @return {File} The converted File object.
 */
export const base64ToFile = function (stringFile, fileName, fileType) {
  let type;
  if (fileType === "pdf") type = `application/${fileType}`;
  else type = `image/${fileType}`;
  const file = new File([stringFile], fileName, { type: type });
  return file;
};


export function debounce(func, wait) {
  let timeout;

  return function (...args) {
    const context = this; // Preserve the context

    clearTimeout(timeout); // Clear the previous timeout
    timeout = setTimeout(() => func.apply(context, args), wait); // Set a new timeout
  };
}

export const appendParamsToUrl = (url, params) => {
  // Filter out invalid or empty parameters
  const validParams = Object.fromEntries(
    Object.entries(params || {}).filter(([_, value]) => value !== undefined && value !== null && value !== "")
  );

  const query = new URLSearchParams(validParams).toString();

  return query ? `${url}?${query}` : url;
};

 export const getDateRangeByValues = (option) => {
  const today = new Date();
  let startDate, endDate
  switch (option) {
    case 'today':
      startDate = endDate = today;
      break;
    case 'yesterday':
      startDate = endDate = new Date(today.setDate(today.getDate() - 1));
      break;
    case 'lastWeek':
      startDate = new Date(today.setDate(today.getDate() - today.getDay() - 7));
      endDate = new Date(today.setDate(startDate.getDate() + 6));
      break;
    case 'thisWeek':
      startDate = new Date(today.setDate(today.getDate() - today.getDay()));
      endDate = new Date(today.setDate(startDate.getDate() + 6));
      break;
    case 'thisMonth':
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      break;
      case 'lastMonth':
    case 'lastMonth':
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
      break;
    case 'thisYear':
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = new Date(today.getFullYear(), 11, 31);
      break;
    case 'lastYear':
      startDate = new Date(today.getFullYear() - 1, 0, 1);
      endDate = new Date(today.getFullYear() - 1, 11, 31);
      break;
    default:
      startDate = endDate = today;
  }

  return { startDate, endDate };
};

export const formatTimeUTC = (dateTime) => {
  const date = new Date(dateTime);
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const amPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  return `${hours}:${minutes} ${amPm} UTC`;
};

// This timezomemap helps to rename older timezone name aliases to new ones.
export const timeZoneMap = {
  "Asia/Calcutta": "Asia/Kolkata", // "Calcutta" has been updated to "Kolkata"
  "Asia/Chongqing": "Asia/Chengdu", // "Chongqing" is now "Chengdu"
  "Africa/Cairo": "Africa/Khartoum", // Previously Cairo used for Sudan too
  "America/Los_Angeles": "America/Los_Angeles", // No change, but it's listed under many aliases
};

export const formatTimeByRegion = (dateTime, userTimeZone) => {
  const resolvedTimeZone = timeZoneMap[userTimeZone] || userTimeZone;
  const date = new Date(dateTime + 'Z'); //UTC interpretation
  const options = { hour: 'numeric', minute: '2-digit', hour12: true, timeZone: resolvedTimeZone };
  return new Intl.DateTimeFormat('en-US', options).format(date) + ` ${resolvedTimeZone ?? ''}`;
};