import React, { useMemo } from "react";
import { Avatar as FlowbiteAvatar, AvatarProps } from "flowbite-react";
import { getIconBorderClass, getPlaceholderInitials, getStatusClass, getStatusPositionClass } from "./helper";

export interface ReusableAvatarProps extends AvatarProps {
  userName?: string;
  icon?: React.ReactNode | boolean; // Custom icon
  bgColor?: string | undefined; // Background color for avatar
  iconBorder?: boolean; // Option to apply border around the icon
  className?: string;
  iconBorderColor?: string; // Color for icon border
  iconStatusPosition?: string; // Status position for icon
  avatarParentClassName?: string;
  iconParentContainerClass?: string;
  [key: string]: any; // Index signature for additional dynamic properties
}

const Avatar: React.FC<ReusableAvatarProps> = ({
  userName,
  icon,
  bgColor,
  iconBorder = false,
  className = '',
  iconBorderColor,
  iconStatusPosition = "top-right", // Default to top-right position
  iconParentContainerClass = "bg-gray-200",
  avatarParentClassName = '',
  ...props // these are flowbite avatar props that can be directly passed (ctrl + hover on AvatarProps to see)
}) => {
  const placeholderInitials = useMemo(() => getPlaceholderInitials(userName), [userName]);
  const statusClass = useMemo(() => getStatusClass(props.status), [props.status]);
  const iconBorderClass = useMemo(() => getIconBorderClass(iconBorder, iconBorderColor), [iconBorder, iconBorderColor]);
  const statusPositionClass = useMemo(() => getStatusPositionClass(iconStatusPosition), [iconStatusPosition]);

  const renderAvatar = useMemo(() => (
    <FlowbiteAvatar
      placeholderInitials={placeholderInitials}
      className={`tc-custom-avatar tc-statusPosition-${props.statusPosition || ''} mx-auto rounded-full w-20 h-20 mb-4 ${className}`}
      {...props}
    />
  ), [placeholderInitials, className, props]);

  const renderIcon = useMemo(() => (
    <div className={`relative top-[-4px] flex justify-center items-center rounded-full w-20 h-20 mb-4 ${iconParentContainerClass}`}
      style={{backgroundColor: bgColor ? bgColor : ''}}
    >
      <div className={iconBorderClass}>{icon}</div>
      <span className={`absolute ${statusClass} ${statusPositionClass}`}></span>
    </div>
  ), [icon, iconBorderClass, statusClass, statusPositionClass, iconParentContainerClass]);

  return (
    <div className={`relative inline-block ${avatarParentClassName}`}>
      {icon ? renderIcon : renderAvatar}
    </div>
  );
};

export default React.memo(Avatar);
