import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Interface for the state
interface UpcomingLeavesPaginationState {
  pageSize: number;
  pageNo: number;
  totalPages: number;
}

// Initial state for pagination
const initialState: UpcomingLeavesPaginationState = {
  pageSize: 12,
  pageNo: 1,
  totalPages: 1,
};

// Upcoming Leaves Pagination slice that contains common actions
const upcomingLeavesPaginationSlice = createSlice({
  name: "upcomingLeavesPagination",
  initialState,
  reducers: {
    /**
     * Sets the page size in the state.
     *
     * @param {UpcomingLeavesPaginationState} state - The current state.
     * @param {PayloadAction<number>} action - The action object containing the payload.
     */
    setUpcomingLeavesPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },

    /**
     * Set the page number in the given state object.
     *
     * @param {UpcomingLeavesPaginationState} state - The state object.
     * @param {PayloadAction<number>} action - The action object.
     */
    setUpcomingLeavesPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },

    /**
     * Sets the total number of pages based on the given payload and the current page size.
     *
     * @param {UpcomingLeavesPaginationState} state - The current state object.
     * @param {PayloadAction<number>} action - The action object containing the payload.
     */
    setUpcomingLeavesTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = Math.ceil(action.payload / state.pageSize);
    },
  },
});

// Export the actions
export const { setUpcomingLeavesPageSize, setUpcomingLeavesPageNo, setUpcomingLeavesTotalPages } =
  upcomingLeavesPaginationSlice.actions;

// Export the reducer
export default upcomingLeavesPaginationSlice.reducer;
