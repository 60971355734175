import { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { HiSearch } from "react-icons/hi";

import PageSection from "../../Layout/PageSection/PageSection";
import EmployeesList from "../EmployeesList";

import { GetUsers } from "../../../services/UserService";
import { setPageNo, setTotalPages } from "../../../store/slices/pagination";
import usePagination, { PaginationComponent } from "../../../hooks/usePagination";
import { UserStatus } from "../../../enum/UserStatus";
import { getStatusForTab, tabData, userManagementTabs } from "../helper";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setAllUsers, setSelectedEmployeeListTab } from "../../../store/slices/userManagementSlice";
import Tabs from "../../common/Tab/Tabs";
import EmployeesFilter from "./ManageEmployeesFilter";
import { OrderBY } from "../../../enum/OrderBy";
import { UserSortExclusions } from "../../../enum/ColumnSortRestrictions";

/**
 * Create and manage employees.
 *
 * @return {JSX.Element} The component JSX element.
 */

const ManageEmployees = () => {
  const dispatch = useAppDispatch();
  const umState = useAppSelector(state => state.userManagement);

  // Setting the page no
  const { pageSize, pageNo, totalPages } = useAppSelector(state => state.pagination);

  // Setting the page size
  const [searchQuery, setSearchQuery] = useState("");

  // sorting params
  const [activeSortColumn, setActiveSortColumn] = useState(1);
  const [order, setOrder] = useState(OrderBY.Ascending);

  const { setPageSizeHandler, onPageChange } = usePagination(false, umState.allUsers.totalRecords);
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters] = useState({
    designation: '',
    role: {
      roleId: '',
      roleName: ''
    },
    department: {
      departmentId: '',
      departmentName: ''
    }
  });
  const [originalUsers, setOriginalUsers] = useState([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setPageNo(1));
      const status = getStatusForTab(umState.selectedEmployeeListTab);
      fetchUsers(pageSize, pageNo, status, searchQuery ?? null, filters);
    }, 200);

    return () => clearTimeout(timeout);
  }, [searchQuery, filters, dispatch]);

  const fetchUsers = useCallback((pageSize, pageNo, userStatus, searchText = "", currentFilters = filters) => {
    GetUsers({
      pageSize,
      pageNo,
      userStatus,
      search: searchText,
      sortBy: activeSortColumn,
      orderBy: order,
      departmentId: currentFilters.department.departmentId,
      designation: currentFilters.designation,
      roleId: currentFilters.role.roleId
    }).then(response => {
      setOriginalUsers(response.data);
      
      dispatch(
        setAllUsers({
          users: response.data,
          records: response.records,
          totalRecords: response.totalRecords,
        })
      );
    });
  }, [filters, activeSortColumn, order]);

  const handleFilterChange = useCallback((key) => (e) => {
    const { value } = e.target;
    let newFilters = {}
    if (key === "role" || key === "department") {
    newFilters = {
      ...filters,
      [key]: {
        ...filters[key],
        [`${key}Id`]: value.value,
        [`${key}Name`]: value.label,
      },
    };
    setFilters(newFilters);
  } else {
    // For other fields like designation, directly update
    newFilters = {
      ...filters,
      [key]: value,
    };
    setFilters(newFilters);
  }

    dispatch(setPageNo(1));
    // Re-fetch users with the updated filters
    const status = getStatusForTab(umState.selectedEmployeeListTab);
    fetchUsers(pageSize, 1, status, searchQuery ?? "", newFilters);

  }, [filters, originalUsers, dispatch, umState.allUsers.totalRecords]);

  useEffect(() => {
    dispatch(setPageNo(1));
  }, [umState.selectedEmployeeListTab]);

  useEffect(() => {
    if (umState.selectedEmployeeListTab === userManagementTabs.active) {
      fetchUsers(pageSize, pageNo, UserStatus.Active);
    } else if (umState.selectedEmployeeListTab === userManagementTabs.pendingApproval) {
      fetchUsers(pageSize, pageNo, UserStatus.PendingEmailVerification);
    } else if (umState.selectedEmployeeListTab === userManagementTabs.inActive) {
      fetchUsers(pageSize, pageNo, UserStatus.Deactivated);
    } else if (umState.selectedEmployeeListTab === userManagementTabs.restrictedLogin) {
      fetchUsers(pageSize, pageNo, UserStatus.RestrictedLogin);
    }
  }, [pageSize, pageNo, umState.refetchUsers, umState.selectedEmployeeListTab, fetchUsers]);

  const onChangeHandler = e => {
    const { value } = e.currentTarget;
    setSearchQuery(value);
  };

  // sort table data
  const sortTableData = useCallback((updatedOrder, selectedColumn) => {
    setActiveSortColumn(selectedColumn);
    setOrder(updatedOrder);
    GetUsers(pageSize, pageNo, selectedColumn, updatedOrder).then(response => {
      dispatch(
        setAllUsers({
          users: response.data,
          records: response.records,
          totalRecords: response.totalRecords,
        })
      );
    });
  }, [pageSize, pageNo, dispatch]);

  const onActiveTabChange = useCallback((tab) => {
      setActiveTab(tab);
      dispatch(setSelectedEmployeeListTab(tab));
    }, []);

  //Function to select all users checkbox from restricted users
  // const toggleSAelectAll = () => {
  //   const updatedUsers = data?.users?.map(user => ({
  //     ...user,
  //     selected: !selectAllRestrictedUsersCheckbox,
  //   }));

  //   setData({ ...data, users: updatedUsers });
  //   setSelectAllRestrictedUsersCheckbox(!selectAllRestrictedUsersCheckbox);
  // };

  // Function to select individual users checkbox from restricted users
  // const toggleSelectedUserCheckboxChange = userId => {
  //   const updatedUsers = data?.users?.map(user => {
  //     if (user.id === userId) {
  //       return {
  //         ...user,
  //         selected: !user.selected,
  //       };
  //     }
  //     return user;
  //   });
  //   setData({ ...data, users: updatedUsers });
  //   const allSelected = updatedUsers.every(user => user.selected);
  //   setSelectAllRestrictedUsersCheckbox(allSelected);
  // };

  const {PhoneNumber, Actions} = UserSortExclusions;

  const activeFiltersCount = useMemo(() => {
    return Object.entries(filters).filter(([key, val]) => {
      // Exclude empty values like "" for roleId, departmentId, and designation.
      if (
        (key === 'role' && val?.roleId === "") ||
        (key === 'department' && val?.departmentId === "") ||
        (key === 'designation' && val === "")
      ) {
        return false; // Don't count empty filters as these are default values
      }
      
      // Handle objects (like role and department) by checking if their inner properties have valid values
      if (typeof val === "object" && val !== null) {
        return Object.keys(val).some(subKey => val[subKey]); // Ensure there are valid (non-empty) values inside the object
      }
      
      // For non-object values, check if it's truthy (i.e., not an empty string or null)
      return Boolean(val);
    }).length;
  }, [filters]);  

  return (
    <PageSection
      containerClass="!bg-transparent rounded-[8px] -mt-6"
      sectionClass="!py-0 !sm:py-0 scrollbar-hide"
      divWrapperClass="!px-0 !w-full !mb-0"
      headingContainerClass="!mb-0"
    >
      <div className="divide-y dark:divide-gray-700">
        <div className="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4">
          <div className="flex items-center justify-between flex-1 space-x-4">
            {/* tabs */}
            <div className="relative text-gray-900 dark:text-white tab-payslip-links">
              <Tabs 
                tabs={tabData}
                activeTab={activeTab}
                onActiveTabChange={onActiveTabChange}
                activeTabClass="text-thynkwebPrimary-900 border-thynkwebPrimary-900"
                inActiveTabClass="hover:text-thynkwebPrimary-900"
              />
            </div>
            <div className="flex items-center gap-3 relative -mt-4">
              <div className="relative">
                <input
                  className="placeholder:text-slate-400 placeholder:font-semibold block bg-white w-full border border-transparent rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-transparent focus:ring-transparent focus:ring-1 dark:focus:ring-transparent dark:focus:border-transparent sm:text-sm"
                  placeholder="Search Users"
                  type="text"
                  name="search"
                  value={searchQuery}
                  onChange={onChangeHandler}
                />
                <div className="absolute pr-3 inset-y-0 right-0 flex items-center">
                  <HiSearch className="fill-thynkwebPrimary-500 z-0" />
                </div>
              </div>
              <EmployeesFilter 
                filters={filters} 
                onFilterChange={handleFilterChange} 
                activeFiltersCount={activeFiltersCount}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-3">
      {/* EmployeeListTableHeading component (TableHeading file) has been deleted as now it's not in use anymore. Instead reusable table is used.*/}
        <EmployeesList users={umState.allUsers.users} onSort={sortTableData}
          sortConfig={{
            column: activeSortColumn,
            orderBy: order,
            skipColumnsToSort: [PhoneNumber, Actions] // Skip phone number & actions
          }} />
        <h5 className="pl-4 pt-2">
          <span className="text-gray-500">Total Users: </span>
          <span className="dark:text-white">{umState.allUsers?.totalRecords}</span>
        </h5>
      </div>

      <PaginationComponent
        onChange={setPageSizeHandler}
        onPageChange={onPageChange}
        showIcons={true}
      />
    </PageSection>
  );
};

export default ManageEmployees;
