import {FC, useState, memo} from 'react'
import { useFloating, offset, flip, shift, useTransitionStyles } from "@floating-ui/react";
import Avatar from '../../../../Avatar/Avatar'
import {FloatingPortal} from '@floating-ui/react';
import { ListItemProps } from '../ListItem';
import UserProfileCard from '../../../../../../pages/MyTeams/userProfileCard/UserProfileCard';
import { ICON_MAPPING, ImageBaseUrl } from '../../../../../../constants/Constant';
import { cn } from '../../../../../../utils/common';

const ListItemText: FC<ListItemProps> = (props) => {
  const {title, subtitle, showPopOver, initials, avatarProps, icon, item, image, imageClassName, subtitleClass, titleParentClass, ...rest} = props;
  const [showCard, setShowCard] = useState(false);
  
  const { x, y, strategy, refs, context } = useFloating({
      open: showCard,
      middleware: [offset(10), flip(), shift()],
      strategy: "absolute",
  });
  
  const { isMounted, styles } = useTransitionStyles(context, {
      initial: {
        transform: "scale(0)",
      },
      common: ({ side }) => ({
        transformOrigin: {
          top: `${100}px calc(100% + ${0}px)`,
          bottom: `${100}px ${0}px`,
          left: "0rem",
          // right: "50%",
        }[side],
      }),
  });

   const onMouseEnter = () => {
    setShowCard(true);
  };

  const onMouseLeave = () => {
    setShowCard(false);
  };

  let selectedIcon = null
  if(avatarProps?.icon) {
    selectedIcon = ICON_MAPPING.find(x => x.iconName === item?.icon)?.iconComponent;
  }
  
  let iconBackground = null
  
  if(avatarProps?.bgColor) {
    iconBackground = item?.colorCode;
  }

  let imageUrl = image ? ImageBaseUrl+encodeURIComponent(image) : null;

  return (
    <div className={cn('flex gap-[15px]', props.parentClass)}
     ref={refs.setReference}
     onMouseEnter={onMouseEnter}
     onMouseLeave={onMouseLeave}
    >
      <Avatar
      {...{...avatarProps, imageClassName: undefined}}
      icon={selectedIcon ? selectedIcon : false}
      bgColor={iconBackground ? iconBackground : ''}
      className={image ? avatarProps.imageClassName : avatarProps.className}
      {...(imageUrl ? { img: imageUrl } : {})}
       />
      <div>
      <p className="text-[14px] truncate">{title}</p>
      {subtitle && <p className="text-[12px] text-gray-500">{subtitle}</p>}
      </div>
      {showPopOver && isMounted && (
      <FloatingPortal>
      <div
        ref={refs.setFloating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
          zIndex: 20,
          ...styles,
        }}
        className=""
      >
      <UserProfileCard
        {...avatarProps}
        {...rest}
      />
      </div>
      </FloatingPortal>
      )}
      </div>
  )
}

export default memo(ListItemText)

