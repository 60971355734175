import React, { useMemo, useState } from "react";
import DeleteModal from "../common/Modal/DeleteModal";
import { handleActivateAndDeactivateUser } from "../../services/UserService";
import { UserStatus } from "../../enum/UserStatus";
import { STATUS_CODES } from "../../constants/Constant";
import { showToast } from "../common/Toast";
import { EMPLOYEE_MESSAGES } from "../../constants/Messages";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { resetUser, setRefetchUsers, setToggleDeactivateUserModal } from "../../store/slices/userManagementSlice";

const DeActivateEmployee = ({}) => {
  
  const umState = useAppSelector(state => state.userManagement);
  const dispatch = useAppDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const avatarConfigs = useMemo(
    () => ({
      userName: `${umState.user?.firstName} ${umState.user?.lastName}`,
      imageBytes: umState.user?.imageBytes,
      // status: "online",    // to be used later when BE implementation is done
      bordered: true,
      color: "warning",
    }),
    [umState.user]
  );

  //Function to deactivate the user
  const onDeactivateUser = async () => {
    setIsProcessing(true);
    try {
      const response = await handleActivateAndDeactivateUser(
        umState.user.id,
        UserStatus.Deactivated
      );
      if (response.status === STATUS_CODES.SUCCESS) {
        showToast("success", EMPLOYEE_MESSAGES.DEACTIVATE_SUCCESS);
        resetState();
      } else {
        showToast("error", EMPLOYEE_MESSAGES.DEACTIVATE_ERROR);
      }
    } catch (error) {
      showToast("error", "An unexpected error occurred.");
    } finally {
      setIsProcessing(false);
    }
  };

  const resetState = () => {
    dispatch(setToggleDeactivateUserModal(false));
    dispatch(resetUser());
    dispatch(setRefetchUsers(!umState.refetchUsers));
  }
  return (
    <div>
      <DeleteModal
        avatarConfigs={avatarConfigs}
        show={umState.toggleDeactivateUserModal}
        title="Confirm User Deactivation"
        message={<>You are about to Deactivate <strong>{umState.user?.firstName} {umState.user?.lastName}</strong>?</>}
        warningMessage={
          <>
            Deactivating user <strong>{umState.user?.firstName}</strong> will revoke access to all projects and restrict login to the system. {" "}
            <span class="text-red-500">This action will not delete user data but will disable all associated permissions and roles.</span>{" "}
            Reactivation will be required to restore access.
          </>
        }
        onCancel={resetState}
        onDelete={onDeactivateUser}
        confirmText="Yes, De-activate"
        isProcessing={isProcessing}
      />
    </div>
  );
};

export default DeActivateEmployee;
