import React, { useMemo } from 'react';
import { FaFilter } from 'react-icons/fa';
import Menu from '../Menu/Menu';
import { FilterProps } from './FilterInterface';
import { cn } from '../../../utils/common';

const Filter: React.FC<FilterProps> = ({
  children,
  icon,
  label,
  arrowIcon = false,
  showNumberBadge = true,
  filterBtnClassName,
  menuClassName,
  menuItemsParentClass,
  numberClassName = "absolute top-0 right-0 transform -translate-x-1/3 -translate-y-1/4 text-xs bg-red-500 text-white rounded-full h-4 w-4 flex items-center justify-center",
  filterIconParentClass,
  filterIconColor = "gray",
  filterIconSize = 20,
  activeFiltersCount = 0
}) => {

  const filterButton = (
    <button
      type="button"
      className={`relative inline-flex justify-center rounded-md px-2 py-[.5rem] text-sm font-medium text-gray-700 focus:outline-none ${filterBtnClassName}`}
    >
      <span className={cn("mr-2", filterIconParentClass)}>
        {React.isValidElement(icon) ? React.cloneElement(icon as React.ReactElement<any>, { size: filterIconSize, color: filterIconColor }) : <FaFilter size={filterIconSize} color={filterIconColor} />}
      </span>
      {label && <span>{label}</span>}
      {showNumberBadge && activeFiltersCount > 0 && (
        <span className={numberClassName}>
          {activeFiltersCount}
        </span>
      )}
    </button>
  );

  return (
    <Menu
      label={filterButton}
      arrowIcon={arrowIcon}
      menuClassName={menuClassName}
      menuItemsParentClass={menuItemsParentClass}
    >
      {children}
    </Menu>
  );
};

export default React.memo(Filter);