import React, { memo } from "react";
import { Dropdown } from "flowbite-react";
import { ReusableMenuProps } from "./MenuInterfaces";

const Menu: React.FC<ReusableMenuProps> = 
  ({
    menuItems,
    label = "•••",
    arrowIcon = false,
    menuClassName,
    menuItemsParentClass,
    children
  }) => {

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      e.stopPropagation();
    };

    return (
      <Dropdown
        className={`tc-menu z-[10] w-[max-content] shadow-lg bg-white dark:bg-gray-800 rounded-lg ${menuClassName || ""}`}
        inline={true}
        label={label}
        arrowIcon={arrowIcon}
      >
        <div className={`p-2 space-y-1 ${menuItemsParentClass || ""}`} onKeyDown={handleKeyDown}>
          {children}
          {menuItems?.map((item, index) => {
            // Divider if needed
            if (item.isDivider) {
              return <div key={index} className={`border-t my-1 dark:border-gray-700 ${item.dividerClassName || ""}`} />;
            }

            // Header if needed
            if (item.isHeader) {
              return (
                <div
                  key={index}
                  className={`px-3 py-2 text-xs font-semibold text-gray-500 dark:text-gray-400 uppercase ${item.headerClassName || ""}`}
                >
                  {item.label}
                </div>
              );
            }

            // menu items
            // Memoize this render if necessary (use of React.memo or useCallback in parent) to prevent re-creations of onClick functions. This has to be handled in the parent component and not here.
            return item.visible ? (
              <Dropdown.Item
                key={index}
                onClick={item.onClick}
                className={`tc-menu-item flex items-center gap-3 px-3 py-2 text-sm text-gray-700 hover:!bg-[#bcd6d7] dark:text-gray-200 dark:hover:bg-gray-700 rounded-md font-medium transition-all duration-300 ease-in-out ${item.className || ""}`}
                style={{ transition: "background-color 0.3s ease, border-color 0.3s ease" }}
              >
                {item.icon && <span className={`text-lg ${item.iconClassName}`}>{item.icon}</span>}
                {item.isButton ? (
                  <button className={`w-full text-left ${item.labelClassName}`}>{item.label}</button>
                ) : (
                  <span className={`w-full text-left ${item.labelClassName}`}>{item.label}</span>
                )}
              </Dropdown.Item>
            ) : null;
          })}
        </div>
      </Dropdown>
    );
  }

export default memo(Menu);