import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Initial state for pagination
interface PastLeavesPaginationState {
  pageSize: number;
  pageNo: number;
  totalPages: number;
}

const initialState: PastLeavesPaginationState = {
  pageSize: 12,
  pageNo: 1,
  totalPages: 1,
};

// Past Leaves Pagination slice that contains common actions
const pastLeavesPaginationSlice = createSlice({
  name: "pastLeavesPagination",
  initialState,
  reducers: {
    /**
     * Sets the page size in the state.
     *
     * @param {PastLeavesPaginationState} state - The current state.
     * @param {PayloadAction<number>} action - The action object containing the payload.
     */
    setPastLeavesPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },

    /**
     * Set the page number in the given state object.
     *
     * @param {PastLeavesPaginationState} state - The state object.
     * @param {PayloadAction<number>} action - The action object.
     */
    setPastLeavesPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },

    /**
     * Sets the total number of pages based on the given payload and the current page size.
     *
     * @param {PastLeavesPaginationState} state - The current state object.
     * @param {PayloadAction<number>} action - The action object containing the payload.
     */
    setPastLeavesTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = Math.ceil(action.payload / state.pageSize);
    },
  },
});

// Export the actions
export const {
  setPastLeavesPageSize,
  setPastLeavesPageNo,
  setPastLeavesTotalPages,
} = pastLeavesPaginationSlice.actions;

// Export the reducer
export default pastLeavesPaginationSlice.reducer;
