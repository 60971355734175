import React, { useMemo, useState } from "react";
import DeleteModal from "../common/Modal/DeleteModal";
import { DeleteUser } from "../../services/UserService";
import { STATUS_CODES } from "../../constants/Constant";
import { showToast } from "../common/Toast";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { resetUser, setRefetchUsers, setToggleDeleteUserModal } from "../../store/slices/userManagementSlice";

const DeleteEmployee = ({}) => {

  const umState = useAppSelector(state => state.userManagement);
  const dispatch = useAppDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const avatarConfigs = useMemo(
    () => ({
      userName: `${umState.user?.firstName} ${umState.user?.lastName}`,
      imageBytes: umState.user?.imageBytes,
      // status: "online",    // to be used later when BE implementation is done
      bordered: true,
      color: "warning",
    }),
    [umState.user]
  );

  //Function to delete user
  const onDeleteUser = async () => {
    try {
      setIsProcessing(true);
      const response = await DeleteUser(umState.user.id);
      
      if (response.status === STATUS_CODES.SUCCESS) {
        showToast("success", "User deleted successfully");
        resetState();
      } else {
        showToast("error", "Error deleting user");
      }
    } catch (error) {
      showToast("error", "Something went wrong. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };  

  const resetState = () => {
    dispatch(setToggleDeleteUserModal(false));
    dispatch(resetUser());
    dispatch(setRefetchUsers(!umState.refetchUsers));
  }
  
  return (
    <div>
      <DeleteModal
        avatarConfigs={avatarConfigs}
        show={umState.toggleDeleteUserModal}
        title="Delete this employee?"
        onCancel={resetState}
        onDelete={onDeleteUser}
        warningMessage={<>This action is irreversible and will permanently delete all data associated with <strong>{umState.user?.firstName}</strong>. Please proceed with caution.</>}
        isProcessing={isProcessing}
      />
    </div>
  );
};

export default React.memo(DeleteEmployee);
