import { Modal } from "flowbite-react";
import React from "react";

/**
 * Generates a modal component with customizable properties.
 *
 * @param {{position?: Object, show: boolean, size?: string, onClose: function, headerClassName?: string, bodyClassName?: string, heading?: string | ReactNode, body?: string, footer?: ReactNode, children?: ReactNode, showFooter?: boolean, subModal?: boolean}} props - The props for the <modal className=""></modal>
 * @param {Object} position - The position of the modal (default: null).
 * @param {boolean} show - Determines whether the modal is shown or hidden.
 * @param {string} size - The size of the modal (default: null).
 * @param {function} onClose - The function to be called when the modal is closed.
 * @param {string} headerClassName - The CSS class for the modal header (default: null).
 * @param {string} bodyClassName - The CSS class for the modal body (default: null).
 * @param {string} heading - The title of the modal (default: null).
 * @param {string} body - The content of the modal body (default: null).
 * @param {ReactNode} footer - The content of the modal footer (default: null).
 * @param {ReactNode} children - The content of the modal (default: null).
 * @param {boolean} showFooter - Determines whether the modal footer is shown or hidden (default: true).
 * @param {boolean} subModal - Determines whether the modal is a sub modal or not (default: false).
 * @return {ReactNode} The generated modal component.
 */
const ModalComponent = ({
  position,
  show,
  size,
  onClose,
  headerClassName,
  bodyClassName,
  heading,
  body,
  footer,
  children,
  className,
  otherProps,
  showFooter = true,
  subModal = false,
}) => {
  let _header_class = "modal-header-title mb-4";
  if (headerClassName) {
    _header_class = headerClassName;
  }

  // overlay-style for subModal
  const modalBackdropClasses = subModal ? "bg-black bg-opacity-15" : "bg-black bg-opacity-50";

  return (
    <Modal
      position={position !== null ? position : "center"}
      show={show}
      size={size ?? "3xl"}
      popup={true}
      onClose={onClose}
      {...otherProps}
      className={`${modalBackdropClasses} ${show ? "zoomIn" : "zoomOut"} ${className}`}>
      {heading && <Modal.Header className={_header_class}>{heading}</Modal.Header>}
      <hr />
      {<Modal.Body className={`${bodyClassName}`}>{body ? body : children}</Modal.Body>}
      {/* render footer conditionally */}
      {showFooter && footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default ModalComponent;
