import React, { useState, useEffect, useContext } from "react";
import {
  GetDepartments,
  UpdateDepartment,
  DeleteDepartment,
  CreateDepartment,
  GetDepartmentDetails,
} from "../../services/DepartmentService";
import { Button } from "flowbite-react";
import { Pagination } from "flowbite-react";
import AddEditDepartment from "./DepartmentModals/addEditDepartmentModal";
import ModalComponent from "../common/Modal/Modal";
import { FaRegTrashAlt } from "react-icons/fa";
import DeleteModal from "../common/Modal/DeleteModal";
import DeleteDepartmentModal from "./DepartmentModals/DeleteDepartmentModal";
import EmployeeListModal from "./DepartmentModals/EmployeeListModal";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../constants/Constant";
// Import custom table components
import Table from "../common/Table/Table"; // Update the import path

import Toggle from "../common/Toggle/Toggle";
import PageSection from "../Layout/PageSection/PageSection";
import { PaginationComponent } from "../../hooks/usePagination";
import { FiPlus } from "react-icons/fi";
import { primaryBtn } from "../../utils/helper";

const Department = ({setToggleModalRef}) => {
  //Pagination
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE); // Default page size

  const [departments, setDepartments] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);

  const cancelDelete = () => {
    setIsDeleteModalVisible(false);
    setDepartmentToDelete(null);
  };

  const handleDelete = () => {
    fetchDepartments();
    setIsDeleteModalVisible(false);
    setDepartmentToDelete(null);
  };

  // Columns for the table
  const DepartmentTableHeaders = [
    "Name",
    "Code",
    "Description",
    "Department Head",
    "Parent Department",
    "Assigned Users",
    "Status",
    "Actions",
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const handleModalSubmit = async formData => {
    try {
      if (formData.id) {
        // Update department
        await UpdateDepartment(formData.id, formData);
      } else {
        await CreateDepartment(formData);
      }
      closeModal();
      fetchDepartments();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  useEffect(() => {
    fetchDepartments();
  }, [pageNo, pageSize]);

  const fetchDepartments = async () => {
    try {
      const response = await GetDepartments(false, pageNo, pageSize);

      setDepartments(response.data);
      setTotalRecords(response.totalRecords);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const openModal = (department = null) => {
    setSelectedDepartment(department);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setSelectedDepartment(null);
    setIsModalVisible(false);
  };

  const confirmDelete = department => {
    setDepartmentToDelete(department);
    setIsDeleteModalVisible(true);
  };

  const deleteDepartment = async () => {
    try {
      await DeleteDepartment(departmentToDelete.id);
      setDepartments(prevDepartments =>
        prevDepartments.filter(dept => dept.id !== departmentToDelete.id)
      );
      setIsDeleteModalVisible(false);
      setDepartmentToDelete(null);
      fetchDepartments();
    } catch (error) {
      console.error("Error deleting department:", error.response?.data || error.message);
    }
  };

  const openAssignedUsersModal = async departmentId => {
    try {
      const response = await GetDepartmentDetails(departmentId);
      setModalData({
        id: departmentId,  // Make sure to include the ID
        name: response.name,
        totalEmployees: response.totalEmployees,
        employees: response.employees || []
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching department details:", error);
    }
  };

  const closeEmployeeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  //resuing table:
  const toggleStatus = async department => {
    try {
      const updatedDepartment = {
        ...department,
        status: department.status === 1 ? 0 : 1,
      };
      await UpdateDepartment(updatedDepartment.id, updatedDepartment);
      fetchDepartments();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // Prepare table rows data
  const renderStatusToggle = department => (
    <Toggle
      checked={department.status === 1}
      onChange={e => {
        e.stopPropagation(); // Prevent bubbling to the row click event
        toggleStatus(department);
      }}
      name={`department-status-${department.id}`}
      id={`department-status-${department.id}`}
    />
  );
  const tableRows = departments.map(department => [
    department,
    [
      department.name,
      department.code,
      <div className="truncate w-32" title={department.description}>
        {department.description}
      </div>,
      department.departmentHeadId ? department.departmentHeadName : "-", // Placeholder for department head
      department.parentDepartmentId ? department.parentDepartmentName : "-", // Placeholder for parent department
      <span
        onClick={e => {
          e.stopPropagation(); // Prevent bubbling to edit row event
          openAssignedUsersModal(department.id);
        }}
        className="cursor-pointer text-blue-600">
        {department.totalEmployees || `-`}
      </span>,
      renderStatusToggle(department),
      <>
        <button
          onClick={e => {
            e.stopPropagation(); // Prevents event bubbling to parent elements
            confirmDelete(department);
          }}
          className="text-gray-400 hover:text-red-500 ml-5">
          <FaRegTrashAlt className="h-4 w-4" />
        </button>
      </>,
    ],
  ]);

  const handleDepartmentEdit = e => {
    // Check if the clicked element is inside a non-interactive element (like a button, toggle, etc)
    const clickedElement = e.target;

    // Stop event propagation if clicked element is a button, toggle or span (these are interactive elements inside rows)
    if (
      clickedElement.closest("button") ||
      clickedElement.closest("span") ||
      clickedElement.closest("input[type='checkbox']") || // avoid on input or toggle
      clickedElement.closest("label") // For labels around the toggles
    ) {
      return; // Do nothing and avoid handling row click
    }
    // Find the closest `tr` element
    const trElement = clickedElement.closest("tr");

    if (trElement) {
      const department = JSON.parse(trElement.dataset.id);
      if (department) {
        openModal(department);
      } else {
        console.warn("Department ID not found!");
      }
    }
  };

  const handleEmployeeListPageChange = newPage => {
    // You can fetch the employee data for the new page from API or update the list if paginated locally.
    setPageNo(newPage);
  };

  useEffect(() => {
    if (setToggleModalRef) {
      setToggleModalRef(openModal);
    }
  }, [setToggleModalRef]);

  return (
    <PageSection
      containerClass="!bg-transparent rounded-[8px] -mt-10"
      sectionClass="!py-0 !sm:py-0 scrollbar-hide"
      divWrapperClass="!px-0 !w-full !mb-0"
      headingContainerClass="!mb-0"
    >
      <div className="divide-y dark:divide-gray-700">
        <div className="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4">
          <div className="flex items-center justify-between flex-1 space-x-4">
            {/* Add any additional filters or search inputs here */}
          </div>
        </div>
      </div>
      <div className="pb-3">
      {isModalOpen && modalData && (
        <EmployeeListModal
          visible={isModalOpen}
          onClose={closeEmployeeModal}
          employees={modalData?.employees || []} // Pass employees array
          totalEmployees={modalData.totalEmployees}
          departmentName={modalData.name}
          pageSize={DEFAULT_PAGE_SIZE} // Define page size
          onPageChange={handleEmployeeListPageChange} // Handle page change
          fetchDepartmentDetails={GetDepartmentDetails}
          departmentId={modalData.id}
        />
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalVisible && (
        <DeleteDepartmentModal
          modals={{ deleteModal: isDeleteModalVisible }}
          departmentToDelete={departmentToDelete}
          setDepartmentToDelete={setDepartmentToDelete}
          cancelDelete={cancelDelete}
          handleDelete={handleDelete} // Update this with the proper handler
        />
      )}

      <AddEditDepartment
        visible={isModalVisible}
        onCancel={closeModal}
        onSave={handleModalSubmit}
        showModal={isModalVisible}
        onCloseModal={closeModal}
        department={selectedDepartment}
      />
      <Table col={DepartmentTableHeaders} rows={tableRows} tableClick={handleDepartmentEdit} />
      {/* Flowbite Pagination */}
      {totalRecords > 10 && (
        <div className="flex justify-end my-4">
          <Pagination
            currentPage={pageNo}
            onPageChange={page => setPageNo(page)}
            showIcons={true}
            totalPages={totalRecords === undefined ? 1 : Math.ceil(totalRecords / pageSize)}
          />
        </div>
      )}
    </div>
    </PageSection>
  );
};

export default Department;
