import moment from "moment/moment";
import { AttendanceStatus } from "../enum/AttendanceStatus";
import { showToast } from "../components/common/Toast";
import { twMerge } from "tailwind-merge";
import { clsx, ClassValue } from "clsx";
import debounce from "lodash.debounce";
import { format } from "date-fns";
import { DATEFORMATS, STATUS_CODES } from "../constants/Constant";

export const _60minutes = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
  52, 53, 54, 55, 56, 57, 58, 59,
];

export const _12hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const weekDays = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const presentCheck = [
  AttendanceStatus.Present,
  AttendanceStatus.Present_holiday,
  AttendanceStatus.Present_leave,
  AttendanceStatus.Present_weekoff,
];

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

//Interceptor for all api calls
export const Interceptor = (axios, setLoading, setUser, setUserAuth, init) => {
  if (init.current !== 0 || init.current < 0) {
    init.current = axios.interceptors.request.use(
      request => {
        setLoading(true);
        return request;
      },
      error => {
        setLoading(false);
      }
    );
  }

  if (!axios.interceptors.response.handlers.length) {
    axios.interceptors.response.use(
      response => {
        setLoading(false);
        return response;
      },
      error => {
        console.log("error:", error);    // Kept for introspection of errors in development. We have to create environments to stop console logs going in production build. To even introspect error in production we have to create a copy of production env.

        // Allow 401 and 403 errors to be handled by the caller
        if (error.response.status === STATUS_CODES.UNAUTHORIZED || error.response.status === STATUS_CODES.FORBIDDEN) {
          setLoading(false);
          return Promise.reject(error); // Passing the error to the caller
        }
        showToast("error", error.response.data.message ?? error.response.data.title ?? "Error");
        setLoading(false);
      }
    );
  }
};

// Getting all properties of time from dateTime/timestamp and returning these properties in form of an array
export const TimestampInfo = dateTime => {
  const timestamp = dateTime;
  const datetimeObj = new Date(timestamp);
  const month = datetimeObj.getMonth();
  const date = datetimeObj.getDate();
  const year = datetimeObj.getFullYear();

  const hours = datetimeObj.getHours();
  const minutes = datetimeObj.getMinutes();

  const formattedHours = hours % 12 || 12;
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, "0")} ${amPm}`;

  return [datetimeObj, month, date, year, hours, minutes, formattedHours, amPm, formattedTime];
};

// Converting(HH:MM:ss.ms or 24Hour format) to 12Hour format time and returning the result/properties in form of an array
export const Time = time => {
  const timeComponents = time?.split(":");
  const hours = parseInt(timeComponents?.[0]);
  const minutes = parseInt(timeComponents?.[1]);
  const formattedHours = hours % 12 || 12;
  const amPm = hours >= 12 ? "PM" : "AM";
  return [hours, minutes, formattedHours, amPm];
};

// Converting 12Hour format to (24Hour format or HH:MM:ss.ms) and returning the result in form of an array
export const convertTime_To_24Hour_Format = In_12Hour_Format_Time => {
  if (!In_12Hour_Format_Time) {
    return ["", ""];
  }
  const [time, period] = In_12Hour_Format_Time.split(" ");
  const [hours, minutes] = time.split(":");

  // Converting time to the 24-hour format
  let hours_24hour_format = parseInt(hours, 10);
  if ((period === "PM" || period === "pm") && hours_24hour_format < 12) {
    hours_24hour_format += 12;
  }
  if ((period === "AM" || period === "am") && hours_24hour_format === 12) {
    hours_24hour_format = 0;
  }

  // Formatting hours and minutes with leading zeros
  const formattedHours = hours_24hour_format.toString().padStart(2, "0");
  const formattedMinutes = minutes.padStart(2, "0");

  return [formattedHours, formattedMinutes];
};

export function checkin_checkout_time_difference(startTime, endTime, optional = null) {
  let formattedDate = optional.split("T")[0];

  let startDateTime = new Date(`${formattedDate}T${startTime}Z`);
  let endDateTime;

  if (endTime === null) {
    endDateTime = new Date();
  } else {
    endDateTime = new Date(`${formattedDate}T${endTime}Z`);
  }

  let timeDifference = endDateTime - startDateTime;

  if (timeDifference < 0) {
    timeDifference += 24 * 60 * 60 * 1000; // Adding 24 hours in milliseconds
  }

  // Converting timeDifference to hours, minutes, and seconds
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  // Formatting the result as HH:MM:SS
  const formattedTimeDifference = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  return formattedTimeDifference;
}

// approx-time to show as just now, seconds ago, days ago, hours ago, etc.
export const approx_time_show = value => {
  if (value) {
    const currentDate = new Date(value);
    const now = new Date();

    const timeDifference = now - currentDate;

    if (timeDifference < 30000) {
      // 30 seconds in milliseconds
      return "Just now"; // show just now when the difference is less than 30 seconds
    }

    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    for (const i in intervals) {
      const counter = Math.floor(timeDifference / 1000 / intervals[i]);

      if (counter > 0) {
        if (counter === 1) {
          return counter + " " + i + " ago"; // singular (1 day ago)
        } else {
          return counter + " " + i + "s ago"; // plural (2 days ago)
        }
      }
    }
  }
};

// Converting time string to UTC
export function convertTimeToUTC(timeString) {
  const timeFormat = "hh:mm:A"; // Format for parsing the time string with uppercase "A" for AM/PM
  const localTime = moment(timeString, timeFormat);

  // Adjust the time based on AM/PM
  if (localTime.format("A") === "PM") {
    localTime.add(12, "hours");
  }

  // Convert to UTC and format as string
  const utcTime = localTime.utc().format(timeFormat);
  return utcTime;
}

// Converting local time string to 24 hour UTC
export function convertLocalTimeTo24hourUTC(timeString) {
  const timeFormat = "hh:mm A"; // Format for parsing the input time string
  const localTime = moment(timeString, timeFormat);

  // Convert to UTC and format as string in 24-hour format
  const utcTime = localTime.utc().format("HH:mm");
  return utcTime;
}

// check the value is not defined and value not equals to null
export function isValue(value) {
  return value !== undefined && value !== null;
}

export function setter(setState, updateFields) {
  setState(s => ({ ...s, ...updateFields }));
}

// output will be an object with the start and end dates of the current week in the startDate and endDate properties
export function getWeekDates(shift) {
  const currentDate = new Date();
  const currentDay = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)

  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - currentDay + 1 + shift * 7);

  const endDate = new Date(currentDate);
  endDate.setDate(currentDate.getDate() + (7 - currentDay) + shift * 7);

  return { startDate, endDate };
}

export function formattedDate(date) {
  if (!isValue(date)) {
    return;
  }
  // Step 1: Parse the date using Moment.js
  const momentDate = moment(date, "YYYY-MM-DD");

  // Step 2: Format the date as "DD MMM YYYY"
  const formattedDate = momentDate.format("DD MMM YYYY");
  return formattedDate;
}

// To get months upto count specified, starting from startIndex specified with months to be returned in short or full form.
export const months_uptoCount = ({
  startMonthIndex = 0,
  count = Number,
  month_inShort = false,
}) => {
  if (count <= 0) {
    return [];
  }

  const months = [
    { full: "January", short: "Jan" },
    { full: "February", short: "Feb" },
    { full: "March", short: "Mar" },
    { full: "April", short: "Apr" },
    { full: "May", short: "May" },
    { full: "June", short: "Jun" },
    { full: "July", short: "Jul" },
    { full: "August", short: "Aug" },
    { full: "September", short: "Sep" },
    { full: "October", short: "Oct" },
    { full: "November", short: "Nov" },
    { full: "December", short: "Dec" },
  ];

  const selectedMonths = months.slice(startMonthIndex, count);

  if (month_inShort) {
    return selectedMonths.map(month => month.short);
  } else {
    return selectedMonths.map(month => month.full);
  }
};

// return value from [key, value] pair of  an object
export function findKeyByValue(obj, targetValue) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === targetValue) {
      return key;
    }
  }
  return null; // If value not found
}

export const formattedAmount = (amount, lang, currency, minFracDigits, maxFracDigits) => {
  return (+amount).toLocaleString(lang, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: minFracDigits,
    maximumFractionDigits: maxFracDigits,
  });
};

/**
 * Replaces occurrences of a specified value in a string with another value.
 *
 * @param {array} inputs - The array of string.
 * @return {string} - The resulting string is a merged tailwind classes.
 */
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

/**
 * Use this for React Async/Select options.
 * @param {function} func - callback function to be passed
 * @param {string} inputValue - Search string value.
 * @param {function} callback - callback call for returned value of array from func call.
 * @return {function} - The returned funtion callback will return an array.
 */
export const debouncedPromiseOptions = func =>
  debounce((inputValue, callback) => {
    return func(inputValue, callback);
  }, 300);

/**
 * Use this date fns function to get date in your desired format.
 * @param {date} date - date object to be passed
 * @param {format} format - date format to be passed.
 * @return {string} - Return formated date in string.
 */
export function dateFnsFormat(date, formatType = DATEFORMATS.DAY_MONTH_YEAR_FORMAT) {
  const dateObj = new Date(date);
  const formattedDate = format(dateObj, formatType);
  return formattedDate; // "dd MMM yyyy" or "yyyy-MM-dd" or "dd-MM-yyyy" or "MM-dd-yyyy" or "MMM dd, yyyy" or "MMM yyyy" or "dd MMM" or "dd-MM
}


var wordsA = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
var wordsB = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

export function numberInWords (num) {
    if (num <= 0 || num == null) return 'Zero';
    if ((num = num.toString()).length > 9) return 'overflow';
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (wordsA[Number(n[1])] || wordsB[n[1][0]] + ' ' + wordsA[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (wordsA[Number(n[2])] || wordsB[n[2][0]] + ' ' + wordsA[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (wordsA[Number(n[3])] || wordsB[n[3][0]] + ' ' + wordsA[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (wordsA[Number(n[4])] || wordsB[n[4][0]] + ' ' + wordsA[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (wordsA[Number(n[5])] || wordsB[n[5][0]] + ' ' + wordsA[n[5][1]]) + 'only ' : '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}
