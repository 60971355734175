export const BloodGroupEnum ={
    "Not Available": 0,
    "O Positive": 1,
    "A Positive": 2,
    "B Positive": 3,
    "AB Positive": 4,
    "AB Negative": 5,
    "A Negative": 6,
    "B Negative": 7,
    "O Negative": 8
}