import { memo, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { OrderBY } from "../../../enum/OrderBy";

const {Ascending, Descending} = OrderBY;

//NOTE ----- when passing col, rows and tableClick memoized the function and objects.
const Table = ({
  tableClass,
  parentClass,
  headClass,
  tableConfig,
  col = [],
  thClassName,
  rows = [],
  children,
  tableClick,
  tdClass,
  trClassName,
  sorting = false,
  onSort,
  sortConfig = { column: 1, orderBy: Ascending, skipColumnsToSort: [] },
  parentStyle
}) => {

  const handleSort = useCallback((columnIndex) => {
    let updatedOrder = Ascending;
    // Same column clicked - toggle order
    if (columnIndex + 1 === sortConfig.column) {
      updatedOrder = sortConfig.orderBy === Ascending ? Descending : Ascending;
    }
    onSort && onSort(updatedOrder, columnIndex + 1);
  }, [sortConfig, onSort]);

  let tableClassName = tableClass
    ? tableClass
    : "w-full text-sm text-left text-gray-500 dark:text-gray-400 bg-white";

  return (
    <div className={`${parentClass} overflow-x-auto`} style={parentStyle}>
      <table className={tableClassName}>
        <Columns
          headClass={headClass}
          thClassName={thClassName}
          col={col}
          sorting={sorting}
          sortConfig={sortConfig}
          onSort={handleSort}
        />

        {rows.length > 0 && (
          <tbody onClick={tableClick}>
            {rows &&
              rows.map(row => {
                return (
                  <Rows
                    key={uuidv4()}
                    tableConfig={tableConfig}
                    row={row}
                    tdClass={tdClass}
                    trClassName={trClassName}
                  />
                );
              })}
          </tbody>
        )}

        {!rows.length && children}
      </table>
    </div>
  );
};

export default memo(Table);

const Columns = memo(({ col, headClass, thClassName, sorting, sortConfig, onSort }) => {
  return (
    <thead className={`${headClass} text-xs uppercase`}>
      <tr>
        {col.map((value, i) => {
          const isActive = sortConfig?.column === i + 1;
          const isSortable = sortConfig?.skipColumnsToSort?.length > 0 ? !sortConfig.skipColumnsToSort?.includes(i) : true; // Check if column can be sorted

          return (
            <th
              key={i}
              scope="col"
              className={`${thClassName} ${value === "user" ? "px-6 pl-4" : "px-3"} py-3 border`}
            >
              <div className="flex items-center">
                {value}
                {sorting && isSortable && (
                  <button 
                    onClick={() => onSort(i)}
                    className="ml-1"
                  >
                    {isActive ? (
                      sortConfig.orderBy === Ascending ? 
                        <FaSortUp className="text-thynkwebPrimary-500" /> : 
                        <FaSortDown className="text-thynkwebPrimary-500" />
                    ) : (
                      <FaSortDown className="text-gray-300" />
                    )}
                  </button>
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
});

const Rows = memo(({ row = [], id, tdClass = "", tableConfig, trClassName = "" }) => {
  let dataset = JSON.stringify(row[0]);
  return (
    <tr
      className={`cursor-pointer border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 ${trClassName}`}
      data-id={dataset}
    >
      {row[1]?.map((v, i) => {
        let cellElem = v;
        if (Array.isArray(v)) {
          cellElem = v[1];
        }

        let tdClassName = "";
        if (tableConfig) {
          const columnConfig = tableConfig[i];
          tdClassName = columnConfig ? columnConfig.className : "";
        }

        return (
          <td key={i} className={`${tdClassName} ${tdClass} px-4 py-2 whitespace-nowrap`}>
            {cellElem}
          </td>
        );
      })}
    </tr>
  );
});