import { deleteShift } from "../../../../../services/AttendanceService";
import DeleteModal from "../../../../common/Modal/DeleteModal";
import { useState } from "react";

const DeleteShiftModal = ({ modals, shiftToDelete, cancelDelete, handleDelete  }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const onClickDeleteHandler = async () => {
    setIsProcessing(true);
    try {
      await deleteShift(shiftToDelete.id); // Call the service directly
      handleDelete(shiftToDelete.id); // Call the parent handler after successful deletion
      setIsProcessing(false);
    } catch (error) {
      console.error("Error deleting shift:", error.response?.data || error.message);
    }
  };

  const onClickCancelHandler = () => {
    cancelDelete(); // Call the parent cancel handler
  };

  const avatarConfigs = {
    showIcon: true,
    iconBorder: false
  }

  return (
    <DeleteModal 
      avatarConfigs={avatarConfigs}
      show={modals.deleteModal}
      title="Delete Shift"
      message={
        <div className="text-center px-8">
          <p className="text-lg text-gray-700">
          Are you sure you want to delete this "{shiftToDelete.name}" shift?
          </p>
        </div>
      }
      warningMessage={<>This action is irreversible and may impact the employee's shift assignments and
          availability data.</>}
      onDelete={onClickDeleteHandler}
      onCancel={onClickCancelHandler}
      isProcessing={isProcessing}
    />
  );
};

export default DeleteShiftModal;
