import React, { memo } from "react";
import { cn } from "../../../utils/common";

interface Tab {
  label: string;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: number;
  onActiveTabChange: (index: number) => void;
  parentClass?: string;
  activeTabClass?: string;
  inActiveTabClass?: string;
  divWrapperClass?: string;
}

const Tabs: React.FC<TabsProps> = memo(({ tabs, activeTab, onActiveTabChange, parentClass, activeTabClass, inActiveTabClass, divWrapperClass }) => {
  return (
    <div className={cn(`w-[100%] mx-auto mb-[1rem] bg-[white] border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`, parentClass)}>
      <div className={cn(`border-b-[5px]`, divWrapperClass)}>
        {tabs.map((tab, index) => (
          <button
            key={tab.label}
            className={`px-4 py-2 text-sm font-medium focus:outline-none ${
              activeTab === index
                ? cn('border-b-2 border-blue-500 text-blue-500', activeTabClass)
                : cn('text-gray-600 hover:text-blue-500', inActiveTabClass)
            }`}
            onClick={() => onActiveTabChange(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
});

export default Tabs
