import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CardItemDateRange {
  startDate: string;
  endDate: string;
}

interface CardItems {
  [key: string]: CardItemDateRange | {};
}

interface dashboardState {
  cardItems: CardItems;
}

const initialState: dashboardState = {
cardItems: {
    4:{},
    7: {},
    8: {},
  }
};
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setCardDateFilter: (state, action: PayloadAction<any | null>) => {
      let object = action.payload
      state.cardItems = {
        ...state.cardItems,
        ...object
      }
    },
  },
});

export const { setCardDateFilter } = dashboardSlice.actions;

export default dashboardSlice.reducer;
