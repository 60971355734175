import { createContext, useState, useLayoutEffect, useRef, useEffect } from "react";
import { Interceptor } from "../utils/common";
import axios from "axios";
import {} from "react";
import { getOrgCompletionStatus } from "../services/CommonService";
import { useStore } from "./createFastContext";
import { useNavigate, useLocation } from "react-router";
import { GetUserById } from "../services/UserService";

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [orgCompletionStatus, _] = useStore(s => s.orgCompletionStatus);

  const [userAuth, setUserAuth] = useState(true);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(false);
  const init = useRef(null);
  const [userInfoById, setStore] = useStore(s => s.userInfoById);

  useLayoutEffect(() => {
      //Implementing Interceptors for all api calls
      Interceptor(axiosInstance, setLoading, setUser, setUserAuth, init);
  }, [init?.current]);  

  useLayoutEffect(() => {
    if (orgCompletionStatus.isCompleted === true) return;
    if (userAuth && user?.token) {
      getOrgCompletionStatus().then(res => {
        const result = res.data;

        if (result.orgDetails && result.settings) {
          orgCompletionStatus.isCompleted = true;
          return;
        }

        if (result.orgDetails === false || result.settings === false) {
          orgCompletionStatus.isCompleted = false;
          orgCompletionStatus.status = result;
          navigate("/required-details");
          return;
        }
      });
    }
  }, [location.pathname, userAuth]);

  useEffect(() => {
    user &&
      GetUserById(user?.id).then(response => {
        setStore({
          userInfoById: response?.data,
        });
      });
  }, [userAuth]);

  return (
    <AuthContext.Provider
      value={{
        userAuth,
        setUserAuth,
        user,
        loading,
        setUser,
        setLoading,
        init,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
