import { axiosInstance } from "../context/AuthContext";
import axios from "axios";
import { appendParamsToUrl } from "../utils/helper";

const baseUrl = process.env.REACT_APP_BASE_URL;
const organizationDetailsBaseUrl = `${baseUrl}/Tenants`;

//Get salary components
export const GetTenants = async () => {
  const response = await axiosInstance.get(`${organizationDetailsBaseUrl}`);
  return response;
};

export const CreateTenant = async bodyData => {
  const response = await axiosInstance.post(`${organizationDetailsBaseUrl}`, bodyData);
  return response;
};

export const UploadOrganizationLogo = async (formData, user) => {
  const response = await axios.post(`${organizationDetailsBaseUrl}/logos`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user.token}`,
    },
  });
  return response;
};

export const UpdateTenantDetails = async bodyData => {
  const response = await axiosInstance.put(`${organizationDetailsBaseUrl}`, bodyData);
  return response;
};

//Deleting logo for current tenant
export const deleteTenantLogo = async () => {
  const response = await axiosInstance.delete(`${organizationDetailsBaseUrl}/logos`);
  return response;
};

//Submitting Tenant header background color and font color
export const submitBackgroundAndFontColor = async colorDetails => {
  const response = await axiosInstance.put(
    `${organizationDetailsBaseUrl}/brandDetails`,
    colorDetails
  );
  return response;
};

export const softDeleteTenant = async (tenantId) => {
  console.log('tenantId', tenantId)
  const params = {
    tenantId: tenantId
  };

  const url = `${organizationDetailsBaseUrl}/softDelete`;
  const finalUrl = appendParamsToUrl(url, params);
  console.log("finalurl", finalUrl)
  return await axiosInstance.delete(finalUrl).then(response => response);
};
