import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Tenant {
  id: number;
  tenantName: string;
  dateCreated: string;
  pan: string;
  tan: string;
  address: string;
  gstNumber: string;
  ianaId: string;
  headerBgColor: string;
  isHeaderFontLight: boolean;
  logoBase64: string | null;
  licenseCustomerId: string | null;
}

interface SubscriptionState {
  tenant: Tenant | null; // `string` for gender values, or `null` if not selected
}

const initialState: SubscriptionState = {
  tenant: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setTenant: (state, action: PayloadAction<Tenant | null>) => {
      state.tenant = action.payload;
    },
    setLicenseCustomerId: (state, action: PayloadAction<string>) => {
      state.tenant.licenseCustomerId = action.payload;
    },
  },
});

export const { setTenant, setLicenseCustomerId } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
