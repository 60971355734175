import React, { memo, useCallback } from 'react';
import { Button } from 'flowbite-react';
import InfoIconSvg from '../../media/InfoIconSvg';

const RestrictedPage: React.FC = () => {

  // Memoize the function to avoid recreation on every render
  const handleContactSupport = useCallback(() => {
    window.open('', '_blank'); // Opening in a new tab instead of navigation
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-300">
      <div className="max-w-xl p-10 bg-white shadow-2xl rounded-2xl border border-gray-300">
        <div className="flex items-center bg-yellow-50 border-l-4 border-yellow-500 p-6 mb-8 rounded-lg justify-center gap-4 mt-4">
          <InfoIconSvg className="w-8 h-8 flex-shrink-0 fill-yellow-500" />
          <div className="text-left">
            <h1 className="text-3xl font-semibold text-yellow-600 leading-snug">Access Restricted</h1>
            <p className="text-lg text-gray-700 mt-4 leading-relaxed">
              Your account has been deactivated or restricted, and you no longer have access to this organization or website. 
            </p>
            <p className="text-lg text-gray-700 mt-3 leading-relaxed">
              <span className="font-semibold text-yellow-600">It appears that you are not currently associated with any organization.</span>
            </p>
            <p className="text-lg text-gray-700 mt-3 leading-relaxed">
              If you believe this is an error or if you'd like more information, please reach out to our support team for further assistance.
            </p>
          </div>
        </div>
        <div className="text-center">
          <Button
            color="failure"
            size="lg"
            onClick={handleContactSupport}
            className="text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 px-6 py-3 font-semibold rounded-lg">
            Contact Support
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(RestrictedPage);
