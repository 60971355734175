import { showToast } from "../components/common/Toast";
import { axiosInstance } from "../context/AuthContext";
import { UserStatus } from "../enum/UserStatus";
import { appendParamsToUrl } from "../utils/helper";
const BaseUrl = process.env.REACT_APP_BASE_URL;
const userBaseUrl = `${BaseUrl}/Users`;

// Creating the new user by admin
export const CreateUser = async user => {
  return await axiosInstance({
    url: userBaseUrl,
    method: "POST",
    data: JSON.stringify(user),
  });
};

// Refactored GetUsers function using appendParamsToUrl
export const GetUsers = async ({
  pageSize = 10,
  pageNo = 1,
  sortBy = 1,
  orderBy = 1,
  minCTC = "",
  maxCTC = "",
  userStatus = 1,
  dateFrom = "",
  dateTo = "",
  search = "",
  departmentId = "",
  roleId = "",
  designation = "",
}) => {
  const params = {
    searchText: search,
    startDate: dateFrom,
    endDate: dateTo,
    minAnnualCTC: minCTC,
    maxAnnualCTC: maxCTC,
    pageNumber: pageNo,
    pageSize: pageSize,
    status: userStatus,
    sortBy: sortBy,
    orderBy: orderBy,
    departmentId: departmentId,
    roleId: roleId,
    designation: designation,
  };

  const url = `${userBaseUrl}`;

  // Using appendParamsToUrl to generate the URL with valid query parameters
  const finalUrl = appendParamsToUrl(url, params);

  return await axiosInstance.get(finalUrl).then(response => response.data);
};

// Updating the user admin
export const EditUser = async user => {
  return await axiosInstance({
    url: userBaseUrl,
    method: "PUT",
    data: JSON.stringify(user),
  });
};

// Delete user by user id
export const DeleteUser = async id => {
  return await axiosInstance.delete(`${userBaseUrl}/${id}`);
};

// Register a new user
export const userRegister = async userDetails => {
  const response = await axiosInstance.post(`${BaseUrl}/Account/register`, userDetails);
  return response;
};

// Update Password
export const changeUserPassword = async password => {
  const response = await axiosInstance(`${BaseUrl}/Account/update-password`, {
    method: "POST",
    data: JSON.stringify(password),
  });
  return response;
};

//Forgot Password
export const SendResetPasswordLink = async userEmail => {
  const response = await axiosInstance.post(`${BaseUrl}/Account/forgot-password`, userEmail);
  return response;
};

//Reset password
export const ResetUserPassword = async userDetails => {
  const response = await axiosInstance.post(`${BaseUrl}/Account/reset-password`, userDetails);
  return response;
};

// Getting the user details and token on login
export const userLogin = async user => {
  const res = await axiosInstance.post(`${BaseUrl}/Account/login`, user).then(response => {
    return response;
  });
  return res;
};

// Getting the user details and token on login
export const userLogout = async user => {
  try {
    const res = await axiosInstance({
      method: "POST",
      url: `${BaseUrl}/Account/logout`,
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (error) {
    showToast("error", error.response?.data?.message ?? "Login Failed. Please try again.");
  }
};

// Getting the single user details by id
export const GetUserById = async id => {
  const response = await axiosInstance.get(`${userBaseUrl}/${id}`).then(response => {
    return response;
  });
  return response;
};

// Searching the user by params
export const GetUserByParam = async para => {
  return await axiosInstance.get(`${userBaseUrl}/search?searchParam=${para}`).then(response => {
    return response.data;
  });
};

// Getting the single user by id
export const EditUsersById = async user => {
  return await axiosInstance({
    url: `${userBaseUrl}/${user?.id}`,
    method: "PUT",
    data: JSON.stringify(user),
  });
};

// Creating the Role for user by admin
export const AssignUserRole = async (userId, roleId) => {
  return await axiosInstance({
    url: `${BaseUrl}/roles/${userId}/${roleId}`,
    method: "POST",
    // data: JSON.stringify(user),
  });
};

export const GetEmploymentTypes = async () => {
  const response = await axiosInstance.get(`${userBaseUrl}/employmenttypes`).then(response => {
    return response;
  });
  return response;
};

export const CreateEmploymentTypes = async typeInfo => {
  const response = await axiosInstance
    .post(`${userBaseUrl}/employmenttypes`, typeInfo)
    .then(response => {
      return response;
    });
  return response;
};

export const GetEmploymentTypeById = async employmentTypeId => {
  const response = await axiosInstance
    .get(`${userBaseUrl}/employmenttypes/${employmentTypeId}`)
    .then(response => {
      return response;
    });
  return response;
};

export const EditEmploymentType = async employmentTypeInfo => {
  const response = await axiosInstance
    .put(`${userBaseUrl}/employmenttypes`, employmentTypeInfo)
    .then(response => {
      return response;
    });
  return response;
};

export const GetReportingUsers = async (search = "", toggle, pageSize, pageNo) => {
  const response = await axiosInstance
    .get(
      `${userBaseUrl}/reportToMe?searchParam=${search}&getAllUsers=${toggle}&pageNumber=${pageNo}&pageSize=${pageSize}`
    )
    .then(response => {
      return response;
    });
  return response;
};

export const GetReporteeUsers = async (
  search = "",
  toggle,
  pageSize,
  pageNo,
  startDate,
  endDate
) => {
  const params = new URLSearchParams();

  // Add parameters conditionally
  if (search) params.append("searchParam", search);
  if (toggle !== undefined || toggle !== null) params.append("includeIndirectReportees", toggle);
  if (pageNo) params.append("pageNumber", pageNo);
  if (pageSize) params.append("pageSize", pageSize);
  if (startDate) params.append("startDate", startDate);
  if (endDate) params.append("endDate", endDate);

  // Construct the final URL
  const url = `${userBaseUrl}/reportees?${params.toString()}`;
  const response = await axiosInstance.get(url).then(response => {
    return response;
  });
  return response;
};

// Delete EmploymentTypes
export const deleteEmploymentTypes = async employmentTypeId => {
  const response = await axiosInstance.delete(`${userBaseUrl}/employmenttypes/${employmentTypeId}`);
  return response;
};

export const importUsersCsv = async (file, isRestricted) => {
  const formData = new FormData();

  formData.append("usersCsv", file);
  formData.append("isRestricted", isRestricted.toString());
  const response = await axiosInstance.post(`${userBaseUrl}/import`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export const addUsersImage = async (User, file, setUploadProgress) => {
  const formData = new FormData();

  formData.append("profileImage", file);

  const config = {
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setUploadProgress(percentCompleted);
    },
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${User?.token}`,
    },
  };

  const customAxios = axiosInstance.create(config);

  const response = await customAxios.post(`${userBaseUrl}/images`, formData);
  return response.data;
};

export const deleteUserImage = async () => {
  const response = await axiosInstance.delete(`${userBaseUrl}/images`);
  return response;
};

//Getting new Employee number info
export const getLatestEmployeeNumber = async () => {
  const response = await axiosInstance.get(`${userBaseUrl}/latestEmployeeNumber`).then(response => {
    return response.data;
  });
  return response;
};

//Validate Employee Number
export const IsValidEmployeeNumber = async (employeeNumber, userId) => {
  const res = await axiosInstance.put(
    `${userBaseUrl}/validate/employeeNumber/${employeeNumber}/${userId}`
  );
  return res.data;
};

//Getting users fields
export const getUsersFields = async () => {
  const response = await axiosInstance.get(`${userBaseUrl}/fields`).then(response => {
    return response?.data;
  });
  return response;
};

export const userPreferences = async () => {
  const response = await axiosInstance.get(`${userBaseUrl}/preferences`).then(response => {
    return response?.data;
  });
  return response;
};

//Function to handle API call for Deactivating the user.
export const handleActivateAndDeactivateUser = async (userId, userStatus) => {
  if (userStatus === UserStatus.Deactivated) {
    const response = await axiosInstance.put(
      `${userBaseUrl}/status?userId=${userId}&userStatus=${userStatus}`
    );
    return response;
  }

  if (userStatus === UserStatus.Active) {
    const response = await axiosInstance.put(
      `${userBaseUrl}/status?userId=${userId}&userStatus=${userStatus}`
    );
    return response;
  }
};

// Get custom user fields
export const GetUserFields = async () => {
  const res = await axiosInstance.get(`${userBaseUrl}/fields`);
  return res?.data;
};

// Get users with basic info (all roles)
export const GetUsersWithBasicInfo = async ({searchParam, pageNumber, pageSize}) => {
  // Build query parameters dynamically
  const params = new URLSearchParams();

  if (searchParam) params.append('searchParam', searchParam);
  if (pageNumber) params.append('pageNumber', pageNumber);
  if (pageSize) params.append('pageSize', pageSize);

  const res = await axiosInstance.get(`${userBaseUrl}/basic?${params.toString()}`);
  return res?.data;
};

export const exportCsv = async () => {
  const response = await axiosInstance.get(`${userBaseUrl}/export`, {
    responseType: "blob",
  });
  return response;
};
