import React, { useState, useEffect, useMemo, useCallback } from "react";
import AssignShiftModal from "./ShiftsModal/AssignShiftModal";
import ModalButtons from "../../../common/Modal/ModalButtons";
import AvailableAndAssignShift from "./ShiftsModal/AvailableAndAssignShift";
import { getAssignedShifts } from "../../../../services/AttendanceService";
import { Pagination } from "flowbite-react";
import { LeftArrowSvg } from "../../../../media/LeftArrowSvg";
import { RightArrowSvg } from "../../../../media/RightArrowSvg";
import Calendar2Svg from "../../../../media/Calendar2Svg";
import { HiSearch } from "react-icons/hi";
import { DEFAULT_PAGE_NO } from "../../../../constants/Constant";

export function EmployeeSchedule() {
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isShiftModalOpen, setShiftModalOpen] = useState(false);
  const [isShiftAssignModalOpen, setShiftAssignModalOpen] = useState(false);
  const [shiftData, setShiftData] = useState({
    employee: "",
    departmentId: null,
    shiftName: "",
    shiftId: null,
    startDate: "",
    endDate: "",
    reason: "",
    shiftStart: "",
    shiftEnd: "",
    totalRecords: "",
    isDefault: false,
  });
  const [employees, setEmployees] = useState([]);
  const [shiftsByDate, setShiftsByDate] = useState({});
  const [shifts, setShifts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  //Pagination
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO); 
  const pageSize = 7;

  // Improved time formatting function
  const formatTime = useCallback(time => {
    if (!time) return "N/A";

    try {
      // Handle backend time format "HH:mm:ss"
      const timeParts = time.split(":");
      if (timeParts.length !== 3) return "N/A";

      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);

      if (isNaN(hours) || isNaN(minutes)) return "N/A";

      const date = new Date();
      date.setHours(hours, minutes, 0);

      // Format time as 12-hour format
      return date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } catch (error) {
      console.error("Error formatting time:", error);
      return "N/A";
    }
  }, []);

  const processShifts = useCallback(shiftsResponse => {
    const shiftsMap = {};

    // Ensure the response has a valid data array
    const shiftsData = Array.isArray(shiftsResponse.data) ? shiftsResponse.data : [];

    // Map the shifts for each employee
    shiftsData.forEach(employeeShift => {
      const { employeeId, employeeName, departmentId, dailyShifts } = employeeShift;

      dailyShifts.forEach(shift => {
        const shiftDate = new Date(shift.date).toDateString();

        // Add to the shiftsMap for each specific date
        if (!shiftsMap[shiftDate]) {
          shiftsMap[shiftDate] = [];
        }

        shiftsMap[shiftDate].push({
          employeeId,
          employeeName,
          departmentId,
          shiftId: shift.shiftId,
          shiftName: shift.shiftName,
          shiftStart: shift.shiftStart,
          shiftEnd: shift.shiftEnd,
          notes: shift.notes,
          isDefault: shift.isDefault,
        });
      });
    });

    return shiftsMap;
  }, []);

  // Update fetchData to use the new structure
  const fetchData = async () => {
    try {
      // Calculate date range for fetching shifts
      const weekStart = new Date(currentDate);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay());
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6);

      // Fetch shifts
      const shiftsResponse = await getAssignedShifts({
        page: Math.max(pageNo, 1), // Ensure `pageNo` is at least 1
        pageSize: pageSize,
        startDate: weekStart.toISOString(),
        endDate: weekEnd.toISOString(),
        searchQuery: searchQuery,
      });

      // Extract and process employee and shift data from response
      const { data: shiftData, totalRecords: total } = shiftsResponse;
      setTotalRecords(total);

      // Format employees and shifts for rendering
      const formattedEmployees = shiftData.map(record => ({
        id: record.employeeId,
        name: record.employeeName,
        departmentId: record.departmentId,
        initials: record.employeeName
          ? record.employeeName
              .split(" ")
              .slice(0, 2)
              .map(name => name[0].toUpperCase())
              .join("")
          : "NA", // Fallback for missing names
        dailyShifts: record.dailyShifts, // Keep shifts for further use
      }));

      setEmployees(formattedEmployees);
      const mappedShifts = processShifts(shiftsResponse);
      setShiftsByDate(mappedShifts);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally set error state or show user-friendly message
    }
  };

  // Trigger data fetch when current date changes
  useEffect(() => {
    fetchData();
  }, [pageNo, pageSize, currentDate, formatTime, refreshTrigger, searchQuery]);

  const openModal = () => setShiftModalOpen(true);
  const closeModal = () => {
    setShiftModalOpen(false);
    setShiftAssignModalOpen(false);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setShiftData(prev => ({ ...prev, [name]: value }));
  };

  const handleSearchChange = e => {
    const { value } = e.target;

    // Update the searchQuery immediately for input responsiveness
    setSearchQuery(value);

    // Trigger the debounced function for fetching/filtering data
    debouncedSearch(value);
  };

  // Debounce function implementation
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  // Debounced search handler
  const debouncedSearch = useCallback(
    debounce(value => {
      setSearchQuery(value);
      setPageNo(1); // Reset to page 1 when search changes
    }, 300),
    []
  );

  // First, modify the handleSubmit function to be more explicit about the refresh
  const handleSubmit = async success => {
    if (success) {
      try {
        setRefreshTrigger(prev => prev + 1); // Increment to trigger refresh
      } catch (error) {
        console.error("Error refreshing data:", error);
      }
    }
    closeModal();
  };

  const weekStart = useMemo(() => {
    const start = new Date(currentDate);
    start.setDate(start.getDate() - start.getDay());
    return start;
  }, [currentDate]);

  const weekDays = useMemo(
    () =>
      Array.from({ length: 7 }, (_, i) => {
        const day = new Date(weekStart);
        day.setDate(day.getDate() + i);
        return day;
      }),
    [weekStart]
  );

  const navigateWeek = useCallback(direction => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + (direction === "prev" ? -7 : 7));
      return newDate;
    });
  }, []);

  const formatDate = date =>
    date.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" });

  const handleShiftClick = (shift, empId, selectedDate) => {
    setShiftData({
      ...shift,
      departmentId: shift.departmentId,
      employeeId: empId,
      selectedDate: new Date(selectedDate), // Convert to Date object
    });
    setShiftAssignModalOpen(true);
  };

  return (
    <div className="flex h-screen flex-col m-0 p-0">
      <header className="flex items-center justify-between border-b py-2 m-0 p-0">
        <div className="flex items-center gap-72">
          <span className="text-sm font-medium bg-gray-200 text-gray-700 px-2 py-1 rounded">
            {formatDate(weekDays[0])} - {formatDate(weekDays[6])}
          </span>
          <div className="flex items-center">
            <button onClick={() => navigateWeek("prev")} className="p-2">
              <LeftArrowSvg />
            </button>
            <button>
              <Calendar2Svg />
            </button>
            <button onClick={() => navigateWeek("next")} className="p-2">
              <RightArrowSvg />
            </button>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <ModalButtons
            btnCont="row-start-3 row-end-4 col-start-1 col-end-3"
            actionBtnText="Assign Shift"
            onClickAction={openModal}
            actionBtnType="button"
            actionBtnClass="bg-blue-500 text-white px-4 rounded"
            hideActionBtn={false}
            hideCancelBtn={true}
          />
        </div>
        <AssignShiftModal
          isShiftModalOpen={isShiftModalOpen}
          handleCloseModal={closeModal}
          handleSubmit={success => handleSubmit(success)} // Pass success status
          handleInputChange={handleInputChange}
          shiftData={shiftData}
        />
      </header>

      <div className="flex flex-1 overflow-hidden h-full p-0 m-0">
        {/* Schedule Grid */}
        <div className="flex-1 overflow-hidden h-auto p-0 m-0">
          <div className="grid grid-rows-[4rem,repeat(auto-fill,4rem)] grid-cols-[minmax(120px,200px),repeat(7,1fr)] h-auto ">
            {/* Header Row */}
            <div className="sticky top-0 bg-white border-r p-2 text-center font-semibold">
              <div className="text-sm font-semibold text-gray-700 py-2 px-[.1rem] mb-2">
                {/* Search Input */}
                <div className="relative">
                  <input
                    className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md pr-3 shadow-sm focus:outline-none focus:border-thynkwebPrimary-500 focus:ring-thynkwebPrimary-500 focus:ring-1 dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500 sm:text-sm"
                    placeholder="Employees"
                    type="text"
                    name="Employees"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <div className="absolute pr-1 inset-y-0 right-0 flex items-center">
                    <HiSearch className="fill-thynkwebPrimary-500 z-0" />
                  </div>
                </div>
              </div>
            </div>
            {weekDays.map(day => (
              <div
                key={day}
                className="sticky top-0 bg-white border-b border-l p-4 text-center font-semibold">
                <div className="text-xs text-gray-500">
                  {day.toLocaleDateString("en-US", { weekday: "short" })}
                </div>
                <div className="text-sm">{day.getDate()}</div>
              </div>
            ))}

            {/* Rows for Employees */}
            {employees.map(emp => (
              <>
                {/* Employee Name */}
                <div key={`emp-${emp.id}`} className="flex items-center py-2 border-r bg-white">
                  {/* Circle with initials */}
                  <div className="h-7 w-7 rounded-full bg-gray-300 flex items-center justify-center text-gray-700 text-xs font-semibold mr-1">
                    {emp.initials}
                  </div>
                  <span
                    className="text-sm font-medium text-gray-700 truncate max-w-[120px] cursor-pointer"
                    title={emp.name}>
                    {emp.name}
                  </span>
                </div>

                {/* Shifts for Each Day */}
                {weekDays.map(day => {
                  const shiftDate = day.toDateString();
                  const employeeShifts =
                    shiftsByDate[shiftDate]?.filter(shift => shift.employeeId === emp.id) || [];

                  return (
                    <div
                      key={`shift-${emp.id}-${day}`}
                      className="border-b border-l flex items-center justify-center">
                      {employeeShifts.length > 0 ? (
                        employeeShifts.map(shift => {
                          return (
                            <div
                              key={shift.shiftId}
                              className={`h-full w-full text-center items-center justify-center py-2 cursor-pointer ${
                                shift.isDefault ? "bg-white" : "bg-[#76ABAE] text-white"
                              }`}
                              onClick={() => handleShiftClick(shift, emp.id, shiftDate)}>
                              <div className="flex flex-col items-start sm:items-center sm:justify-between">
                                <div
                                  className={`text-sm font-medium ${
                                    shift.isDefault ? "text-gray-700" : "text-white font-semibold"
                                  }  mt-2 sm:mr-1`}>
                                  {shift.shiftName}
                                </div>
                                <div
                                  className={`text-[.65rem] ${
                                    shift.isDefault ? "text-gray-500" : "text-white"
                                  } truncate w-full sm:w-auto overflow-hidden text-left sm:text-right px-2 lg:p-0`}>
                                  {formatTime(shift.shiftStart)} - {formatTime(shift.shiftEnd)}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="text-xs text-gray-500">No Shift</div>
                      )}
                    </div>
                  );
                })}
              </>
            ))}
          </div>
          {/* Flowbite Pagination */}
          {totalRecords > 7 && (
            <div className="mt-4 flex justify-end">
              <Pagination
                currentPage={pageNo}
                onPageChange={page => setPageNo(page)}
                showIcons={true}
                totalPages={totalRecords === undefined ? 1 : Math.ceil(totalRecords / pageSize)}
              />
            </div>
          )}
        </div>
        <AvailableAndAssignShift
          isShiftModalOpen={isShiftAssignModalOpen}
          handleCloseModal={closeModal}
          handleSubmit={success => handleSubmit(success)} // Pass success status
          handleInputChange={handleInputChange}
          shiftData={shiftData}
        />
      </div>
    </div>
  );
}
