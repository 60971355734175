import React, { useState, useEffect, useMemo } from "react";
import ModalComponent from "../../../../common/Modal/Modal";
import ModalButtons from "../../../../common/Modal/ModalButtons";
import { btnPrimary } from "../../../../../utils/helper";
import { GetUsers } from "../../../../../services/UserService";
import {
  getShiftsAvailableForDepartment,
  assignShiftToEmployees,
} from "../../../../../services/AttendanceService";
import { showToast } from "../../../../common/Toast";
import AsyncSelect from "react-select/async";
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NO } from "../../../../../constants/Constant";

const AssignShiftModal = ({
  isShiftModalOpen,
  handleCloseModal,
  handleSubmit: parentHandleSubmit,
  handleInputChange: parentHandleInputChange,
  shiftData,
}) => {
  // Core states
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [departmentShifts, setDepartmentShifts] = useState({});
  const [isPastDate, setIsPastDate] = useState(false);
  const [updateAttendance, setUpdateAttendance] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query

  // Derived states using useMemo
  const selectedDepartments = useMemo(
    () => Array.from(new Set(selectedEmployees.map(emp => emp.value.departmentId))),
    [selectedEmployees]
  );

  const availableShifts = useMemo(() => {
    if (selectedDepartments.length === 0) return [];

    // If all employees are from the same department
    if (selectedDepartments.length === 1) {
      return departmentShifts[selectedDepartments[0]] || [];
    }

    // Find common shifts across all selected departments
    const commonShifts = selectedDepartments.reduce((acc, deptId) => {
      const deptShifts = new Set((departmentShifts[deptId] || []).map(shift => shift.id));
      if (acc === null) return deptShifts;
      return new Set([...acc].filter(shiftId => deptShifts.has(shiftId)));
    }, null);

    return shifts.filter(shift => commonShifts?.has(shift.id));
  }, [selectedDepartments, departmentShifts, shifts]);

  // Fetch employees on mount or when searchQuery changes
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await GetUsers(10, 1, 1, 1, "", "", "", "", searchQuery); // Pass searchQuery to the API
        setEmployees(response.data || []);
      } catch (error) {
        showToast("error", "Failed to fetch employees");
      }
    };

    fetchEmployees();
  }, [searchQuery]); // Re-fetch employees when search query changes

  // Fetch shifts when departments change
  useEffect(() => {
    const fetchDepartmentShifts = async () => {
      try {
        const shiftsPromises = selectedDepartments.map(deptId =>
          getShiftsAvailableForDepartment(deptId)
        );

        const responses = await Promise.all(shiftsPromises);

        const newDepartmentShifts = {};
        responses.forEach((response, index) => {
          newDepartmentShifts[selectedDepartments[index]] = response.data;
        });

        setDepartmentShifts(newDepartmentShifts);
      } catch (error) {
        showToast("error", "Failed to fetch shifts");
      }
    };

    if (selectedDepartments.length > 0) {
      fetchDepartmentShifts();
    }
  }, [selectedDepartments]);

  // Handlers
  const handleUserInputChange = e => {
    setSelectedEmployees(e.value);
    setFormErrors(prev => ({ ...prev, employees: null }));
  };

  const handleDateChange = e => {
    const { value } = e.target;
    parentHandleInputChange(e);

    const selectedDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    const isPast = selectedDate.getTime() <= currentDate.getTime();
    setIsPastDate(isPast);
    setUpdateAttendance(isPast);
    setFormErrors(prev => ({ ...prev, startDate: null }));
  };

  const validateForm = () => {
    const errors = {};
    if (selectedEmployees.length === 0) {
      errors.employees = "Please select at least one employee";
    }
    if (!shiftData.shiftName) {
      errors.shift = "Please select a shift";
    }
    if (!shiftData.startDate) {
      errors.startDate = "Please select a start date";
    }
    if (shiftData.endDate && new Date(shiftData.endDate) < new Date(shiftData.startDate)) {
      errors.endDate = "End date cannot be before start date";
    }
    return errors;
  };

  const handleSubmit = async () => {
    if (isSubmitting) return; // Prevent duplicate submissions
    setIsSubmitting(true); // Set loading state

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);

      // Show toast messages for validation errors
      if (errors.employees) showToast("error", errors.employees);
      if (errors.shiftName) showToast("error", errors.shiftName);
      if (errors.startDate) showToast("error", errors.startDate);
      if (errors.endDate) showToast("error", errors.endDate);

      setIsSubmitting(false); // Reset loading state
      return;
    }

    try {
      const employeeIds = selectedEmployees.map(emp => emp.value.id);
      const assignShiftData = {
        EmployeeIds: employeeIds,
        ShiftId: Number(shiftData.shiftName),
        StartDate: shiftData.startDate,
        EndDate: shiftData.endDate || null,
        Notes: shiftData.reason || "",
        UpdateAttendance: updateAttendance,
      };

      const result = await assignShiftToEmployees(assignShiftData);
      if (result) {
        showToast("success", "Shift assigned successfully");
        handleCloseModalWithReset();
        parentHandleSubmit(true); // Indicate success to parent
      }
    } catch (error) {
      showToast("error", error.message || "Failed to assign shift");
    } finally {
      setIsSubmitting(false); // Reset loading state
    }
  };

  const handleCloseModalWithReset = () => {
    // Reset form states
    setSelectedEmployees([]);
    setFormErrors({});
    setIsPastDate(false);
    setUpdateAttendance(false);

    // Reset other parent states if needed
    parentHandleInputChange({ target: { name: "shiftName", value: "" } });
    parentHandleInputChange({ target: { name: "startDate", value: "" } });
    parentHandleInputChange({ target: { name: "endDate", value: "" } });
    parentHandleInputChange({ target: { name: "reason", value: "" } });

    // Call the original close handler
    handleCloseModal();
  };

  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const loadEmployeeOptions = async inputValue => {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    return new Promise(resolve => {
      const newTimeout = setTimeout(async () => {
        try {
          const response = await GetUsers({
            search: inputValue,
            pageSize: DEFAULT_PAGE_SIZE,
            pageNo: DEFAULT_PAGE_NO,
          });
          const newOptions = response.data.map(user => ({
            value: {
              id: user.id,
              departmentId: user.departmentId, // Check if this exists
            },
            label: `${user.firstName} ${user.lastName}`,
          }));
          resolve(newOptions);
        } catch (error) {
          console.error("Error loading employees:", error);
          resolve([]);
        }
      }, 300);

      setDebounceTimeout(newTimeout);
    });
  };

  const handleEmployeeChange = selectedOptions => {
    setSelectedEmployees(selectedOptions || []);
    setFormErrors(prev => ({ ...prev, employees: null }));
  };

  // Date Range Picker handlers
  const handleDateRange = ranges => {
    setDateRange([ranges.selection]);
    const startDate = ranges.selection.startDate;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);

    const isPast = startDate.getTime() <= currentDate.getTime();
    setIsPastDate(isPast);
    setUpdateAttendance(isPast);

    // Update parent component's state
    parentHandleInputChange({
      target: {
        name: "startDate",
        value: startDate.toISOString().split("T")[0],
      },
    });

    if (ranges.selection.endDate) {
      parentHandleInputChange({
        target: {
          name: "endDate",
          value: ranges.selection.endDate.toISOString().split("T")[0],
        },
      });
    }
    setFormErrors(prev => ({ ...prev, startDate: null, endDate: null }));
  };

  return (
    <ModalComponent
      show={isShiftModalOpen}
      size="xl"
      bodyClassName="gap-4 mt-2"
      heading="Assign Shift"
      showFooter={false}
      onClose={handleCloseModalWithReset}>
      <div className="flex flex-col gap-4">
        {/* Employee Selection */}
        <div className="flex items-start space-x-4 w-full">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Employee<span className="text-red-500">*</span>
            </label>
            <AsyncSelect
              isMulti
              cacheOptions
              defaultOptions
              loadOptions={loadEmployeeOptions}
              value={selectedEmployees}
              onChange={handleEmployeeChange}
              placeholder="Select employees"
              classNamePrefix="react-select"
              className="mt-2 rounded"
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value.id}
            />

            {formErrors.employees && (
              <p className="mt-1 text-sm text-red-500">{formErrors.employees}</p>
            )}
          </div>

          {/* Shift Selection */}
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Shift Name<span className="text-red-500">*</span>
            </label>
            <select
              name="shiftName"
              onChange={parentHandleInputChange}
              className={`mt-2 block w-full rounded-md border text-sm text-gray-700 ${
                formErrors.shift ? "border-red-500" : "border-gray-300"
              }`}>
              <option value="">Select Shift</option>
              {availableShifts.map(shift => (
                <option className="text-gray-700 text-md" key={shift.id} value={shift.id}>
                  {shift.shiftName}
                </option>
              ))}
            </select>
            {formErrors.shift && <p className="mt-1 text-sm text-red-500">{formErrors.shift}</p>}
          </div>
        </div>

        {/* Date Selection */}
        <div className="flex items-start space-x-4 w-full">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Start Date<span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="startDate"
              onChange={handleDateChange}
              className={`mt-1 block w-full rounded-md border h-[42px] p-2.5 ${
                formErrors.startDate ? "border-red-500" : "border-gray-300"
              } `}
            />
            {formErrors.startDate && (
              <p className="mt-1 text-sm text-red-500">{formErrors.startDate}</p>
            )}
          </div>

          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">End Date</label>
            <input
              type="date"
              name="endDate"
              min={shiftData.startDate}
              disabled={!shiftData.startDate}
              onChange={parentHandleInputChange}
              className={`mt-1 block w-full rounded-md border h-[42px] p-2.5 ${
                formErrors.endDate ? "border-red-500" : "border-gray-300"
              } `}
            />
            {formErrors.endDate && (
              <p className="mt-1 text-sm text-red-500">{formErrors.endDate}</p>
            )}
          </div>
        </div>

        {/* Reason */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Reason</label>
          <textarea
            name="reason"
            rows="4"
            onChange={parentHandleInputChange}
            placeholder="Enter reason for shift assignment"
            className="mt-1 block w-full rounded-md border border-gray-300"
          />
        </div>

        {/* Past Date Warning */}
        {isPastDate && (
          <div className="mt-4 p-4 bg-yellow-50 border-l-4 border-yellow-400 rounded">
            <h2 className="text-sm font-semibold text-yellow-800 mb-2">
              Warning: Updating Past Attendance
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              You're assigning shifts for past dates. This will affect existing attendance records.
            </p>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={updateAttendance}
                onChange={e => setUpdateAttendance(e.target.checked)}
                className="h-4 w-4 text-indigo-600 rounded border-gray-300"
              />
              <label className="ml-2 text-sm text-gray-700">
                Update existing attendance records
              </label>
            </div>
          </div>
        )}

        {/* Action Buttons */}
        <ModalButtons
          btnCont="flex justify-end gap-2"
          cancelBtnText="Cancel"
          actionBtnText="Submit"
          onClickCancel={handleCloseModalWithReset}
          onClickAction={handleSubmit}
          actionBtnType="button"
          actionBtnClass={`${btnPrimary()} rounded-md text-white font-semibold px-4 py-2`}
          cancelBtnClass="gray"
        />
      </div>
    </ModalComponent>
  );
};

export default AssignShiftModal;
