import React, { memo } from "react";
import Avatar, { ReusableAvatarProps } from "../../../components/common/Avatar/Avatar";
import { ImageBaseUrl } from "../../../constants/Constant";
interface UserProfileCardProps {
  profileName?: string;
  profileImg?: string;
  profileEmail?: string;
  profileDesignation?: string;
  profileAddress?: string;
  profilePhoneNumber?: string;
  initials?: string;
}

const UserProfileCard: React.FC<UserProfileCardProps> = memo((props) => {
  const {
    profileName,
    profileImg,
    profileEmail,
    profileDesignation,
    profileAddress,
    profilePhoneNumber,
    initials,
  } = props;

  let imageUrl = profileImg ? ImageBaseUrl+encodeURIComponent(profileImg) : null;
  

  return (
    <div className="bg-[white] w-[13rem] rounded-lg shadow-lg overflow-hidden border border-gray-200 pb-[1rem]">
      {/* Profile Section */}
      <div className="relative mt-[1rem] text-center">
        {/* Profile Picture */}
        <div className="w-24 h-24 mx-auto rounded-full overflow-hidden">
          <Avatar
            img={imageUrl || undefined}
            userName={profileName}
            rounded={true}
            className="w-24 h-24 [&_div]:!w-24 [&_div]:!h-24 [&_span]:scale-[1.5] [&_img]:!w-[100%] [&_img]:!h-[100%]"
          />
        </div>

        {/* User Info */}
        <div className="mt-3">
          {profileName && <h2 className="text-xl font-semibold max-w-[170px] truncate mx-[auto]">{profileName}</h2>}
          {profileEmail && <p className="text-blue-600 text-sm mt-1 max-w-[170px] truncate mx-[auto]">{profileEmail}</p>}
        </div>

        {/* Role and Location */}
        {profileDesignation && (
          <div className="mt-3 text-sm text-gray-600">
            <p className="max-w-[170px] truncate mx-[auto]">{profileDesignation}</p>
          </div>
        )}
        {profilePhoneNumber && (
          <div className="mt-3 text-sm text-gray-600">
            <p className="max-w-[170px] truncate mx-[auto]">{profilePhoneNumber}</p>
          </div>
        )}
        {profileAddress && (
          <div className="mt-3 text-sm text-gray-600">
            <p className="max-w-[170px] truncate mx-[auto]">{profileAddress}</p>
          </div>
        )}
      </div>
    </div>
  );
});

export default UserProfileCard;
