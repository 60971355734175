import {memo, useEffect, useState} from 'react'
import PageSection from '../../components/Layout/PageSection/PageSection'
import { MdSubscriptions } from "react-icons/md";
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { shallowEqual } from 'react-redux';
import { btnPrimary } from '../../utils/helper';
import { Button } from 'flowbite-react';
import ModalComponent from '../../components/common/Modal/Modal';
import { axiosInstance } from '../../context/AuthContext';
import toast from 'react-hot-toast';
import { setLicenseCustomerId } from '../../store/slices/subscriptionSlice';
import './subscription.css'
import { Organization, Plan, State } from './subscriptionInterfaces';
import Pricing from './Pricing';

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRmbmZrbHFhbWxsYXBtdW1jaml3Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTczNDc2NDUyMCwiZXhwIjoyMDUwMzQwNTIwfQ.RoxzfPyu1FT2iPelQhyE1GNNaUDxRlENn-2GYWRIdGQ'


const Subscription = () => {
  const [state, setState] = useState<State>({
    subscriptionData: {},
    selectedSubscriptionData: null,
    showModal: false
  });
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [selectedSeat, setSelectedSeat] = useState('1');
  const [plans, setPlans] = useState<Plan[]>([]);
  const [userData, setUserData] = useState<Organization | null>(null);
  const dispatch = useAppDispatch();
  const { tenant } = useAppSelector(state => ({ tenant: state.subscription.tenant }), shallowEqual);

  const heading = userData?.subscriptions?.length ? 'Update subscription' : 'Create subscription';
  const preSelectedPlan = userData?.subscriptions[0]?.plan?.id ?? null;

  function fetchPlansList() {
    axios.get('https://dfnfklqamllapmumcjiw.supabase.co/rest/v1/plans?select=*&is_active=eq.true&order=monthly_price_per_user.asc', {
      headers: {
        apiKey: token,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Prefer: 'return=representation'
      }
    }).then(res => {
      if (res.status === 200) {
        setPlans(res.data);
      }
    }).catch(console.error);  
  }

  function fetchUserData() {
    axios.get(`https://dfnfklqamllapmumcjiw.supabase.co/rest/v1/customers?select=*%2Csubscriptions%28id%2Cstatus%2Cbilling_type%2Cseats%2Ccurrent_period_start%2Ccurrent_period_end%2Cplan%3Aplans%28id%2Cname%2Cmonthly_price_per_user%2Cyearly_price_per_user%29%2Cinvoices%28*%29%29&id=eq.${tenant.licenseCustomerId}`, {
        headers: {
          apiKey: token,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Prefer: 'return=representation'
        }
      }).then(res => {
        if(res.status === 200) {
          let data = res.data[0]
          setUserData(data);
          setSelectedSeat(data.subscriptions[0]?.seats+'')
        }
      }).catch(console.error);
  }

  useEffect(() => {
    fetchPlansList()
  }, []);

  useEffect(() => {
    if(tenant?.licenseCustomerId) {
       fetchUserData()
    }
  }, [tenant?.licenseCustomerId]);

  const handleButton = () => {
    setState(prevState => ({ ...prevState, showModal: !prevState.showModal }));
  };

  const applySubscription = () => {
    const today = new Date();
    const expirationDate = new Date(today);
    expirationDate.setMonth(today.getMonth() + 1);

    const subscriptionData = {
      billing_type: selectedPlan?.billing_period,
      current_period_end: expirationDate.toISOString(),
      current_period_start: today.toISOString(),
      customer_id: tenant?.licenseCustomerId,
      plan_id: selectedPlan?.id,
      seats: Number(selectedSeat),
      status: "active"
    };

    if (!tenant?.licenseCustomerId) {
      axiosInstance.get('https://localhost:5002/api/Account/generateCustomerId').then(res => {
        const customerId = res.data.data;
        dispatch(setLicenseCustomerId(customerId));
        axios.post('https://dfnfklqamllapmumcjiw.supabase.co/rest/v1/subscriptions?select=*', subscriptionData, {
          headers: {
            apiKey: token,
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Prefer: 'return=representation'
          }
        }).then(() => {
             axios.post('https://dfnfklqamllapmumcjiw.supabase.co/rest/v1/subscriptions?select=*', subscriptionData, {
            headers: {
              apiKey: token,
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              Prefer: 'return=representation'
            }
          }).then(() => {
            toast.success('Subscription created successfully.');
            handleButton();
            fetchUserData()
            handleButton();
          }).catch(console.error);
        }).catch(console.error);
      });
      return;
    }

    if (userData?.subscriptions.length) {
      axios.patch(`https://dfnfklqamllapmumcjiw.supabase.co/rest/v1/subscriptions?id=eq.${userData.subscriptions[0].id}`, subscriptionData, {
        headers: {
          apiKey: token,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Prefer: 'return=representation'
        }
      }).then(() => {
        toast.success('Subscription updated successfully.');
        handleButton();
        fetchUserData()
      }).catch(console.error);
      return;
    }

    axios.post('https://dfnfklqamllapmumcjiw.supabase.co/rest/v1/subscriptions?select=*', subscriptionData, {
      headers: {
        apiKey: token,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Prefer: 'return=representation'
      }
    }).then(() => {
      toast.success('Subscription created successfully.');
      handleButton();
      fetchUserData()
    }).catch(console.error);
  };

  const handleSeat = (e) => {
    setSelectedSeat(e.target.value);
  };

  return (
    <PageSection
      heading="Subscriptions"
      icon={<MdSubscriptions size={"2rem"} />}
      showIcon={true}
      containerClass="!bg-[transparent]"
    >
      <div>
        <div className='p-[1rem] bg-white rounded-lg w-[100%] shadow border border-gray-200'>
          <Pricing
            setState={setState}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            plans={plans}
            subscriptionData={state.subscriptionData}
            preSelectedPlan={preSelectedPlan}
          />
        </div>
      </div>
      <ModalComponent
        show={state.showModal}
        onClose={handleButton}
        showFooter={false}
        heading={heading}
      >
        <div className='h-[10rem] flex flex-col justify-between'>
          <div>
            <h1 className='text-center mt-[1rem] text-[18px]'>
              Please select number of seats you want in this plan?
            </h1>
            <div>
              <input type='number' value={selectedSeat} onChange={handleSeat} className='w-[100%] rounded-lg' />
            </div>
          </div>
          <div className='flex justify-end gap-[1rem]'>
            <Button onClick={applySubscription} className={`${btnPrimary()} flex justify-end`}>
              Apply
            </Button>
            <Button color="gray" onClick={handleButton}>
              Cancel
            </Button>
          </div>
        </div>
      </ModalComponent>
    </PageSection>
  );
};

export default memo(Subscription);
