import React, { useMemo, useState } from "react";
import ReactivateConfirmationModal from "../common/Modal/DeleteModal";
import { handleActivateAndDeactivateUser } from "../../services/UserService";
import { UserStatus } from "../../enum/UserStatus";
import { STATUS_CODES } from "../../constants/Constant";
import { showToast } from "../common/Toast";
import { EMPLOYEE_MESSAGES } from "../../constants/Messages";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { resetUser, setRefetchUsers, setToggleReactivateUserModal } from "../../store/slices/userManagementSlice";

const ReActivateEmployee = ({}) => {

  const umState = useAppSelector(state => state.userManagement);
  const dispatch = useAppDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  //Function to reactivate the user
  const onReactivateUser = async () => {
    setIsProcessing(true);
    try {
      const response = await handleActivateAndDeactivateUser(
        umState.user.id,
        UserStatus.Active
      );
      if (response.status === STATUS_CODES.SUCCESS) {
        showToast("success", EMPLOYEE_MESSAGES.REACTIVATE_SUCCESS);
        resetState();
      } else {
        showToast("error", EMPLOYEE_MESSAGES.REACTIVATE_ERROR);
      }
    } catch (error) {
      showToast("error", "An unexpected error occurred.");
    } finally {
      setIsProcessing(false);
    }
  };

  const resetState = () => {
    dispatch(setToggleReactivateUserModal(false));
    dispatch(resetUser());
    dispatch(setRefetchUsers(!umState.refetchUsers));
  }

  const avatarConfigs = useMemo(
    () => ({
      userName: `${umState.user?.firstName} ${umState.user?.lastName}`,
      imageBytes: umState.user?.imageBytes,
      // status: "online",    // to be used later when BE implementation is done
      bordered: true,
      color: "warning",
    }),
    [umState.user]
  );

  return (
    <div>
      {/* Using delete modal with different name */}
      <ReactivateConfirmationModal
        avatarConfigs={avatarConfigs}
        show={umState.toggleReactivateUserModal}
        title="Confirm User Reactivation"
        message={"Are you sure you want to Reactivate this user?"}
        warningMessage={
          <>
            Reactivating user <strong>{umState.user?.firstName}</strong> will restore access to all projects and enable system login. {" "}
            <span class="text-green-500">This action will reinstate all previously assigned permissions and roles.</span> {" "}
            Ensure that the user's access and credentials are up-to-date before proceeding.
          </>
        }
        onCancel={resetState}
        onDelete={onReactivateUser}
        confirmText="Yes, Re-activate"
        isProcessing={isProcessing}
      />
    </div>
  );
};

export default ReActivateEmployee;
