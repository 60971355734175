import { CalculationType } from "../../enum/CalculationType";
import { Role } from "../../enum/Role";
import { UserStatus } from "../../enum/UserStatus";
import { FileImportSvg } from "../../media/FileImportSvg";
import { GetSpecificSalaryComponents } from "../../services/PayrollService";
import '../../styles/variables.css';
import { BsEnvelopePlusFill } from 'react-icons/bs';
import { FaUserLock } from 'react-icons/fa';

const root = document.documentElement;
const secondaryColor = getComputedStyle(root).getPropertyValue('--secondary-color');
const lightenColor = getComputedStyle(root).getPropertyValue('--lighten-secondary-color');

export const activeStyle = {
  backgroundColor: secondaryColor,
  color: "white",
  cursor: "pointer",
  borderRadius: "0px",
};

export const inactiveStyle = {
  backgroundColor: lightenColor,
  cursor: "pointer",
  borderRadius: "2px",
};

export const AddEmployeeTabs = [
  {
    id: 1,
    name: "General Information",
    activeItem: "generalInformation",
  },
  {
    id: 3,
    name: "Employment Details",
    activeItem: "employmentDetails",
  },
  {
    id: 4,
    name: "Salary Structure",
    activeItem: "salaryComponents",
  },
  {
    id: 5,
    name: "Bank Details",
    activeItem: "bankDetails",
  },
];

export const EditEmployeeTabs = [
  {
    id: 1,
    name: "General Information",
    activeItem: "generalInformation",
  },
  {
    id: 3,
    name: "Employment Details",
    activeItem: "employmentDetails",
  },
  {
    id: 4,
    name: "Salary Structure",
    activeItem: "salaryComponents",
  },
  {
    id: 5,
    name: "Bank Details",
    activeItem: "bankDetails",
  },
];

export const otherDocTypeFields = {
  id: 1,
  label: "Other",
  type: "text",
  name: "Other",
  placeholder: "Enter document type",
  required: true,
};

export const userManagementTabs = {
  active: 0,
  pendingApproval: 1,
  inActive: 2,
}

/**
 * @namespace MANDATORY_EDIT_FIELDS helper function to create mandatory edit fields for admin
 * @param {Object} user
 * @param {Object} salaryStructure
 * @returns objects with predefined keys for EditEmployee component
 */
export const MANDATORY_EDIT_FIELDS = function (user, salaryStructure) {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    dateOfJoining: user.dateOfJoining,
    annualCTC: user.annualCTC,
    experience: user.experience,
    annualCTC: salaryStructure.annualCTC,
    regimeType: salaryStructure.regimeType,
    employeeNumber: user.employeeNumber
  };
};

/**
 * @namespace checkMandatoryFields checks if a mandatory fields are not empty
 * @param {Object} user user which contains all the fields
 * @param {Object?} mandatoryFields fields which are mandatory, if null predefined obj will be used.
 * @returns returns boolean
 */
export const checkMandatoryFields = (user, mandatoryFields) => {
  let mandatoryObj;

  if (mandatoryFields) {
    mandatoryObj = mandatoryFields;
  } else {
    //predefined obj
    mandatoryObj = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      dateOfJoining: user.dateOfJoining,
      annualCTC: user.annualCTC,
      experience: user.experience,
      annualCTC: user.annualCTC,
      regimeType: user.regimeType,
      employeeNumber: user.employeeNumber
    };
  }


  const userValues = Object.values(mandatoryObj);

  const notEmpty = userValues.every(value => {
    //first check if value exist then do empty string check else return false;
    if (value === null || value === undefined) return false;
    else return value.toString().trim() !== "";
  });

  return notEmpty;
};

/**
 * @namespace toggleSubmitBtn toggle btn background color for hover state, some predefined styles for button
 * @param {string} bgColor background color
 * @param {string} hoverClass background color for hover state
 * @returns string
 */
//add active and disabled btn class
export const toggleSubmitBtn = (bgColor, hoverClass) => {
  return `text-center px-5 py-2.5 text-sm font-medium text-white ${bgColor} rounded-[5px] focus:ring-4 focus:ring-thynkwebPrimary-200 dark:focus:ring-thynkwebPrimary-900 ${hoverClass} w-full"`;
};

//create UID for inputs
/**
 * @namespace createUID create unique id
 * @param {string} UID unique id from react useId hook
 * @param {string} inputName input name <input id={createUID(UID, 'first-name')} />
 * @returns string
 */
export const createUID = (UID, inputName) => {
  return `${UID}${inputName}`;
};

export const HEADINGS = [
  "user",
  "email",
  "user role",
  "mobile no",
  // "department",  // not showing department information for now
  "designation",
  "status",
  "joining date",
  "last login",
  "Actions"        // will always be last column
];

// Salary Components earning names
export const component_earningName = name => {
  let earningName;

  switch (name) {
    case "Basic":
      earningName = "basicPay";

    case "House Rent Allowance":
      earningName = "houseRentAllowance";

    case "Fixed Allowance":
      earningName = "fixedAllowance";
      break;

    default:
      earningName = "otherAllowance";
      break;
  }

  return earningName;
};

/**
 * Retrieves the earnings data and sets it in the state.
 *
 * @param {Object} setAllEarnings - A callback function to set the earnings data in the state.
 * @param {Object} defaultComponentAssociation - A callback function to set the default component association.
 * @return {Promise} A promise that resolves when the earnings are fetched and the state is updated.
 */
export const getEarnings = async (setAllEarnings, defaultComponentAssociation) => {
  const res = await GetSpecificSalaryComponents("Earnings");
  const earnings = res?.data?.data;
  setAllEarnings(earnings);

  //set salary components value already set by an organization to show in add user modal
  defaultComponentAssociation(earnings);
};

export const viewUserDetailTabs = [
  {
    name: "Skills",
    tab: "skills",
  },
  {
    name: "Documents",
    tab: "uploadedDocuments",
  },
];

export const skillsTableHeader = [
  "Skills",
  "Last Worked On",
  "Added by",
  "Updated By",
  "Updated On",
  "strength",
  "Delete",
];
export const profileSkillsTableHeader = [
  "Skills",
  "Last Worked On",
  "Updated On",
  "strength",
  "Delete",
];

export const DocumentTableHeader = ["Document Name", "Document type", "File Type"];

export const skillInputFields = [
  {
    id: "strength",
    label: "strength",
    type: "number",
    name: "strength",
    placeholder: "Strength",
    required: true,
    value: "",
  },
  {
    id: "lastWorkedOn",
    label: "last worked",
    type: "month",
    name: "lastWorkedOn",
    placeholder: "Last Worked On",
    required: true,
    value: "",
  },
];

// resignation inputs
export const resignationInputFields = [
  {
    id: "reason",
    label: "Reason of Leaving",
    type: "text",
    name: "reason",
    placeholder: "Reason",
    required: true,
    value: "",
  },
];

//Function to Calculate earning values for Salary Details
export const recalculateEarnings = (updatedEarnings, annualCtcValue) => {
  let basicMonthlyAmount = 0;
  let basicAnnualAmount = 0;
  let hraMonthlyAmount = 0;
  let hraAnnualAmount = 0;

  const previousEarnings = updatedEarnings.map(earning => {
    if (earning.calculationType === CalculationType.PercentageOfCTC) {
      basicMonthlyAmount = (annualCtcValue / 12) * (earning.calculationValue / 100);
      basicAnnualAmount = basicMonthlyAmount * 12;
    } else if (earning.calculationType === CalculationType.PercentageOfBasic) {
      const basicCalculationValue =
        updatedEarnings.find(e => e.calculationType === CalculationType.PercentageOfCTC)
          ?.calculationValue || 0;
      hraMonthlyAmount =
        (annualCtcValue / 12) * (basicCalculationValue / 100) * (earning.calculationValue / 100);
      hraAnnualAmount = hraMonthlyAmount * 12;
    }
    return earning;
  });

  let totalMonthlyAmount = 0;
  let totalAnnualAmount = 0;

  const newEarnings = previousEarnings.map(earning => {
    let monthlyAmount = 0;
    let annualAmount = 0;

    if (earning.calculationType === CalculationType.PercentageOfCTC) {
      monthlyAmount = basicMonthlyAmount;
      annualAmount = basicAnnualAmount;
    } else if (earning.calculationType === CalculationType.PercentageOfBasic) {
      monthlyAmount = hraMonthlyAmount;
      annualAmount = hraAnnualAmount;
    } else if (earning.calculationType === CalculationType.FlatAmount) {
      const remainingMonthlyAmount =
        annualCtcValue / 12 - (basicMonthlyAmount + hraMonthlyAmount);
      const remainingAnnualAmount = annualCtcValue - (basicAnnualAmount + hraAnnualAmount);
      monthlyAmount = remainingMonthlyAmount;
      annualAmount = remainingAnnualAmount;
    }

    totalMonthlyAmount += monthlyAmount;
    totalAnnualAmount += annualAmount;

    return {
      ...earning,
      monthlyAmount,
      annualAmount,
    };
  });

  return {
    newEarnings,
    totalMonthlyAmount,
    totalAnnualAmount
  };
};

//Salary components table heading while inviting a user
export const salaryComponentsTableHeading = [
  "Components",
  "Calculation Type",
  "Monthly Amount",
  "Annual Amount"
]

export const tabData = [
  { label: 'Active', component: <span>Active</span>},
  { label: 'Pending Verification', component: <span>Pending Verification</span> },
  { label: 'Inactive', component: <span>Pending Verification</span> },
];

export const getAddUserMenuItems = (setIsOpen, dispatch, setToggleUserModal, setIsEditingUser, setIsAddingRestrictedUser, setToggleImportUsers) => [
  {
    label: 'Invite User',
    visible: true,
    onClick: () => {
      setIsOpen(false);
      dispatch(setToggleUserModal(true));
      dispatch(setIsEditingUser(false));
    },
    icon: <BsEnvelopePlusFill size={16} />,
  },
  {
    label: 'Add Restricted User',
    visible: true,
    onClick: () => {
      setIsOpen(false);
      dispatch(setToggleUserModal(true));
      dispatch(setIsEditingUser(false));
      dispatch(setIsAddingRestrictedUser(true));
    },
    icon: <FaUserLock size={16} />,
  },
  {
    label: 'Import Users',
    visible: true,
    onClick: () => {
      setIsOpen(false);
      setToggleImportUsers(true);
    },
    icon: <FileImportSvg className="w-4 h-4" />,
  },
];

export const getActionMenuItems = (user, currentUser, dispatch, getIndividualUserDetails, setUser, setIsEditingUser, setToggleUserModal, goToUserProfile, setToggleChangeRoleModal, onSubmitResendEmailVerification, onHandleToggleDeactivateAndReactivateUser, onHandleToggleDeleteUser ) => [
    {
      label: "Edit",
      visible: true,
      onClick: async () => {
        await getIndividualUserDetails(user?.id);
        dispatch(setIsEditingUser(true));
        dispatch(setToggleUserModal(true));
      },
    },
    {
      label: "View Profile",
      visible: true,
      onClick: () => goToUserProfile(user?.id),
    },
    {
      label: "Set Role",
      visible: user?.status === UserStatus.Active,
      onClick: () => {
        setToggleChangeRoleModal(true);
        dispatch(setUser(user));
      },
    },
    {
      label: "Resend Verification Email",
      visible: user?.status === UserStatus.PendingEmailVerification,
      onClick: () => onSubmitResendEmailVerification(user?.id, "resendVerificationEmail"),
    },
    {
      label: "De-Activate user",
      visible: user?.status === UserStatus.Active,
      onClick: () => onHandleToggleDeactivateAndReactivateUser("deactivate", user),
    },
    {
      label: "Re-Activate user",
      visible: user?.status === UserStatus.Deactivated,
      onClick: () => onHandleToggleDeactivateAndReactivateUser("reactivate", user),
    },
    {
      label: "Send Verification Email",
      visible: user?.status === UserStatus.RestrictedLogin,
      onClick: () => onSubmitResendEmailVerification(user?.id, "sendVerificationEmail"),
    },
    {
      label: "Delete",
      className: "text-red-500",
      visible:
        !(currentUser?.roleId === Role.Moderator && user?.roleId === Role.Administrator),
      onClick: () => onHandleToggleDeleteUser(user),
    },
  ];

  // Helper function to get status based on tab
  export const getStatusForTab = (selectedTab) => {
    switch (selectedTab) {
      case userManagementTabs.active:
        return UserStatus.Active;
      case userManagementTabs.pendingApproval:
        return UserStatus.PendingEmailVerification;
      case userManagementTabs.inActive:
        return UserStatus.Deactivated;
      case userManagementTabs.restrictedLogin:
        return UserStatus.RestrictedLogin;
      default:
        return UserStatus.Active;
    }
  };
