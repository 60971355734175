import React from "react";
import ModalComponent from "./Modal";
import { cn } from "../../../utils/common";
import ModalButtons from "./ModalButtons";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { btnPrimary } from "../../../utils/helper";

export interface ConfirmationModalProps {
  show?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  modalHeader?: string | React.ReactNode;
  modalBody?: string;
  confirmText?: string;
  cancelText?: string;
  confirmBtnClass?: string;
  cancelBtnClass?: string;
  showFooter?: boolean;
  modalPosition?: string;
  modalSize?: string;
  headerClassName?: string;
  bodyClassName?: string;
  children?: React.ReactElement;
  subModal?: boolean;
  modalButtonConfigs?: string;
  isActionDeleteBtn?: boolean;
  isCustomDeleteButton?: boolean;
  footer?: React.ReactNode;
  message?: string | React.ReactElement;
  showIcon?: boolean;
  overrideBtnClass?: string;
  isProcessing?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  onClose,
  onConfirm,
  modalHeader = "",
  modalBody = "",
  message = "Are you sure you want to proceed?",
  confirmText = "Yes, Confirm",
  cancelText = "Cancel",
  confirmBtnClass = "",
  overrideBtnClass = `px-4 py-2 rounded-md text-white !font-[400] tracking-wide ${btnPrimary()}`,
  cancelBtnClass = "",
  showFooter = false,
  footer = null,
  modalPosition = "center",
  modalSize = "",
  headerClassName = "",
  bodyClassName = "",
  children,
  subModal = false,
  isActionDeleteBtn = false,
  isCustomDeleteButton = false,
  showIcon = true,
  isProcessing = false
}) => {
  return (
    <ModalComponent
      position={modalPosition}
      size={modalSize}
      show={show}
      onClose={onClose}
      heading={modalHeader}
      body={modalBody}
      showFooter={showFooter}
      headerClassName={headerClassName}
      bodyClassName={bodyClassName}
      subModal={subModal}
      footer={footer}>
      <div className={`text-center mt-4 -mb-5`}>
        {children && children}
        {!children && (
          <>
            {showIcon && (
              <HiOutlineExclamationCircle
                className={`mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200`}
              />
            )}
            <h3 className={`mb-5 text-lg font-normal text-gray-500 dark:text-gray-400`}>
              {message}
            </h3>
            <ModalButtons
              btnCont={cn("justify-end")}
              actionBtnText={confirmText}
              cancelBtnText={cancelText}
              onClickAction={onConfirm}
              onClickCancel={onClose}
              actionBtnClass={confirmBtnClass}
              overrideBtnClass={overrideBtnClass}
              cancelBtnClass={cancelBtnClass}
              children={children}
              isActionDeleteBtn={isActionDeleteBtn}
              isCustomDeleteButton={isCustomDeleteButton}
              isProcessing={isProcessing}
              actionBtnType="button"
            />
          </>
        )}
      </div>
    </ModalComponent>
  );
};

export default ConfirmationModal;
