import {memo, FC, useState} from 'react'
import { cn } from '../../../../../utils/common';
import { ReusableAvatarProps } from '../../../Avatar/Avatar'
import ListItemText from './listItemText/ListItemText';
import ListItemEdit from './listItemEdit/ListItemEdit';

export interface ListItemProps {
  image?: string;
  title: string;
  initials?: string;
  showPopOver?: boolean;
  subtitle?: string;
  date?: String;
  editable?: boolean;
  avatarProps?: ReusableAvatarProps;
  parentClass?: string;
  editAction?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, item?: any) => void,
  deleteAction?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, item?: any) => void,
  onItemClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, item?: any) => void,
  item?: any,
  [key: string]: any; // Index signature for additional dynamic properties
}

const ListItem: FC<ListItemProps> = (props) => {
  const { image, title, initials, showPopOver, subtitle, date, editable, avatarProps,
  parentClass = '', editAction, deleteAction, item, isToday, customText, link, onItemClick, showBarClass, ...rest } = props;
  
  const [editMenu, setEditMenu] = useState(false);
  const [showBar, setShowBar] = useState(false);

  const handleMenu = (e) => {
    setEditMenu(!editMenu)
  }

  const handleClick = (e) => {
    if(onItemClick) onItemClick(e, item);
    if(e.target.closest('[data-edit-dropdown]')) return
    if(link) window.open(link, '_blank')
  }

  const onMouseEnter = () => {
    setShowBar(true)
  }

  const onMouseLeave = () => {
    setShowBar(false)
  }

  return (
    <div onClick={handleClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={cn('my-[10px] flex justify-between w-[100%] relative', parentClass)}>
      {
        showBar &&
        <div className={cn(`absolute top-[-1px] left-[-8px] h-[30px] w-[5px] rounded-[20px] bg-[#2196f3] dark:bg-[#2196f3] ${showBarClass ?? ''}`, isToday && 'bg-green-400 dark:bg-green-400')}>
        </div>
      }
      <ListItemText 
        {...props}
      />
      {
        date &&
        <div className={`absolute right-0 top-0 text-[14px] font-semibold text-gray-900 dark:text-white ${isToday ? 'text-green-400' : ''}`}>
          {isToday ? 'Today' : date}
        </div>
      }
      {
        customText &&
        <div className={`absolute right-0 top-0 text-[14px] font-semibold text-gray-900 dark:text-white`}>
          {customText}
        </div>
      }
      {
        editable &&
        <ListItemEdit 
          editAction={editAction} 
          deleteAction={deleteAction}
          item={item}
        />
      }
      
    </div>
  );
};

export default memo(ListItem)
