import { useAppSelector } from "../../../store/store";

/**
 * Renders the bank details form if the activeItem is "bankDetails".
 *
 * @param {{handleUserChange: function}} props - The properties for rendering the bank details form.
 * @param {function} props.handleUserChange - The function to handle user changes.
 * @return {JSX.Element} The bank details form component.
 */
const BankDetails = ({ handleUserChange }) => {
  const umState = useAppSelector(state => state.userManagement);
  const user = useAppSelector(state => state.userManagement.user);
  const isActive = umState.selectedUserModalItem === "bankDetails";
  return (
    <>
      {isActive && (
        <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
          {/* PAN Number */}
          <div>
            <label
              htmlFor="pan-number"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              PAN (Permanent Account Number)
            </label>
            <input
              type="text"
              name="pan"
              id="pan-number"
              value={user?.pan ?? ""}
              onChange={handleUserChange}
              className="common-input"
              placeholder="Permanent Account No."
            />
          </div>

          {/* Bank Account Number */}
          <div>
            <label
              htmlFor="bank-acc-number"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Bank Account Number
            </label>
            <input
              type="number"
              name="bankAccountNumber"
              id="bank-acc-number"
              value={user?.bankAccountNumber ?? ""}
              onChange={handleUserChange}
              className="common-input"
              placeholder="Bank Account number"
            />
          </div>

          {/* Bank Name */}
          <div>
            <label
              htmlFor="bank-name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Bank Name
            </label>

            <input
              type="text"
              name="bankName"
              id="bank-name"
              value={user?.bankName ?? ""}
              onChange={handleUserChange}
              className="common-input"
              placeholder="Bank Name"
            />
          </div>

          {/* IFSC Code */}
          <div>
            <label
              htmlFor="ifsc-code"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              IFSC Code
            </label>
            <input
              type="text"
              name="ifscCode"
              id="ifsc-code"
              value={user?.ifscCode ?? ""}
              onChange={handleUserChange}
              className="common-input"
              placeholder="IFSC Code"
            />
          </div>

          {/* Account Holder Name */}
          <div>
            <label
              htmlFor="account-holder-name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Account Holder Name
            </label>
            <input
              type="text"
              name="accountHolderName"
              id="account-holder-name"
              value={user?.accountHolderName ?? ""}
              onChange={handleUserChange}
              className="common-input"
              placeholder="Account Holder Name"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BankDetails;
